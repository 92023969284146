import { injectable, inject } from 'inversify';
import { FundraiserShoppingCartItem } from '../../../base/model/fundraiser-shopping-cart-item';
import {
  formatCurrency,
  getUrlVParameters,
  isEventNumber,
} from '../../../../utils/utils';
import { BaseCartComponent } from './base-cart-component';

@injectable()
export class ItemsComponent extends BaseCartComponent{

  private eventTickets: FundraiserShoppingCartItem[];
  private programBookAds: FundraiserShoppingCartItem[];
  private sponsorships: FundraiserShoppingCartItem[];
  private merchandise: FundraiserShoppingCartItem[];
  private packageDeals: FundraiserShoppingCartItem[];

  constructor() {
    super();
    this.componentId = 'fr-items';
    this.title = 'All Shopping Items';
  }

  public scanDataAttributes(): void {
    //
  }

  public fillData(data): void {
    super.fillData(data);
    // hide shopping items when they are out of stock, if configured to do so
    this.shoppingItems = this.shoppingItems.filter(i => i.unlimitedFlag || !i.hideWhenNotAvailable || i.availableQuantity > 0);
    this.eventTickets = this.shoppingItems.filter(i => i.categoryName === 'Event Tickets');
    this.programBookAds = this.shoppingItems.filter(i => i.categoryName === 'Program Book Ads');
    this.sponsorships = this.shoppingItems.filter(i => i.categoryName === 'Sponsorship');
    this.merchandise = this.shoppingItems.filter(i => i.categoryName === 'Merchandise');
    this.packageDeals = this.shoppingItems.filter(i => i.categoryName === 'Packaged Deals');
  }

  public getView(): string {
    let view = '' +
      '<div class="fr-widget__body">';
    view += this.buildMenu();
    view += this.buildItems();
    view += '</div>';
    return view;
  }

  public buildMenu(): string {
    const view = '' +
      '<div class="fr-widget__body-items-menu">' +
      ' <ul>' +
      '   <li data-type="ALL" class="ALL selected">' +
      '      <a href="javascript:;"><i class="fa fa-chevron-right"></i> ALL ITEMS (' + this.shoppingItems.length + ')</a>' +
      '   </li>' +
      '   <li data-type="EVENT_TICKETS" class="EVENT_TICKETS">' +
      '     <a href="javascript:;"><i class="fa fa-chevron-right"></i>Event Tickets (' + this.eventTickets.length + ')</a>' +
      '   </li>' +
      '   <li data-type="PROGRAM_BOOK_ADS" class="PROGRAM_BOOK_ADS">' +
      '     <a href="javascript:;"><i class="fa fa-chevron-right"></i>Program Book Ads (' + this.programBookAds.length + ')</a>' +
      '   </li>' +
      '   <li data-type="SPONSORSHIP" class="SPONSORSHIP">' +
      '     <a href="javascript:;"><i class="fa fa-chevron-right"></i>Sponsorship (' + this.sponsorships.length + ')</a>' +
      '   </li>' +
      '   <li data-type="MERCHANDISE" class="MERCHANDISE">' +
      '     <a href="javascript:;"><i class="fa fa-chevron-right"></i>Merchandise (' + this.merchandise.length + ')</a>' +
      '   </li>' +
      '   <li data-type="PACKAGE_DEALS" class="PACKAGE_DEALS">' +
      '     <a href="javascript:;"><i class="fa fa-chevron-right"></i>Package Deals (' + this.packageDeals.length + ')</a>' +
      '   </li>' +
      '  </ul>' +
      '</div>' +
      this.addItemTeamModal();
    //
    return view;
  }

  public buildItems(): string {
    let view = '' +
      '<div class="fr-widget__body-items-content">';
    view += '<div class="checkout-container" style="display:none"><button type="button" class="checkout">checkout</button></div> ';
    //
    view += this.buildItemSection('EVENT_TICKETS', 'Event Tickets', this.eventTickets);
    view += this.buildItemSection('PROGRAM_BOOK_ADS', 'Program Book Ads', this.programBookAds);
    view += this.buildItemSection('SPONSORSHIP', 'Sponsorship', this.sponsorships);
    view += this.buildItemSection('MERCHANDISE', 'Merchandise', this.merchandise);
    view += this.buildItemSection('PACKAGE_DEALS', 'Package Deals', this.packageDeals);
    //
    view += '</div>';
    //
    return view;
  }

  public buildItemSection(type: string, label: string, items: FundraiserShoppingCartItem[]): string {
    let view = '';
    //
    view += '<div class="fr-widget__body-item item-section-type-' + type + '" data-type="' + type + '">' +
      '<div class="fr-widget__body-item-header">' +
      '<h2>' + label + ' (' + items.length + ')' + '</h2>' +
      '</div> ' +
      '<div class="fr-widget__body-item-body">';
    items.forEach((item, index) => {
      view += this.buildItem(type, index, item);
    });
    view += '</div>' +
      '</div>';
    //
    return view;
  }

  public buildItem(type: string, order: number, item: FundraiserShoppingCartItem): string {
    let view = '' +
      '<div data-type="' + type + '" data-order-id="' + order + '" class="fr-widget__body-item-product"  data-bs-toggle="modal" data-bs-target="#itemModal">' +
      ' <img src="' + item.imageUrl + '"/>' +
      ' <div class="fr-widget__body-item-product-name">' + item.name + '</div> ' +
      ' <div class="fr-widget__body-item-product-price">' + formatCurrency(item.listPrice) + '</div> ' +
      '</div>';
    view += '';
    return view;
  }

  public addItemTeamModal(): string {
    let view = '';
    //
    view += '' +
      '<div id="itemModal" class="modal fade shopping-item-modal" role="dialog">' +
      '  <div class="modal-dialog">' +
      '    <div class="modal-content">' +
      '      <div class="modal-header">' +
      '        <button type="button" class="close" data-bs-dismiss="modal">' +
      '           <span>&times;</span>' +
      '        </button>' +
      '      </div>' +
      '      <form onsubmit="event.preventDefault();" autocomplete="off">' +
      '        <div class="modal-body">' +
      '        </div>' +
      '      </form>' +
      '      <div class="modal-footer">' +
      '      </div>' +
      '    </div>' +
      '  </div>' +
      '</div>';
    //
    return view;
  }

  public buildItemModalBodyContent(item: FundraiserShoppingCartItem): string {
    const view = '' +
      '<div class="modal-body-image">' +
      ' <img src="' + item.imageUrl + '"/>' +
      '</div>' +
      '<div class="modal-body-input">' +
      '<div class="modal-body-input-text input-name font-weight-bold">' + item.name + '</div>' +
      '<div class="modal-body-input-text input-description ">' + item.description + '</div>' +
      '<div class="modal-body-input-text input-price font-weight-bold text-center"> Price ' + formatCurrency(item.listPrice) + '</div>' +
      '<div class="modal-body-input-text input-quantity"> Quantity ' +
      '   <input type="text" name="quantity" id="quantity" data-item-id="' + item.itemId + '">' +
      '</div>' +
      '<div class="modal-body-input-button text-center">' +
      '<button type="button" class="add-to-cart"> Add to Cart</button>' +
      '</div>' +
      '<div class="modal-body-input-error input-error-item"></div>'
      '' +
      '</div>' +
      '';
    //
    return view;
  }

  public buildItemModalFooterContent(item: FundraiserShoppingCartItem): string {
    const view = '' +
      '<div class="modal-footer-text">' +
      item.name + ' have been added to your cart.' +
      '</div>' +
      '<div class="modal-footer-buttons">' +
      '  <button type="button" data-bs-dismiss="modal"> Continue shopping</button>' +
      '  <button type="button" class="checkout-modal"> Checkout Now</button>' +
      '</div>' +
      '';
    //
    return view;
  }

  public afterView(): boolean {
    this.refreshCartHeaderLink();
    //
    this.empowerMenu();
    this.empowerProducts();
    //
    if (this.sessionItems != null && this.sessionItems.length > 0) {
      window.jQuery('.fr-widget__body .checkout-container').show();
    }
    //
    window.jQuery('.fr-items .checkout').on('click', (e) => {
      window.location.href = this.getDonateCartUrl();
    });
    //
    const params: any = getUrlVParameters();
    if (params.action != null) {
      this.menuSelected(params.action);
    }
    //
    return true;
  }

  public empowerMenu(): void {
    window.jQuery('.fr-widget__body-items-menu li a').on('click', (e) => {
      const type = window.jQuery(e.target).parent().data('type');
      // menus
      this.menuSelected(type);
    });
  }

  protected menuSelected(type: string) {
    window.jQuery('.fr-widget__body-items-menu ul li').removeClass('selected');
    window.jQuery('.fr-widget__body-items-menu ul li.' + type).addClass('selected');
    // sections
    if (type !== 'ALL') {
      window.jQuery('.fr-widget__body-item').hide();
      window.jQuery('.fr-widget__body-item.item-section-type-' + type).show();
    }else {
      window.jQuery('.fr-widget__body-item').show();
    }
  }

  public empowerProducts(): void {
    window.jQuery('.fr-widget__body-item-product').on('click', (e) => {
      window.jQuery('#itemModal .modal-footer').hide();
      const type = window.jQuery(e.target).parent().data('type');
      const order:number = window.jQuery(e.target).parent().data('order-id');
      //
      let item = null;
      if (type === 'EVENT_TICKETS') {
        item = this.eventTickets[order];
      } else if (type === 'PROGRAM_BOOK_ADS') {
        item = this.programBookAds[order];
      } else if (type === 'SPONSORSHIP') {
        item = this.sponsorships[order];
      } else if (type === 'MERCHANDISE') {
        item = this.merchandise[order];
      } else if (type === 'PACKAGE_DEALS') {
        item = this.packageDeals[order];
      }
      if (item != null) {
        window.jQuery('#itemModal .modal-body').html(this.buildItemModalBodyContent(item));
        window.jQuery('#itemModal .modal-footer').html(this.buildItemModalFooterContent(item));
        window.jQuery('#quantity').on('keypress', (e) => {
          return isEventNumber(e);
        });
        window.jQuery('#itemModal .modal-body .add-to-cart').on('click', (e) => {
          const value = parseInt(window.jQuery('#quantity').val() + '', 10);
          const itemId = window.jQuery('#quantity').data('item-id');
          if (value > 0) {
            const availableQuantity = this.getAvailableItemQuantity(itemId);
            if (availableQuantity != 0) {
              if (this.hasAvailableQuantity(itemId, value)) {
                this.cartService.addCart(itemId, value).then((data: any) => {
                  this.sessionItems = data;
                  this.refreshCartHeaderLink();
                  window.jQuery('#itemModal .modal-body .modal-body-input').hide();
                  window.jQuery('#itemModal .modal-footer').show();
                  window.jQuery('.fr-widget__body .checkout-container').show();
                  window.jQuery('.fr-items .checkout-modal').on('click', (e) => {
                    window.location.href = this.getDonateCartUrl();
                  });
                });
              } else {
                this.showErrorMessage('Maximum available quantity: ' + availableQuantity + ' (including items already in the cart)');
              }
            } else {
              this.showErrorMessage('This item is not available.');
            }
          } else {
            this.showErrorMessage('Please enter valid quantity.');
          }
        });
      }
    });
  }

  protected hasAvailableQuantity(itemId: number, purchaseQuantity: number): boolean {
    const availableQuantity = this.getAvailableItemQuantity(itemId);
    if (availableQuantity === null) {
      return true;
    } else {
      const cartItem = this.sessionItems.find(i => i.itemId === itemId);
      // account for items that may already be in the cart
      if (cartItem) {
        purchaseQuantity += cartItem.quantity;
      }
      return (availableQuantity >= purchaseQuantity);
    }
  }

  private showErrorMessage(message: string): void {
    window.jQuery('.modal-body-input-error.input-error-item').text(message);
  }
}
