export function formatCurrency<T>(money: number) {
  if (money != null) {
    let result = '$' + Number(Number(money).toFixed(2)).toLocaleString("en-US",{useGrouping: true,minimumFractionDigits: 2});
    return result;
  }
  return '$0';
}

export function formatCurrencyNoDecimals<T>(money: number) {
  if (money != null) {
    const result = '$' + Number(Number(money).toFixed(0)).toLocaleString("en-US");
    return result;
  }
  return '$0';
}

export function timezoneToMomentTimezone(tz: string) {
  return true;
}

export function setInputFilter(textbox, inputFilter) {
  if (textbox != null) {
    ['input', 'keydown', 'keyup', 'mousedown', 'mouseup', 'select', 'contextmenu', 'drop'].forEach((event) => {
      textbox.addEventListener(event, () => {
        if (inputFilter(this.value)) {
          this.oldValue = this.value;
          this.oldSelectionStart = this.selectionStart;
          this.oldSelectionEnd = this.selectionEnd;
        } else if (Object.getOwnPropertyDescriptor(this, 'oldValue')) {
          this.value = this.oldValue;
          this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd);
        }
      });
    });
  }
}
export function isEventNumber(e: any) {
  const evt = (e) ? e : window.event;
  const charCode = (evt.which) ? evt.which : evt.keyCode;
  if (charCode > 31 && (charCode < 48 || charCode > 57)) {
    return false;
  }
  return true;
}

export function getUrlVParameters() {
  const vars = [];
  let hash;
  const hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
  for (let i = 0; i < hashes.length; i += 1) {
    hash = hashes[i].split('=');
    vars.push(hash[0]);
    vars[hash[0]] = hash[1];
  }
  return vars;
}