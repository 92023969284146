import { injectable, inject } from 'inversify';
import { BaseFundraiserComponent } from '../../../base/base-fundraiser-component';
import { FundraiserEnvironment } from '../../../sdk/services/fundraiser-environment';
import { FundraiserPage } from '../../../base/model/fundraiser-page';
import { FundraiserCampaign } from '../../../base/model/fundraiser-campaign';
import fundraiserContainer from '../../../../container/fundraiser-container';
import sdkTypes from '../../../sdk/types/sdk-types';
import { FundraiserShoppingCartItem } from '../../../base/model/fundraiser-shopping-cart-item';
import { CartService } from '../services/cart-service';
import joinTypes from '../../types/join-types';
import { FundraiserShoppingCartSessionItem } from '../../../base/model/fundraiser-shopping-cart-session-item';

@injectable()
export class BaseCartComponent extends BaseFundraiserComponent{

  protected fundraiserEnvironment: FundraiserEnvironment;
  protected cartService: CartService;
  protected fundraiserPage: FundraiserPage;
  protected fundraiserCampaign: FundraiserCampaign;
  protected sessionItems: FundraiserShoppingCartSessionItem[];
  protected shoppingItems: FundraiserShoppingCartItem[];

  constructor() {
    super();
    this.fundraiserEnvironment = fundraiserContainer.get<FundraiserEnvironment>(sdkTypes.fundraiserEnvironment);
    this.cartService = fundraiserContainer.get<CartService>(joinTypes.CartService);
  }

  public scanDataAttributes(): void {
    //
  }

  public prepareData(): Promise<any> {
    return this.cartService.getCart();
  }

  public fillData(data): void {
    this.data = data;
    this.sessionItems = data;
    this.fundraiserCampaign = this.fundraiserEnvironment.options.fundraiserCampaign;
    this.fundraiserPage = this.fundraiserEnvironment.options.fundraiserPage;
    //
    this.shoppingItems = this.fundraiserEnvironment.options.shoppingItems;
  }

  public getTitleView(): string {
    return '';
  }

  protected getDonateCartUrl(): string {
    let shoppingCartParam = '';
    this.sessionItems.forEach((si) => {
      const value = si.itemId + '=' + si.quantity;
      if (shoppingCartParam !== '') {
        shoppingCartParam += ', ' + value;
      }else {
        shoppingCartParam += value;
      }
    });
    //
    let donateUrl = this.fundraiserCampaign.donationUrl + '?amount=';
    donateUrl += '&fundraiserIDTo=' + this.fundraiserPage.fundraiserId;
    donateUrl += '&fundraiserPageID=' + this.fundraiserPage.fundraiserPageId;
    donateUrl += '&participantFirstName=' + this.fundraiserPage.memberFirstName;
    donateUrl += '&participantLastName=' + this.fundraiserPage.memberLastName;
    if (this.fundraiserPage.fundraiserType === 'EVENT') {
      donateUrl += '&displayName=' + this.fundraiserPage.event.name;
    }else {
      donateUrl += '&displayName=' + this.fundraiserPage.displayName;
    }
    donateUrl += '&fundraiserPageTemplatetype=' + this.fundraiserPage.fundraiserType;
    donateUrl += '&programGroupName=' + this.fundraiserCampaign.name;
    donateUrl += '&fundraiserPageURL=' + this.fundraiserPage.fullUrl;
    donateUrl += '&shoppingCartParam=%7B' + shoppingCartParam + '%7D'; //safari doesn't play nice with curly brackets, so they must be escaped
    return donateUrl;
  }

  public refreshCartHeaderLink(): void {
    const items = this.sessionItems != null ? this.sessionItems.length : 0;
    window.jQuery('li.cart-link a span').text('Cart ' + items + '');
  }

  protected getAvailableItemQuantity(itemId: number): number {
    const item = this.shoppingItems.find(i => i.itemId === itemId);
    return item.availableQuantity;
  }
}