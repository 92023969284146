import { injectable } from 'inversify';
import { FundraiserPageInput } from './model/input/fundraiser-page-input';

@injectable()
export class BaseFundraiserComponent {

  public componentId;
  public title;
  public showWhen;
  public domElement;
  public data: any;
  // options
  protected hideTitle: boolean;
  protected showIfEmpty: boolean;

  constructor() {
    // do nothing.
  }

  public scan(): boolean {
    //TODO move to logging utils
    //console.log('Scanning component with class: .' + this.componentId);
    this.domElement = window.jQuery('.' + this.componentId);
    if (this.domElement.length > 0) {
      for (let i = 0; i < this.domElement.length; i += 1) {
        const e = this.domElement.get(i);
        this.title = e.dataset.title ? e.dataset.title : this.title;
        this.hideTitle = e.dataset.hideTitle != null ? e.dataset.hideTitle === 'true' : false;
        this.showIfEmpty = e.dataset.showIfEmpty != null ? e.dataset.showIfEmpty === 'true' : false;
        this.showWhen = e.dataset.showWhen != null ? e.dataset.showWhen : 'show-always';
        window.jQuery(e).addClass(this.showWhen);
      }
      this.scanDataAttributes();
    }
    return this.domElement.length > 0;
  }

  public scanDataAttributes(): void {
    return;
  }

  public setType(fundraiserType: string): void {
    this.domElement.addClass('type-' + fundraiserType.toLowerCase());
  }

  public fillData(data): void {
    this.data = data;
  }

  public prepareData(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      resolve(resolve);
    });
  }

  public hasView(): boolean {
    return true;
  }

  public getView(): string {
    return '';
  }

  public getGhostView(): string {
    return this.getTitleView();
  }

  public getTitleView(): string {
    let view = '';
    if (!this.hideTitle) {
      view = '<div class="fr-widget__header"> ' +
          '<h4>' + this.title + '</h4>' +
          '</div>';
    }
    return view;
  }

  public afterView(): boolean {
    return true;
  }

  public loadMaps(): void {
    // do nothing.
  }

  public select2(): void {
    // do nothing.
  }

  public replaceView(): void {
    this.domElement.html(this.getView());
  }
  public replaceHtmlParams(array: any) {
    let html = this.domElement.html();
    array.forEach((elem) => {
      html = html.replace('{{' + elem.key + '}}', elem.value);
    });
    this.domElement.html(html);
  }

  public replaceHtmlParamsInView(view: string, array: any) {
    let replacedView = view;
    array.forEach((elem) => {
      replacedView = replacedView.replace('{{' + elem.key + '}}', elem.value);
    });
    return replacedView;
  }

  public disableLoading() {
    this.domElement.removeClass('fr-widget-loading');
  }

  public initiateGhostView() {
    this.domElement.html(this.getGhostView());
    this.domElement.addClass('fr-widget-loading');
  }

  /**
   * Do nothing. If child need data from main api, call tell this object
   * @param pageInput
   */
  requestData(pageInput: FundraiserPageInput) {
    // do nothing.
  }
}