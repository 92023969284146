import { injectable } from 'inversify';
import fundraiserContainer from '../../../container/fundraiser-container';
import structuralTypes from '../../structural/types/structural-types';
import { BaseFundraiserComponent } from '../../base/base-fundraiser-component';
import contentTypes from '../../content/types/content-types';
import joinTypes from '../../join/types/join-types';
import { FundraiserPage } from '../../base/model/fundraiser-page';
import { FundraiserCampaign } from '../../base/model/fundraiser-campaign';

@injectable()
export class ComponentScanner {

  private componentsScanned:BaseFundraiserComponent[] = [];

  public initialize(): void {
    //TODO move to logging utils
    //console.log('[ComponentScanner] - Initializing component scanner...');
    //
    this.scanFRStructure();
    //
    this.scanContent();
    //
    this.scanJoin();
    //
    //console.log('[ComponentScanner] - Finished scan of widgets. Found: ' + this.componentsScanned.length);
  }

  public setHiddenVariables(fundraiserPage: FundraiserPage, fundraiserCampaign: FundraiserCampaign) {
    let domElement = window.jQuery('body');
    domElement.prepend('<div id="hidden-variables"></div>');
    //
    domElement = window.jQuery('#hidden-variables');
    if (fundraiserPage != null) {
      domElement.append('<div style="display:none" id="fundraiser-id" data-value="' + fundraiserPage.fundraiserId + '"></div>');
      domElement.append('<div style="display:none" id="fundraiser-goal" data-value="' + fundraiserPage.fundraiserGoal + '"></div>');
      domElement.append('<div style="display:none" id="fundraiser-goal-minimum" data-value="' + fundraiserPage.fundraiserGoalMinimum + '"></div>');
      //
      if (fundraiserPage.event != null) {
        domElement.append('<div style="display:none" id="fundraiser-event-location" data-value="' + fundraiserPage.event.location + '"></div>');
        domElement.append('<div style="display:none" id="fundraiser-event-address" data-value="' + fundraiserPage.event.address + '"></div>');
        domElement.append('<div style="display:none" id="fundraiser-event-postalCode" data-value="' + fundraiserPage.event.postalCode + '"></div>');
        domElement.append('<div style="display:none" id="fundraiser-event-city" data-value="' + fundraiserPage.event.city + '"></div>');
        domElement.append('<div style="display:none" id="fundraiser-event-state" data-value="' + fundraiserPage.event.state + '"></div>');
        domElement.append('<div style="display:none" id="fundraiser-event-country" data-value="' + fundraiserPage.event.country + '"></div>');
        //
        domElement.append('<div style="display:none" id="fundraiser-event-latitude" data-value="' + fundraiserPage.event.latitude + '"></div>');
        domElement.append('<div style="display:none" id="fundraiser-event-longitude" data-value="' + fundraiserPage.event.longitude + '"></div>');
      }
    }
    if (fundraiserCampaign != null) {
      domElement.append('<div style="display:none" id="fundraiser-campaign-id" data-value="' + fundraiserCampaign.fundraiserCampaignId + '"></div>');
      domElement.append('<div style="display:none" id="fundraiser-event-video" data-value="' + fundraiserCampaign.videoUrl + '"></div>');
    }
  }

  public getComponentsScanned(): BaseFundraiserComponent[] {
    return this.componentsScanned;
  }

  private scanFRStructure(): void {
    //TODO move to logging utils
    //console.log('[ComponentScanner] - Scanning Fundraising DOM Structure...');
    //
    const structuralComponentArray: Symbol[] = [];
    structuralComponentArray.push(structuralTypes.PageTitleComponent);
    //
    this.scan(structuralComponentArray);
    //
    //console.log('[ComponentScanner] - Finished scan of Fundraising DOM Structure.');
  }

  private scanContent(): void {
    //console.log('[ComponentScanner] - Scanning Content Fundraising Widgets...');
    //
    const contentTypesComponentArray: Symbol[] = [];
    contentTypesComponentArray.push(contentTypes.PostsComponent);
    contentTypesComponentArray.push(contentTypes.CommentComponent);
    contentTypesComponentArray.push(contentTypes.ConnectComponent);
    contentTypesComponentArray.push(contentTypes.ContactComponent);
    contentTypesComponentArray.push(contentTypes.EventContentComponent);
    contentTypesComponentArray.push(contentTypes.EventVideoComponent);
    contentTypesComponentArray.push(contentTypes.EventParticipantsComponent);
    contentTypesComponentArray.push(contentTypes.EventTitleComponent);
    contentTypesComponentArray.push(contentTypes.EventInfoComponent);
    contentTypesComponentArray.push(contentTypes.CountdownClockComponent);
    contentTypesComponentArray.push(contentTypes.FindOurEventComponent);
    contentTypesComponentArray.push(contentTypes.NewsFeedComponent);
    contentTypesComponentArray.push(contentTypes.PageHeaderComponent);
    contentTypesComponentArray.push(contentTypes.ParticipateForComponent);
    contentTypesComponentArray.push(contentTypes.ProgressGoalComponent);
    contentTypesComponentArray.push(contentTypes.ProgressGoalChartComponent);
    contentTypesComponentArray.push(contentTypes.TeardropGoalComponent);
    contentTypesComponentArray.push(contentTypes.LeaderboardComponent);
    contentTypesComponentArray.push(contentTypes.ShareComponent);
    contentTypesComponentArray.push(contentTypes.ThanksToComponent);
    contentTypesComponentArray.push(contentTypes.YearCandidatesComponent);
    //
    this.scan(contentTypesComponentArray);
    //
    //TODO move to logging utils
    //console.log('[ComponentScanner] - Finished scan of Content Fundraising Widgets.');
  }

  private scanJoin(): void {
    //console.log('[ComponentScanner] - Scanning Join Fundraising Widgets...');
    //
    const joinTypesComponentArray: Symbol[] = [];
    joinTypesComponentArray.push(joinTypes.CartComponent);
    joinTypesComponentArray.push(joinTypes.DonateComponent);
    joinTypesComponentArray.push(joinTypes.DonateMembersComponent);
    joinTypesComponentArray.push(joinTypes.JoinOurTeamComponent);
    joinTypesComponentArray.push(joinTypes.ItemsComponent);
    joinTypesComponentArray.push(joinTypes.RegisterTodayComponent);
    joinTypesComponentArray.push(joinTypes.RegisterTodayNationalComponent);
    joinTypesComponentArray.push(joinTypes.SearchComponent);
    joinTypesComponentArray.push(joinTypes.SearchExternalComponent);
    joinTypesComponentArray.push(joinTypes.TellMeMoreComponent);
    //
    this.scan(joinTypesComponentArray);
    //
    //TODO move to logging utils
    //console.log('[ComponentScanner] - Finished scan of Join Fundraising Widgets.');
  }

  private scan(componentArray: Symbol[]) {
    componentArray.forEach((item:any) => {
      const component = fundraiserContainer.get<BaseFundraiserComponent>(item);
      if (component.scan()) {
        this.componentsScanned.push(component);
      }
    });
  }
}