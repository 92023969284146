import { injectable, inject } from 'inversify';
import { BaseFundraiserComponent } from '../../../base/base-fundraiser-component';
import fundraiserContainer from '../../../../container/fundraiser-container';
import { FundraiserEnvironment } from '../../../sdk/services/fundraiser-environment';
import sdkTypes from '../../../sdk/types/sdk-types';
import { FundraiserPage } from '../../../base/model/fundraiser-page';
import { formatCurrencyNoDecimals } from '../../../../utils/utils';

@injectable()
export class TeardropGoalComponent extends BaseFundraiserComponent{

  private fundraiserEnvironment: FundraiserEnvironment;
  private fundraiserPage: FundraiserPage;
  private raised: number;
  private goal: number;
  private percentage: number;
  private maxPercentage: number;
  //
  private currentProgressHeight = 7;
  private currentNeedleBottom = 7 - 19;
  private progressHeight: number;
  private needleBottom: number;
  private interval: any;

  constructor() {
    super();
    this.fundraiserEnvironment = fundraiserContainer.get<FundraiserEnvironment>(sdkTypes.fundraiserEnvironment);
    this.componentId = 'fr-teardrop-goal';
    this.title = 'Fundraising Goal';
    //
  }

  public fillData(data): void {
    this.fundraiserPage = this.fundraiserEnvironment.options.fundraiserPage;
    this.raised = Math.round(this.fundraiserPage.fundraiserTotalDonationAmount);
    this.goal = Math.round(this.fundraiserPage.fundraiserGoal);
    if (this.goal > 0) {
      this.percentage = Math.round((this.raised / this.goal) * 100);
      this.maxPercentage = this.percentage;
    }else {
      this.percentage = 0;
    }
    //
    console.log('Calculating teardrop... Raised: ' + this.raised + ' Goal: ' + this.goal + ' ' + this.percentage + '%');
    //
    // Range height is from 7 to 180 = 173
    this.progressHeight = (this.percentage * 173 / 100) + 7;
    this.needleBottom = this.progressHeight - 19;
  }

  public getGhostView(): string {
    return this.getTemplateView(7, '', '<h5></h5>');
  }

  private getTemplateView(progressHeight: number, needle: string, label: string): string {
    let view = this.getTitleView();
    //
    view += '<div class="fr-widget__body fr-widget__body-teardrop">' +
      '<div class="fr-widget__body-teardrop__drop">\n' +
      '        <div class="progress" style="height: ' + progressHeight + 'px"></div>\n' +
      '        <div class="frame"></div>\n';
    view += needle;
    view +=  '</div>\n' +
      '<div class="label">\n';
    view += label;
    view += '</div>' +
      '</div>';
    //
    return view;
  }

  public getView(): string {
    const needle = '<div class="needle" style="bottom: ' + this.needleBottom + 'px; right: 10px;">' +
      '               <div class="needle-percentage">' + this.percentage + '%</div>' +
      '             </div>\n';
    //
    const label = formatCurrencyNoDecimals(this.raised) + ' of <span>' + formatCurrencyNoDecimals(this.goal) + '</span>';
    //
    return this.getTemplateView(this.currentProgressHeight, needle, label);
  }

  public afterView(): boolean {
    if (this.percentage > 0) {
      this.percentage = 0;
      const timeout = this.maxPercentage > 200 ? 10 : 25;
      this.interval = setInterval(() => { this.refreshView(); }, timeout);
    }
    return true;
  }

  public refreshView(): void {
    if (this.percentage >= 100) {
      this.percentage += 1;
    } else {
      this.currentProgressHeight += 1;
      this.currentNeedleBottom = this.currentProgressHeight - 19;
      this.percentage = Math.round(((this.currentProgressHeight - 7) * 100) / 173);
    }
    if (this.currentNeedleBottom >= 161) {
      this.currentNeedleBottom = 161;
    }
    //
    this.domElement.find('.progress').attr({ style: 'height: ' + this.currentProgressHeight + 'px' });
    this.domElement.find('.needle').attr({ style: 'bottom: ' + this.currentNeedleBottom + 'px; right: 10px' });
    this.domElement.find('.needle-percentage').html(this.percentage + '%');
    if (this.percentage >= this.maxPercentage) {
      clearInterval(this.interval);
    }
  }

}