import { ContainerModule, interfaces } from 'inversify';
import structuralTypes from '../types/structural-types';
import { StructuralService } from '../services/structural-service';
import { PageTitleComponent } from '../page-title/component/page-title-component';

const structuralModule = new ContainerModule((bind: interfaces.Bind, unbind: interfaces.Unbind) => {
  bind<StructuralService>(structuralTypes.StructuralService).to(StructuralService);

  bind<PageTitleComponent>(structuralTypes.PageTitleComponent).to(PageTitleComponent);
});
export default structuralModule;