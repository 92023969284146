import { ContainerModule, interfaces } from 'inversify';
import contentTypes from '../types/content-types';
import { PostsService } from '../posts/services/posts-service';
import { PostsComponent } from '../posts/component/posts-component';
import { ThanksToComponent } from '../thanks-to/component/thanks-to-component';
import { EventContentComponent } from '../event-content/component/event-content-component';
import { EventInfoComponent } from '../event-info/component/event-info-component';
import { EventTitleComponent } from '../event-title/component/event-title-component';
import { EventVideoComponent } from '../event-video/component/event-video-component';
import { CountdownClockComponent } from '../countdown-clock/component/countdown-clock-component';
import { FindOurEventComponent } from '../find-our-event/component/find-our-event-component';
import { ProgressGoalComponent } from '../progress-goal/component/progress-goal-component';
import { TeardropGoalComponent } from '../teardrop-goal/component/teardrop-goal-component';
import { CommentComponent } from '../comment/component/comment-component';
import { LeaderboardComponent } from '../leaderboard/component/leaderboard-component';
import { ConnectComponent } from '../connect/component/connect-component';
import { LeaderboardService } from '../leaderboard/services/leaderboard-service';
import { NewsFeedComponent } from '../news-feed/component/news-feed-component';
import { PostsPager } from '../posts/services/posts-pager';
import { ParticipateForComponent } from '../participate-for/component/participate-for-component';
import { ShareComponent } from '../share/component/share-component';
import { YearCandidatesComponent } from '../year-candidates/component/year-candidates-component';
import { ProgressGoalChartComponent } from '../progress-goal/component/progress-goal-chart-component';
import { EventParticipantsComponent } from '../event-participants/component/event-participants-component';
import { ContactComponent } from '../contact/component/contact-component';
import { PageHeaderComponent } from '../page-header/component/page-header-component';

const contentModule = new ContainerModule((bind: interfaces.Bind, unbind: interfaces.Unbind) => {
  bind<PostsService>(contentTypes.PostsService).to(PostsService).inSingletonScope();
  bind<PostsPager>(contentTypes.PostsPager).to(PostsPager).inSingletonScope();
  bind<PostsComponent>(contentTypes.PostsComponent).to(PostsComponent);
  bind<CommentComponent>(contentTypes.CommentComponent).to(CommentComponent);
  bind<ContactComponent>(contentTypes.ContactComponent).to(ContactComponent);
  //
  bind<EventContentComponent>(contentTypes.EventContentComponent).to(EventContentComponent);
  bind<EventInfoComponent>(contentTypes.EventInfoComponent).to(EventInfoComponent);
  bind<EventParticipantsComponent>(contentTypes.EventParticipantsComponent).to(EventParticipantsComponent);
  bind<PageHeaderComponent>(contentTypes.PageHeaderComponent).to(PageHeaderComponent);
  bind<EventTitleComponent>(contentTypes.EventTitleComponent).to(EventTitleComponent);
  bind<EventVideoComponent>(contentTypes.EventVideoComponent).to(EventVideoComponent);
  //
  bind<ConnectComponent>(contentTypes.ConnectComponent).to(ConnectComponent);
  //
  bind<CountdownClockComponent>(contentTypes.CountdownClockComponent).to(CountdownClockComponent);
  //
  bind<FindOurEventComponent>(contentTypes.FindOurEventComponent).to(FindOurEventComponent);
  //
  bind<LeaderboardService>(contentTypes.LeaderboardService).to(LeaderboardService);
  bind<LeaderboardComponent>(contentTypes.LeaderboardComponent).to(LeaderboardComponent);
  //
  bind<NewsFeedComponent>(contentTypes.NewsFeedComponent).to(NewsFeedComponent);
  //
  bind<ParticipateForComponent>(contentTypes.ParticipateForComponent).to(ParticipateForComponent);
  //
  bind<TeardropGoalComponent>(contentTypes.TeardropGoalComponent).to(TeardropGoalComponent);
  bind<ProgressGoalComponent>(contentTypes.ProgressGoalComponent).to(ProgressGoalComponent);
  bind<ProgressGoalChartComponent>(contentTypes.ProgressGoalChartComponent).to(ProgressGoalChartComponent);
  //
  bind<ShareComponent>(contentTypes.ShareComponent).to(ShareComponent);
  //
  bind<ThanksToComponent>(contentTypes.ThanksToComponent).to(ThanksToComponent);
  //
  bind<YearCandidatesComponent>(contentTypes.YearCandidatesComponent).to(YearCandidatesComponent);

});
export default contentModule;