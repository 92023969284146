import { Container } from 'inversify';
import 'reflect-metadata';
import sdkModule from '../widgets/sdk/module/sdk-module';
import structuralModule from '../widgets/structural/module/structural-module';
import contentModule from '../widgets/content/module/content-module';
import joinModule from '../widgets/join/module/join-module';

const fundraiserContainer = new Container();
// SDK
fundraiserContainer.load(sdkModule);
// Structural
fundraiserContainer.load(structuralModule);
// Content
fundraiserContainer.load(contentModule);
// Join
fundraiserContainer.load(joinModule);

export default fundraiserContainer;