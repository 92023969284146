import { injectable } from 'inversify';
import { request } from '../../../../utils/request';
import fundraiserContainer from '../../../../container/fundraiser-container';
import sdkTypes from '../../../sdk/types/sdk-types';
import { FundraiserEnvironment } from '../../../sdk/services/fundraiser-environment';

@injectable()
export class JoinOurTeamService {

  private fundraiserEnvironment: FundraiserEnvironment;

  constructor() {
    this.fundraiserEnvironment = fundraiserContainer.get<FundraiserEnvironment>(sdkTypes.fundraiserEnvironment);
  }

  public async fetchSubteamsInfo() {
    const url = '/api/fundraiser/' + this.fundraiserEnvironment.options.fundraiserPage.fundraiserId + '/team/subteams/?depth=5';
    //
    const response = await request('get', url, {}, {});
    return response.json();
  }

  public async fetchSubteamsMembersInfo(query: string) {
    const url = '/api/fundraiser/' + this.fundraiserEnvironment.options.fundraiserPage.fundraiserId + '/team/subteams/members/?depth=5&term=' + query;
    //
    const response = await request('get', url, {}, {});
    return response.json();
  }

}