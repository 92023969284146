class LogEntry{
    public Group: string;
    public Message: any;
    public Timestamp: Date;

    constructor(){
        this.Timestamp = new Date();
    }

    public WriteToConsole(){
        const now = new Date();
        const agoInSecs = (now.getTime() - this.Timestamp.getTime()) / 1000;

        console.log("[" + this.Group + "]("+ Math.round(agoInSecs)+"s): ", this.Message);
    }
}

export class Logger{
    public static Logs = [];

    public static info(group: string, msg?: any){
        if(typeof msg === "undefined" || msg == null){
            msg = group;
            group = "general";
        }
 
        const entry = new LogEntry();
        entry.Group = group;
        entry.Message = msg

        this.Logs.push(entry); 

        const enableOuput = window.localStorage.getItem("Logger.EnableOuput");
        if(typeof enableOuput === "string" && enableOuput == "true")
            entry.WriteToConsole();
    }

    public static OutputToConsole(filterGroup: string){
        if(typeof filterGroup === "string")
            this.Logs.forEach(function(entry){
                if(entry.Group == filterGroup)
                entry.WriteToConsole();
            });
        else
            this.Logs.forEach(function(entry){
                entry.WriteToConsole();
            });
    }

    public static EnableLogging(){
        window.localStorage.setItem("Logger.EnableOutput", "true");
    }

    public static DisableLogging(){
        window.localStorage.setItem("Logger.EnableOutput", "false");
    }
}