import { injectable, inject } from 'inversify';
import moment from 'moment';
import { BaseFundraiserComponent } from '../../../base/base-fundraiser-component';
import fundraiserContainer from '../../../../container/fundraiser-container';
import { FundraiserEnvironment } from '../../../sdk/services/fundraiser-environment';
import sdkTypes from '../../../sdk/types/sdk-types';
import { FundraiserEvent } from '../../../base/model/fundraiser-event';
import { FundraiserPage } from '../../../base/model/fundraiser-page';
import { FundraiserTeam } from '../../../base/model/fundraiser-team';
import { DonateService } from '../../../join/donate/services/donate-service';
import joinTypes from '../../../join/types/join-types';

@injectable()
export class PageHeaderComponent extends BaseFundraiserComponent{

  private fundraiserEnvironment: FundraiserEnvironment;
  private fundraiserEvent: FundraiserEvent;
  private donateService: DonateService;
  private eventTitle: string;
  private eventLocation: string;
  private eventDate: string;
  private backgroundImage: string;
  private fundraiserPage: FundraiserPage;
  private fundraiserTeam: FundraiserTeam;
  private honoree: string;
  private honoreeType: string;
  private showRegisterBtn: boolean;
  private learnMoreUrl: string;
  private profileImagePath: string;
  //
  private teamLabel: string;
  private eventLabel: string;

  constructor() {
    super();
    this.fundraiserEnvironment = fundraiserContainer.get<FundraiserEnvironment>(sdkTypes.fundraiserEnvironment);
    this.donateService = fundraiserContainer.get<DonateService>(joinTypes.DonateService);
    this.componentId = 'fr-event-header';
    this.title = 'Page Header';
  }

  public fillData(data): void {
    this.fundraiserPage = this.fundraiserEnvironment.options.fundraiserPage;
    //
    this.fundraiserTeam = this.fundraiserEnvironment.options.fundraiserPage.team;
    this.honoree = this.fundraiserPage.honoree;
    this.honoreeType = this.fundraiserPage.honoreeType;
    this.fundraiserEvent = this.fundraiserEnvironment.options.fundraiserPage.event;
    this.eventTitle = this.fundraiserEnvironment.options.fundraiserCampaign.eventDefaultSubject;
    this.backgroundImage = this.domElement.get(0).dataset.backgroundImage != null ? this.domElement.get(0).dataset.backgroundImage : '';
    //
    this.teamLabel = this.domElement.get(0).dataset.teamLabel != null ? this.domElement.get(0).dataset.teamLabel : 'Team:';
    this.eventLabel = this.domElement.get(0).dataset.eventLabel != null ? this.domElement.get(0).dataset.eventLabel : 'Event:';
    //
    if (this.fundraiserEvent != null) {
      const date = moment(this.fundraiserEvent.date).tz('America/New_York');
      this.eventTitle = this.fundraiserEvent.name;
      if (this.fundraiserEvent.location !== null && this.fundraiserEvent.location !== '') {
        this.eventLocation = this.fundraiserEvent.location + ', ' + this.fundraiserEvent.address;
      } else {
        this.eventLocation = this.fundraiserEvent.city + ', ' + this.fundraiserEvent.address;
      }
      this.eventDate = date.format('MMMM DD, YYYY');
    }
    //determine if the register button is visible
    //SE (BigClimb)
    if(this.fundraiserEnvironment.options.fundraiserCampaign.name == "SE"){
      this.learnMoreUrl = "https://www.lls.org/big-climb";

      //determine if we are outside the registration window.      
      this.showRegisterBtn = this.fundraiserPage.event.acceptsRegistrations;
    }
    //try to find the profile image
    if(typeof this.fundraiserPage.imagePath === "string" &&
       typeof this.fundraiserEnvironment.options.fundraiserCampaign.imageURL === "string" &&
       this.fundraiserPage.imagePath != null &&
       this.fundraiserEnvironment.options.fundraiserCampaign.imageURL != null){
        this.profileImagePath = this.fundraiserEnvironment.options.fundraiserCampaign.imageURL + this.fundraiserPage.imagePath;
    }
  }

  public getGhostView(): string {
    return '';
  }

  public getEventView(): string {
    let html = '<div class="event-header-panel">' +
              '<h1>{{eventTitle}}</h1>' +
              '<h2>{{eventLocation}}</h2>' +
              '<h5><i class="fa fa-calendar"></i> {{eventDate}}</h5>';
    //determine if we need to show or hide the register button
    html += ((this.showRegisterBtn) ? 
            '<a href="#register-section"><button class="register-button">REGISTER</button></a>' :
            `<a href="${this.learnMoreUrl}"><button class="learnmore-button">MORE INFO</button></a>`);

    return html += '</div>'; 
  }

  public getTeamView(): string {
    return `
      <div class="team-header-panel">
        <div class="team-info">
          {{profileImage}}
          <div class="details">
            <h1>{{teamName}}</h1>
            <h5>{{teamLabel}}<a href="{{parentTeamHref}}">{{parentTeam}}</a></h5>
            <h2>{{memoryOf}}</h2>
            <h5>{{eventLabel}}<a href="{{eventHref}}">{{eventName}}</a></h5>
            <a href="{{donateHref}}"><button class="register-button alternative-button">DONATE</button></a>
          </div>
        </div>
      </div>`;
  }

  public getView(): string {
    //
    let view = '';
    if (this.fundraiserPage.fundraiserType === 'EVENT') {
      const array = [{
        key: 'eventTitle',
        value: this.eventTitle,
      }, {
        key: 'eventLocation',
        value: this.eventLocation,
      }, {
        key: 'eventDate',
        value: this.eventDate,
      }];
      //
      view = this.replaceHtmlParamsInView(this.getEventView(), array);
    } else {
      const eventHref = window.location.href.substring(0, window.location.href.lastIndexOf('/'));
      let displayName = '';
      if (this.fundraiserPage.fundraiserType === 'TEAM') {
        displayName = this.fundraiserTeam.teamName;
      } else {
        displayName = this.fundraiserPage.displayName;
      }
      const donateHref = this.donateService.buildDonationUrl(null, '');
      //
      let parentTeam = '';
      let parentTeamHref = 'javascript:;';
      let teamLabel = '';
      if (this.fundraiserPage.fundraiserType === 'TEAM' && this.fundraiserTeam.parentTeam !== undefined  && this.fundraiserTeam.parentTeam != null) {
        parentTeam = this.fundraiserTeam.parentTeam.teamName;
        parentTeamHref = this.fundraiserTeam.parentTeam.fullUrl;
        teamLabel = this.teamLabel + '&nbsp;';
      }else if (this.fundraiserPage.fundraiserType !== 'TEAM' && this.fundraiserTeam !== undefined  && this.fundraiserTeam !== null) {
        parentTeam = this.fundraiserTeam.teamName;
        parentTeamHref = this.fundraiserTeam.fullUrl;
        teamLabel = this.teamLabel + '&nbsp;';
      }
      //
      let memoryOf = '';
      if (this.honoreeType != null && this.honoree != null) {
        if (this.honoreeType === 'HONOR_OF') {
          memoryOf = 'In Honor of ' + this.honoree;
        } else if (this.honoreeType === 'MEMORY_OF') {
          memoryOf = 'In Memory of ' + this.honoree;
        }
      }
      let eventLabel = '';
      if (this.eventTitle != null && this.eventTitle !== '') {
        eventLabel = this.eventLabel + '&nbsp;';
      }

      let profileImage = "";
      if(this.profileImagePath != null){
        profileImage = `<div class="team-member-picture">
                              <img src="${this.profileImagePath}" />
                            </div>`;
      }

      //
      const array = [{
        key: 'eventLabel',
        value: eventLabel,
      }, {
        key: 'eventName',
        value: this.eventTitle,
      }, {
        key: 'eventHref',
        value: eventHref,
      }, {
        key: 'teamLabel',
        value: teamLabel,
      }, {
        key: 'teamName',
        value: displayName,
      }, {
        key: 'memoryOf',
        value: memoryOf,
      }, {
        key: 'parentTeam',
        value: parentTeam,
      }, {
        key: 'parentTeamHref',
        value: parentTeamHref,
      }, {
        key: 'donateHref',
        value: donateHref,
      }, {
        key: 'profileImage',
        value: profileImage,
      }];
      //
      view = this.replaceHtmlParamsInView(this.getTeamView(), array);
    }
    return view;
  }

  public getTitleView(): string {
    return '';
  }

  afterView(): boolean {
    if (this.backgroundImage) {
      window.jQuery('.fr-top').attr('style', 'background-image:' +
        'url(' + this.backgroundImage + ')');
    }
    window.jQuery('.fr-event-header').parent().prepend('<div class="overlay-panel"></div>');
    return true;
  }
}