import { injectable, inject } from 'inversify';
import moment from 'moment';
import { BaseFundraiserComponent } from '../../../base/base-fundraiser-component';
import fundraiserContainer from '../../../../container/fundraiser-container';
import { FundraiserEnvironment } from '../../../sdk/services/fundraiser-environment';
import sdkTypes from '../../../sdk/types/sdk-types';
import { FundraiserCampaign } from '../../../base/model/fundraiser-campaign';
import { FundraiserPage } from '../../../base/model/fundraiser-page';
import { FundraiserCampaignDonationRange } from '../../../base/model/fundraiser-campaign-donation-range';
import { FundraiserPageInput } from '../../../base/model/input/fundraiser-page-input';
import { formatCurrencyNoDecimals, setInputFilter } from '../../../../utils/utils';
import { FundraiserShoppingCartItem } from '../../../base/model/fundraiser-shopping-cart-item';
import joinTypes from '../../types/join-types';
import { DonateService } from '../services/donate-service';
import { FundraiserDisplayOptions } from '../../../base/model/fundraiser-display-options';
import he from 'he';

@injectable()
export class DonateComponent extends BaseFundraiserComponent{

  private fundraiserEnvironment: FundraiserEnvironment;
  private donateService: DonateService;
  private fundraiserPage: FundraiserPage;
  private fundraiserCampaign: FundraiserCampaign;
  private displayOptions: FundraiserDisplayOptions;
  private serverDate: moment.Moment;
  private donationUrl: string;
  private becomeSponsorUrl: string;
  private shopOurStoreUrl: string;
  private buyMerchandiseUrl: string;
  private donationRange: FundraiserCampaignDonationRange;
  private shoppingItems: FundraiserShoppingCartItem[];
  private hasEventTickets: boolean;
  private hasSponsorships: boolean;
  private hasMerchandise: boolean;
  private hideWarnings: boolean;
  private showButtonOnClosedDonation: boolean;
  private fundraisingClosed: boolean;
  private fundraisingNoAlloc: boolean;
  private donationClosedLink: string;
  private donationClosedLabel: string;
  private donationNoAllocLabel: string;
  private waitForKickoffLabel: string;
  private kickoffDate: number;
  private hideOptions: any;
  private useBubbles: any;
  private beforeKickoff = false;

  constructor() {
    super();
    this.fundraiserEnvironment = fundraiserContainer.get<FundraiserEnvironment>(sdkTypes.fundraiserEnvironment);
    this.donateService = fundraiserContainer.get<DonateService>(joinTypes.DonateService);
    this.componentId = 'fr-donate';
    this.title = 'Donate now';
  }

  public scanDataAttributes(): void {
    this.hideWarnings = this.domElement.get(0).dataset.hideWarnings != null ? (this.domElement.get(0).dataset.hideWarnings === 'true') : false;
    this.hideOptions = this.domElement.get(0).dataset.hideOptions != null ? (this.domElement.get(0).dataset.hideOptions === 'true') : false;
    this.useBubbles = this.domElement.get(0).dataset.useBubbles != null ? (this.domElement.get(0).dataset.useBubbles === 'true') : false;
    //
    this.showButtonOnClosedDonation =
      this.domElement.get(0).dataset.showButtonOnClosedDonation != null ? (this.domElement.get(0).dataset.showButtonOnClosedDonation === 'true') : false;
    this.donationClosedLabel = this.domElement.get(0).dataset.donationClosedLabel != null ? this.domElement.get(0).dataset.donationClosedLabel
      : 'We are no longer accepting donations for this event, however you can still make a donation to The Leukemia & Lymphoma Society.';
    this.donationNoAllocLabel = this.domElement.get(0).dataset.donationNoAllocLabel != null ? this.domElement.get(0).dataset.donationNoAllocLabel
      : 'Donations cannot be accepted for this team; there are no team members.';
    this.waitForKickoffLabel = this.domElement.get(0).dataset.waitForKickoffLabel != null ? this.domElement.get(0).dataset.waitForKickoffLabel
      : 'You will be able to donate and purchase items after the Kick Off date below.';
    this.donationClosedLink = this.domElement.get(0).dataset.donationClosedLink != null ? this.domElement.get(0).dataset.donationClosedLink
      : '';
  }

  public fillData(data): void {
    this.data = data;
    //
    this.fundraiserCampaign = this.fundraiserEnvironment.options.fundraiserCampaign;
    this.fundraiserPage = this.fundraiserEnvironment.options.fundraiserPage;
    // Convert server date to UTC, since we cannot assume the timezone.
    // In fact, server, kickoff, and closed dates could all be in different timezones and need to be compared in UTC.
    this.serverDate = moment(this.fundraiserEnvironment.options.serverDate).subtract(this.fundraiserEnvironment.options.serverDateOffset, 'hour');
    this.shoppingItems = this.fundraiserEnvironment.options.shoppingItems;
    this.displayOptions = this.fundraiserEnvironment.options.displayOptions;
    //
    if (this.shoppingItems != null) {
      this.hasEventTickets = this.shoppingItems.filter(d => d.categoryName.toUpperCase() === 'EVENT TICKETS').length > 0;
      this.hasSponsorships = this.shoppingItems.filter(d => d.categoryName.toUpperCase() === 'SPONSORSHIP').length > 0;
      this.hasMerchandise = this.shoppingItems.filter(d => d.categoryName.toUpperCase() === 'MERCHANDISE'
        || d.categoryName.toUpperCase() === 'PROGRAM BOOK ADS'
        || d.categoryName.toUpperCase() === 'PACKAGED DEALS').length > 0;
    }
    //
    if (!this.hideOptions && this.fundraiserEnvironment.options.campaignDonationRanges != null) {
      this.donationRange = this.fundraiserEnvironment.options.campaignDonationRanges[0];
    }
    //
    // Move the donation url to a service. So we can create a new component for the menu bar donate button.
    //
    this.donationUrl = this.donateService.buildDonationUrl(null, this.donationClosedLink);
    //
    this.fundraisingNoAlloc = false;
    if (this.fundraiserPage.team != null) {
      //workaround for schools without changing the behavior of non-schools
      if (this.fundraiserPage.team.teamType === 'SCHOOL' ) {
        this.fundraisingNoAlloc = !(this.fundraiserPage.team.activeTeamMembers && this.fundraiserPage.team.activeTeamMembers > 0)
      } else {
        this.fundraisingNoAlloc = this.fundraiserPage.team.members == null || this.fundraiserPage.team.members.length === 0;
      }
    }
    // Convert to UTC
    const closeDonationsDate = moment(this.fundraiserPage.closeDonationsDate).subtract(this.fundraiserPage.event.timezoneOffset, 'hour');
    this.fundraisingClosed = this.serverDate.isAfter(closeDonationsDate);
    //
    this.shopOurStoreUrl = null;
    if (this.fundraiserCampaign.name === 'REGATTA') {
      const shopLink = window.jQuery('li.shop-link a');
      if (shopLink.length) {
        this.shopOurStoreUrl = shopLink.attr('href');
      }
    }
    this.beforeKickoff = false;
    if (this.fundraiserPage.kickoffDate != null) {
      // Convert to UTC
      this.kickoffDate = this.fundraiserPage.kickoffDate;
      this.beforeKickoff = this.serverDate.isBefore(moment(this.kickoffDate).subtract(this.fundraiserPage.kickoffTimezoneOffset, 'hour'));
    }
    if (this.fundraiserCampaign.name === 'MWOY') {
      if (this.hasSponsorships) {
        this.becomeSponsorUrl = `/${this.fundraiserCampaign.lookupName.toLowerCase()}/items/?action=SPONSORSHIP&fundraiserPageURL=` + 
          this.fundraiserPage.fullUrl;
      }
      if (this.hasEventTickets) {
        this.shopOurStoreUrl = `/${this.fundraiserCampaign.lookupName.toLowerCase()}/items/?action=EVENT_TICKETS&fundraiserPageURL=` + 
          this.fundraiserPage.fullUrl;
      }
      if (this.hasMerchandise) {
        this.buyMerchandiseUrl = `/${this.fundraiserCampaign.lookupName.toLowerCase()}/items/?fundraiserPageURL=` + 
          this.fundraiserPage.fullUrl;
      }
    }
  }

  public requestData(pageInput: FundraiserPageInput) {
    if (!this.hideOptions) {
      pageInput.fetchCampaignDonationRange = true;
    }
  }

  public getGhostView(): string {
    return '';
  }

  public getView(): string {
    // Do not show widget if content is empty
    let view =  this.getTitleView() +
      '<div class="fr-widget__body">';
    //
    if (!this.hideWarnings) {
      if (this.fundraisingNoAlloc) {
        view += '<span class="donations-not-accepted">' + this.donationNoAllocLabel + '</span>';
      } else if (this.fundraisingClosed) {
        view += '<span class="donations-not-accepted">' + this.donationClosedLabel + '</span>';
      }
    }
      //
      if (this.beforeKickoff && this.fundraiserCampaign.name === 'MWOY') {
        // This message only disables but didn't hide options
        view += '<span class="donations-not-accepted">' + this.waitForKickoffLabel + '</span>';
        view += '<div class="kickoff-date">' + moment(this.kickoffDate).tz('America/New_York').format('dddd, MMMM DD, YYYY hh:mm A ') +
          this.fundraiserPage.kickoffTimezone + '</div>';
      }
      //
      view += '<form action="' + this.donationUrl + ' " method="post" id="donateForm">';
      if (!this.hideOptions) {
        if ((!(this.fundraiserCampaign.name === 'MWOY' && this.beforeKickoff) && !this.fundraisingClosed) || 
            (this.fundraisingClosed && this.showButtonOnClosedDonation)) {
          view += '';
          if (!this.fundraisingClosed) {
            if (!this.useBubbles) {
              view += '' +
                  '<div class="d-flex fr-widget__body-donate-text">' +
                  '<span>Join ' + this.fundraiserPage.displayName + ' in the Mission to wipe out blood cancer:</span>' +
                  '</div>' +
                  '<input type="hidden" name="amount" id="amount"/>';
            } else {
              view += '' +
                  '<div class="d-flex fr-widget__body-donate-text">' +
                  '<span>Make a Donation</span>' +
                  '</div>';
            }
          }
          view += '<div class="fr-widget__body-donate-amounts">' + this.buildOptions() + '</div>';
        }
      }
      //
      // Only for Regatta Team Pages
      if (this.fundraiserCampaign.name === 'REGATTA' && this.fundraiserPage.fundraiserType === 'TEAM' && this.displayOptions.showRosterLinks) {
        //
        view += '<div class="d-flex justify-content-center">' +
          '<div class="styled-select">' +
          '<select id="selectTeamMember">' +
          '<option>Donate to a Team Member</option>' +
          '<optgroup label="Select a Team Member">';
        //
        this.fundraiserPage.team.members.sort((a, b) => {
          if (a.lastName < b.lastName) { return -1; }
          if (a.lastName > b.lastName) { return 1; }
          return 0;
        }).forEach((member) => {
          view += '<option value="' + member.fundraiserPageUrl + '">' + member.firstName + ' ' + member.lastName +  '</option>';
        });
        //
        view += '</optgroup> ' +
          '</select>' +
          '</div> ' +
          '</div>';
        //
      }
      //
      if ((!(this.fundraiserCampaign.name === 'MWOY' && this.beforeKickoff) && !this.fundraisingClosed) || 
          (this.fundraisingClosed && this.showButtonOnClosedDonation)) {
        view += '<div class="d-flex justify-content-center">' +
          '   <button class="btn button" type="submit" id="donateSubmit">Donate</button>' +
          '</div>';
      }
      //
      if (!(this.fundraiserCampaign.name === 'MWOY' && this.beforeKickoff) && !this.fundraisingClosed && this.becomeSponsorUrl != null) {
        view += '<hr />';
        view += '<div class="d-flex justify-content-center">' +
          '   <a href="' + this.becomeSponsorUrl + '" class="shop-button become-sponsor-button" id="shopSubmit" target="_self">Become a sponsor</a>' +
          '</div>';
      }
      //
      if (!(this.fundraiserCampaign.name === 'MWOY' && this.beforeKickoff) && !this.fundraisingClosed && this.shopOurStoreUrl != null) {
        let shopLabel = 'Shop our store';
        if (this.fundraiserCampaign.name === 'MWOY') {
          shopLabel = 'Purchase event tickets';
        }
        view += '<hr />';
        view += '<div class="d-flex justify-content-center">' +
          '   <a href="' + this.shopOurStoreUrl + '" class="shop-button shop-our-store-button" id="shopSubmit" target="_self">' + shopLabel + '</a>' +
          '</div>';
      }
      //
      if (!(this.fundraiserCampaign.name === 'MWOY' && this.beforeKickoff) && !this.fundraisingClosed && this.buyMerchandiseUrl != null) {
        let merchLabel = 'Buy Merchandise';
        if (this.fundraiserCampaign.name === 'MWOY') {
          merchLabel = 'Additional Opportunities';
        }
        view += '<hr />';
        view += '<div class="d-flex justify-content-center">' +
          '   <a href="' + this.buyMerchandiseUrl + '" class="shop-button buy-merchandise-button" id="shopSubmit" target="_self">' + merchLabel + '</a>' +
        '</div>';
      }
      //
      view += '</form>';
      //
      view += '</div>';
    //
    return view;
  }

  public buildOptions():string {
    let view = '';
    //
    if (this.donationRange != null) {
      if (!this.useBubbles) {
        view += he.decode(this.donationRange.donationAmountHtml);
      }else {
        view += '<ul class="bubbles-container">';
        //
        this.donationRange.donationAmountList.forEach((option) => {
          if (option.value !== 0) {
            view += '<li class="bubble">' +
              '<div class="bubble-value" data-value="' + option.value + '">' + formatCurrencyNoDecimals(option.value) + '</div>' +
              '<div class="bubble-label">' + option.key + '</div>' +
              '</li>';
          }
        });
        //
        view += '</ul>';
        //
        this.donationRange.donationAmountList.forEach((option) => {
          if (option.value === 0) {
            view += '<div class="other">' +
              'Other amount ' +
              '<div>' +
              '<span>$</span>' +
              '<input type="text" value="" name="amount" id="amount"/>' +
              '</div>' +
              '</div>';
          }
        });
      }
    }
    //
    return view;
  }

  public afterView(): boolean {
    if (this.fundraiserCampaign.name === 'MWOY') {
      if (!this.beforeKickoff) {
        this.enableFormButtons();
      }else {
        // Disable form
        window.jQuery('#donateForm').on('submit', (event) => {
          return false;
        });
      }
    }else {
      this.enableFormButtons();
    }
    if (this.fundraiserCampaign.name === 'REGATTA' && this.fundraiserPage.fundraiserType === 'TEAM' && this.displayOptions.showRosterLinks) {
      window.jQuery('#selectTeamMember').on('change', (event) => {
        window.location.href = window.jQuery('#selectTeamMember').val() + '';
      });
    }
    return true;
  }

  public enableFormButtons(): void {
    if (this.useBubbles) {
      // Filter input for only numbers
      setInputFilter(document.getElementById('amount'), (value) => {
        return /^\d*\.?\d*$/.test(value);
      });
      //
      window.jQuery('ul.bubbles-container li').on('click', (event) => {
        //
        let target = window.jQuery(event.target);
        //
        if (window.jQuery(event.target).is('div')) {
          target = window.jQuery(event.target).parent();
        }
        if (!target.hasClass('active')) {
          window.jQuery('ul.bubbles-container li').removeClass('active');
          target.addClass('active');
          window.jQuery('#donateForm #amount').val(target.find('.bubble-value').data('value'));
        }else {
          window.jQuery('ul.bubbles-container li').removeClass('active');
          window.jQuery('#donateForm #amount').val('');
        }
        this.rebuildUrlWithAmount();
      });
      window.jQuery('#amount, [name=amount]').on('focus', (event) => {
        window.jQuery('ul.bubbles-container li').removeClass('active');
      });
      window.jQuery('#amount, [name=amount]').on('input', (event) => {
        this.rebuildUrlWithAmount();
      });
      window.jQuery('#donateForm').on('submit', (event) => {
        window.location.href = window.jQuery('#donateForm').attr('action');
        return false;
      });
    } else if (!this.fundraisingClosed && !this.fundraisingNoAlloc) {
      window.jQuery('#donateForm').on('submit', (event) => {
        const checkedRadio = window.jQuery('#donateForm input[name="donateAmount"]:checked').val();
        let amount = checkedRadio;
        if (checkedRadio === '0') {
          // "Other" is selected
          amount = window.jQuery('#donateForm input[name="enteredAmount"]').val();
        }
        window.jQuery('#donateForm #amount').val(amount);
        return true;
      });
    }
  }

  public rebuildUrlWithAmount(): void {
    const val =  window.jQuery('#amount').val();
    const url = this.donateService.buildDonationUrl(val, this.donationClosedLink);
    window.jQuery('#donateForm').attr('action', url);
  }

}
