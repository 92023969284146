import { injectable, inject } from 'inversify';
import { BaseFundraiserComponent } from '../../../base/base-fundraiser-component';
import fundraiserContainer from '../../../../container/fundraiser-container';
import { FundraiserEnvironment } from '../../../sdk/services/fundraiser-environment';
import sdkTypes from '../../../sdk/types/sdk-types';
import { FundraiserCampaign } from '../../../base/model/fundraiser-campaign';
import { FundraiserPage } from '../../../base/model/fundraiser-page';

@injectable()
export class ParticipateForComponent extends BaseFundraiserComponent{

  private fundraiserEnvironment: FundraiserEnvironment;
  private fundraiserPage: FundraiserPage;
  private fundraiserCampaign: FundraiserCampaign;
  private titleInHonorOf: string;
  private titleInMemoryOf: string;
  private honoree: string;
  private honoreeType: string;

  constructor() {
    super();
    this.fundraiserEnvironment = fundraiserContainer.get<FundraiserEnvironment>(sdkTypes.fundraiserEnvironment);
    this.componentId = 'fr-participate-for';
    this.title = 'Participate For';
  }

  public scanDataAttributes(): void {
    this.titleInHonorOf = this.domElement.get(0).dataset.titleInHonorOf != null ? this.domElement.get(0).dataset.titleInHonorOf
      : 'In Honor Of';
    this.titleInMemoryOf = this.domElement.get(0).dataset.titleInMemoryOf != null ? this.domElement.get(0).dataset.titleInMemoryOf
      : 'In Memory Of';
  }

  public fillData(data): void {
    this.data = data;
    this.fundraiserPage = this.fundraiserEnvironment.options.fundraiserPage;
    this.honoree = this.fundraiserPage.honoree;
    this.honoreeType = this.fundraiserPage.honoreeType;
    //
  }

  public getGhostView(): string {
    const view = this.getTitleView() +
      '<div class="fr-widget__body">' +
      ' <h5 class="h-75">{{honoree}}</h5>' +
      ' </div>' +
      '</div>';
    return view;
  }

  public getView(): string {
    if (this.honoreeType != null && this.honoree != null) {
      if (this.honoreeType === 'HONOR_OF') {
        this.title = this.titleInHonorOf;
      }else if (this.honoreeType === 'MEMORY_OF') {
        this.title = this.titleInMemoryOf;
      }
      return this.replaceHtmlParamsInView(this.getGhostView(), [{
        key: 'honoree',
        value: this.honoree,
      }]);
    }
    return '';
  }

}