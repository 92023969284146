import { injectable, inject } from 'inversify';
import { BaseFundraiserComponent } from '../../../base/base-fundraiser-component';
import fundraiserContainer from '../../../../container/fundraiser-container';
import { FundraiserEnvironment } from '../../../sdk/services/fundraiser-environment';
import sdkTypes from '../../../sdk/types/sdk-types';
import { FundraiserNewsFeed } from '../../../base/model/fundraiser-news-feed';
import { FundraiserPageInput } from '../../../base/model/input/fundraiser-page-input';
import he from 'he';

@injectable()
export class NewsFeedComponent extends BaseFundraiserComponent{

  private fundraiserEnvironment: FundraiserEnvironment;
  private newsFeed: FundraiserNewsFeed;

  constructor() {
    super();
    this.fundraiserEnvironment = fundraiserContainer.get<FundraiserEnvironment>(sdkTypes.fundraiserEnvironment);
    this.componentId = 'fr-news-feed';
    this.title = 'News Feed';
  }

  requestData(pageInput: FundraiserPageInput) {
    pageInput.fetchNewsFeed = true;
  }

  public fillData(data): void {
    this.newsFeed = this.fundraiserEnvironment.options.newsFeed;
  }

  public getGhostView(): string {
    let view = this.getTitleView();
    view += '<div class="fr-widget__body">';
    view += '<h5 class="h70"></h5><h5 class="h60"></h5><h5 class="h40"></h5>';
    view += '</div>';
    return view;
  }

  public getView(): string {
    let view = '';
    if (this.newsFeed != null && this.newsFeed.htmlContent != null && this.fundraiserEnvironment.options.displayOptions.showNewsFeed) {
      view = this.getTitleView();
      view += '<div class="fr-widget__body">';
      view += he.decode(this.newsFeed.htmlContent);
      view += '</div>';
    }
    return view;
  }
}