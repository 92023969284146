import { injectable } from 'inversify';
import moment from 'moment';
import 'moment-timezone-slim';
import { BaseFundraiserComponent } from '../../../base/base-fundraiser-component';
import fundraiserContainer from '../../../../container/fundraiser-container';
import { FundraiserEnvironment } from '../../../sdk/services/fundraiser-environment';
import sdkTypes from '../../../sdk/types/sdk-types';
import { LeaderboardService } from '../services/leaderboard-service';
import contentTypes from '../../types/content-types';
import { FundraiserEventLeaderboard } from '../../../base/model/fundraiser-event-leaderboard';
import { FundraiserPage } from '../../../base/model/fundraiser-page';
import { formatCurrency, formatCurrencyNoDecimals } from '../../../../utils/utils';
import { FundraiserCampaign } from '../../../base/model/fundraiser-campaign';

@injectable()
export class LeaderboardComponent extends BaseFundraiserComponent{

  private fundraiserEnvironment: FundraiserEnvironment;
  private fundraiserPage: FundraiserPage;
  private fundraiserCampaign: FundraiserCampaign;
  private leaderboardService: LeaderboardService;
  private leaderboardData: FundraiserEventLeaderboard[];
  private isNationalTeam;
  private max: number;
  private pagination: number;
  private viewableElements: number;
  private viewMoreText: string;
  private viewLessText: string;
  private splitTabs: any;
  //
  private corporateTeamsLabel: string;
  private friendsFamilyTeamsLabel: string;
  private individualsLabel: string;
  private topTeamsLabel: string;
  private topIndividualsLabel: string;
  //
  private corporateData: FundraiserEventLeaderboard[];
  private schoolData: FundraiserEventLeaderboard[];
  private teamData: FundraiserEventLeaderboard[];
  private friendFamilyData: FundraiserEventLeaderboard[];
  private individualData: FundraiserEventLeaderboard[];
  private date: any;

  constructor() {
    super();
    this.fundraiserEnvironment = fundraiserContainer.get<FundraiserEnvironment>(sdkTypes.fundraiserEnvironment);
    this.leaderboardService = fundraiserContainer.get<LeaderboardService>(contentTypes.LeaderboardService);
    this.componentId = 'fr-leaderboard';
    this.title = 'Top Fundraisers';
  }

  public fillData(data): void {
    this.leaderboardData = data;

    this.max = this.domElement.get(0).dataset.max != null ? parseInt(this.domElement.get(0).dataset.max, 10) : 10;
    this.viewableElements = this.max;
    this.pagination = this.domElement.get(0).dataset.pagination != null ? parseInt(this.domElement.get(0).dataset.pagination, 10) : 10;
    this.viewMoreText = this.domElement.get(0).dataset.viewMoreText != null ? this.domElement.get(0).dataset.viewMoreText
      : 'View More';
    this.viewLessText = this.domElement.get(0).dataset.viewLessText != null ? this.domElement.get(0).dataset.viewLessText
      : 'View Less';
    this.splitTabs = this.domElement.get(0).dataset.splitTabs != null ? (this.domElement.get(0).dataset.splitTabs === 'true') : false;
    //
    this.corporateTeamsLabel = 'Corporate Teams';
    this.friendsFamilyTeamsLabel = 'Friends & Family Teams';
    this.individualsLabel = 'Individuals Participants';
    //
    this.topTeamsLabel = 'Teams';
    this.topIndividualsLabel = 'Individual Participants';
    if (this.isNationalTeam) {
      this.topTeamsLabel = 'Top Teams';
      this.topIndividualsLabel = 'Top Individuals';
    }
    if (this.fundraiserCampaign.name === 'SE') {
      this.corporateTeamsLabel = 'Top Corporate Teams';
      this.friendsFamilyTeamsLabel = 'Top Friends and Family Teams';
      this.individualsLabel = 'Top Fundraisers';
    } else if (this.fundraiserCampaign.name === 'LTN') {
      this.friendsFamilyTeamsLabel = 'Community & School Teams';
    }
    //
    this.teamData = this.leaderboardData.filter(d => d.type === 'CORPORATE' || d.type === 'FRIEND_FAMILY' || d.type === 'SCHOOL')
      .sort((d, e) => e.totalFundraisedAmount - d.totalFundraisedAmount);
    //
    this.corporateData =  this.leaderboardData.filter(d => d.type === 'CORPORATE');
    if (this.fundraiserCampaign.name === 'LTN') {
      this.friendFamilyData = this.leaderboardData.filter(d => d.type === 'FRIEND_FAMILY' || d.type === 'SCHOOL')
          .sort((d, e) => e.totalFundraisedAmount - d.totalFundraisedAmount);
    } else {
      this.friendFamilyData = this.leaderboardData.filter(d => d.type === 'FRIEND_FAMILY');
    }
    this.individualData = this.leaderboardData.filter(d => d.type === 'INDIVIDUAL');
    //
    this.date = moment();
    if (this.corporateData.length > 0) {
      this.date = moment(this.corporateData[0].batchCreatedDate);
    }else if (this.friendFamilyData.length > 0) {
      this.date = moment(this.friendFamilyData[0].batchCreatedDate);
    }else if(this.individualData.length > 0) {
      this.date = moment(this.individualData[0].batchCreatedDate);
    }
  }

  public prepareData(): Promise<any> {
    this.fundraiserPage = this.fundraiserEnvironment.options.fundraiserPage;
    this.fundraiserCampaign = this.fundraiserEnvironment.options.fundraiserCampaign;
    this.isNationalTeam = this.fundraiserPage.fundraiserType === 'NATIONAL_TEAM';
    if (this.fundraiserPage.fundraiserType === 'EVENT') {
      console.log("FETCHING EVENT LEADERBOARD: ");
      console.log(this.leaderboardService.fetchEventLeaderboard());
      console.log("=============================");


      return this.leaderboardService.fetchEventLeaderboard();
    }
    if (this.fundraiserPage.fundraiserType === 'TEAM' ||
      this.fundraiserPage.fundraiserType === 'NATIONAL_TEAM') {
      console.log("FETCHING TEAM LEADERBOARD: ");
      console.log(this.leaderboardService.fetchTeamLeaderboard());
      console.log("=============================");

      return this.leaderboardService.fetchTeamLeaderboard();
    }
    return super.prepareData();
  }
  public getView(): string {
    let view = null;
    if (this.splitTabs) {
      view = this.getSplittedView();
    } else {
      view = this.getTabView();
    }
    return view;
  }

  public getSplittedView(): string {
    const page_fundraiser_data = this.fundraiserEnvironment.options.fundraiserData;

    let page_fundraiser_teamType_data = null;

    if( !!Object.getOwnPropertyDescriptor(page_fundraiser_data, 'team') == true ){
      page_fundraiser_teamType_data = page_fundraiser_data.team.teamType;
    }

    let view = `<div class="fr-widget__body">`;
    //

    if (this.fundraiserPage.fundraiserType != 'NATIONAL_TEAM' || page_fundraiser_teamType_data != 'SCHOOL_DISTRICT' || this.fundraiserCampaign.name != 'LTN' ){
      view += this.buildSplittedTab('tab-individual', this.individualsLabel, this.individualData);
    }

    view += this.buildSplittedTab('tab-friends', this.friendsFamilyTeamsLabel, this.friendFamilyData);
    view += this.buildSplittedTab('tab-corporate', this.corporateTeamsLabel, this.corporateData);
    //
    view += `</div>`;
    return view;
  }

  private build_teams_list_partial(members: FundraiserEventLeaderboard[]): string{
    let view_output = ``;

    members.forEach((member, index) => {
      const colorStyle = (index + 1) >= 4 ? 'row-gray' : '';
      const visibleStyle = (index + 1) > this.viewableElements ? '' : 'show';

      let inner_partial = ``;

      if (member.displayName.length < 40) {
        inner_partial += member.displayName;
      } else {
        inner_partial += member.displayName.substring(0, 40) + '...';
      }

      view_output += `
        <tr class="teamsList ${colorStyle} ${visibleStyle}">
          <td>${(index + 1)}</td>
          <td> <a target="_blank" href="${member.fullUrl}"> ${inner_partial} </a>
          <td>${formatCurrency(member.totalFundraisedAmount)}</td>
        </tr>
      `;
    });

    return view_output;
  }

  public buildSplittedTab(tabId, label, members): string {
    let teams_list_partial = ``;
    let load_more_fundraisers_partial = ``;
    let empty_row_partial = ``;

    //Partial 1
    teams_list_partial = this.build_teams_list_partial(members);

    //Partial 2
    if (members.length > this.viewableElements) {
      load_more_fundraisers_partial = `
        <tr>
          <td colspan="3"><button class="secondary-button load-more-fundraisers" data-tab-id="${tabId}Content">${this.viewMoreText}</button> </td>
        </tr>
      `;
    }

    //Partial 3
    if (members.length <= this.viewableElements) {
      empty_row_partial = `
        <tr class="empty-row">
          <td colspan="3"> </td>
        </tr>
      `;
    }

    const view = `
      <div id="${tabId}Content" class="leaderboard-splitted-tab ${tabId}">
        <table>
          <thead>
              <tr>
                <th colspan="3">${label}</th>
              </tr>
              <tr>
                <th>Rank</th>
                <th>Name</th>
                <th>Raised</th>
              </tr>
          </thead>
          <tbody>

            ${teams_list_partial}

          </tbody>
          <tfoot>

            <tr style="display:none">
              <td colspan="3"><button class="secondary-button load-less-fundraisers" data-tab-id="${tabId}Content"> ${this.viewLessText}</button> </td>
            </tr>

            ${load_more_fundraisers_partial}

            ${empty_row_partial}

          </tfoot>
        </table>

      </div>     
    `;

    return view;
  }

  public getTabView(): string {
    let tabs_header_partial = ``;
    let tabs_content_partial = ``;

    let teamType = '';
    if (this.fundraiserEnvironment.options.fundraiserPage.team) {
      teamType = this.fundraiserEnvironment.options.fundraiserPage.team.teamType;
    }

    // Do not show widget if content is empty
    const view_header = this.getTitleView();

    let active = 'CORPORATE';
    if (this.fundraiserCampaign.name === 'TNT') {
      active = 'INDIVIDUAL';
    }

    //Partial 1
    if (this.fundraiserPage.fundraiserType === 'EVENT' && this.fundraiserCampaign.name !== 'REGATTA') {
      tabs_header_partial += this.buildMembersHeader('corporate-tab', this.corporateTeamsLabel, active === 'CORPORATE') +
        this.buildMembersHeader('friends-tab', this.friendsFamilyTeamsLabel, active === 'FAMILY') +
        this.buildMembersHeader('individual-tab', this.individualsLabel, active === 'INDIVIDUAL');
    }else {
      tabs_header_partial += this.buildMembersHeader('teams-tab', this.topTeamsLabel, true);

      console.log("======================");
      console.log("Fundraiser Type: "+ this.fundraiserPage.fundraiserType);
      console.log("Fundraiser Team Type: "+ teamType);
      console.log("Fundraiser Campaign Type: "+ this.fundraiserCampaign.name);
      console.log("======================");

      if(this.fundraiserPage.fundraiserType != 'NATIONAL_TEAM' || teamType != 'SCHOOL_DISTRICT' || this.fundraiserCampaign.name != 'LTN' ){
        tabs_header_partial += this.buildMembersHeader('individual-tab', this.topIndividualsLabel, false);
      }
    }

    //Partial 2
    if (this.fundraiserPage.fundraiserType === 'EVENT' && this.fundraiserCampaign.name !== 'REGATTA') {
      tabs_content_partial += this.buildMembersContent('team', 'corporate-tab', this.corporateTeamsLabel, this.corporateData, active === 'CORPORATE');
      tabs_content_partial += this.buildMembersContent('team', 'friends-tab', this.friendsFamilyTeamsLabel, this.friendFamilyData, active === 'FAMILY');
      tabs_content_partial += this.buildMembersContent('individual', 'individual-tab', this.individualsLabel, this.individualData, active === 'INDIVIDUAL');
    }else {
      tabs_content_partial += this.buildMembersContent('team', 'teams-tab', this.topTeamsLabel, this.teamData, true);

      if(this.fundraiserPage.fundraiserType != 'NATIONAL_TEAM' || teamType != 'SCHOOL_DISTRICT' || this.fundraiserCampaign.name != 'LTN' ){
        tabs_content_partial += this.buildMembersContent('individual', 'individual-tab', this.topIndividualsLabel, this.individualData, false);
      }
      
    }

    const view = `
      ${view_header}

      <div class="fr-widget__body">
        <div class="fr-widget__body-inline-header">
          <div class="h4-wrapper"><h4>${this.title}</h4></div>
          <ul class="nav nav-tabs" id="myTab" role="tablist">

            ${tabs_header_partial}

          </ul>
        </div>
        <div class="tab-content" id="myTabContent">

          ${tabs_content_partial}

        </div>
      </div>
    `;

    return view;
  }

  public buildMembersHeader(tabId: string, tabName: string, active: boolean): string {
    const style = active ? 'active' : '';
    const view =  `
      <li class="nav-item">
        <a class="nav-link ${style}" id="${tabId}" data-bs-toggle="tab" href="#${tabId}Content" role="tab" aria-controls="${tabId}Content"
          aria-selected="true">${tabName}</a>
      </li>
    ` ;
    //
    return view;
  }

  private create_member_row_partial(type: string, percentage: number, initialAmount: number, members: FundraiserEventLeaderboard[]): string{
    let view_output = ``;

    members.forEach((member, index) => {
      let visible = false;
      if (index < this.viewableElements) {
        visible = true;
      }
      const memberPercentage = percentage * member.totalFundraisedAmount / initialAmount;
      view_output += this.addRow(type, index + 1, member.displayName, member.fullUrl, memberPercentage, 
        member.totalFundraisedAmount, member.teamMemberCount, visible);
    });

    return view_output;
  }
  
  public buildMembersContent(type: string, tabId: string, label: string, members: FundraiserEventLeaderboard[], active: boolean): string {
    const style = active ? 'show active' : '';
    let no_top_fundraisers_partial = ``;
    let member_row_partial = ``;
    let last_row_updated_partial = ``;
    let see_more_partial = ``;

    const percentage = 95;
    let initialAmount = 0;
    console.log("Members: "+members.length);
    
    if (members.length > 0) {
      initialAmount = members[0].totalFundraisedAmount;
    } else {
      //Partial 1
      if (this.isNationalTeam) {
        no_top_fundraisers_partial = `<tr class="missing"><td colspan="7">No top fundraisers are available at the moment!</td></tr>`;
      } else {
        no_top_fundraisers_partial = `<tr class="missing"><td colspan="5">No top fundraisers are available at this time in ${label}.</td></tr>`;
      }
    }

    //Partial 2
    member_row_partial = this.create_member_row_partial(type, percentage, initialAmount, members);

    //Partial 3
    if (!this.isNationalTeam || members.length > 0) {
      last_row_updated_partial = `
        <tr class="lastRow"><td class="lastupdated" colspan="5">Last updated on 
          ${this.date.tz('America/New_York').format('ddd MMM DD, YYYY @ hh:mm A z')}</td></tr>
      `;
    }

    //Partial 4
    if (members.length > this.viewableElements) {
      see_more_partial = `
        <tr class="trSeeMore">
          <td class="seeMore" colspan="5">
            <button class="load-more-fundraisers" data-tab-id="${tabId}Content"> ${this.viewMoreText} </button>  
          </td>
        </tr>
      `;
    }

    const view = `
      <div class="tab-pane fade ${style}" id="${tabId}Content" role="tabpanel" aria-labelledby="${tabId}-label">
        <table class="table table-striped leaderboard">
        <tbody>

          ${no_top_fundraisers_partial}

          ${member_row_partial}

          ${last_row_updated_partial}

          <tr class="trSeeLess" style="display:none">
            <td class="seeLess" colspan="5">
              <button  class="load-less-fundraisers" data-tab-id="${tabId}Content"> ${this.viewLessText} </button>  
            </td>
          </tr>

          ${see_more_partial}

        </tbody>
        </table>
      </div>
    `;
    
    return view;
  }

  public addRow(type: string, position: number, displayName: string, link: string, percentage: number, amount: number,
                membersCount: number, visible: boolean): string {
    // Do not show widget if content is empty
    const barStyle = position <= 3 ? 'topFundraiser' : 'regularFundraiser';
    const rowStyle = visible ? 'show' : '';

    let display_members_partial = ``;
    //
    let positionStr;
    if (this.isNationalTeam) {
      positionStr = this.padNumber(position);
    } else {
      positionStr = '' + position;
    }

    //Partial 1
    if (this.isNationalTeam && type === 'team') {
      display_members_partial = `
        <td class="walker"></td>
        <td class="members">${membersCount}</td>
      `;
    }

    const view = `
      <tr class="teamsList ${rowStyle}">
        <td class="number rank text-center font-weight-bold">${positionStr}</td>
        <td class="progress">
          <div class="fullbar" style="position: relative;">
            <div class="bar ${barStyle}" style="width: ${percentage}%;"></div>
            <div class="username">
              <a target="_blank" href="${link}"> ${displayName} </a>
            </div>
          </div>
        </td>
        <td class="number money moneyRaisedLB text-right">${formatCurrencyNoDecimals(amount)}</td>

        ${display_members_partial}

      </tr>
    `;

    //
    return view;
  }

  public refresh(tabId: string): void {
    window.jQuery('#' + tabId).find('.teamsList').each((index, e) => {
      if (index + 1 <= this.viewableElements) {
        window.jQuery(e).addClass('show');
      } else {
        window.jQuery(e).removeClass('show');
      }
    });
    //
    if (this.viewableElements >= window.jQuery('#' + tabId).find('.teamsList').length) {
      window.jQuery('#' + tabId).find('.load-more-fundraisers').hide();
    }else {
      window.jQuery('#' + tabId).find('.load-more-fundraisers').show();
    }
    if (this.pagination < this.viewableElements) {
      window.jQuery('#' + tabId).find('.load-less-fundraisers').parent().parent().show();
    }else {
      window.jQuery('#' + tabId).find('.load-less-fundraisers').parent().parent().hide();
    }
  }

  public afterView(): boolean {
    window.jQuery('.load-less-fundraisers').on('click', (event) => {
      const tabId = window.jQuery(event.target).data('tab-id');
      this.viewableElements -= this.pagination;
      this.refresh(tabId);
      return true;
    });
    window.jQuery('.load-more-fundraisers').on('click', (event) => {
      const tabId = window.jQuery(event.target).data('tab-id');
      this.viewableElements += this.pagination;
      this.refresh(tabId);
      return true;
    });

    // mobile stuff
    window.jQuery('.fr-leaderboard ul.nav li:first-child').addClass('mobile-li-open'); // @TODO: find opened tab and assign to appropriate one
    window.jQuery('.fr-leaderboard ul.nav a.nav-link').on('click', (event) => {
      const li = window.jQuery(event.currentTarget).parent();
      const nav = window.jQuery('.fr-leaderboard ul.nav');

      if (window.matchMedia('screen and (max-width: 768px)').matches) {
        if (nav.hasClass('mobile-open')) {
          window.jQuery('.fr-leaderboard ul.nav li').removeClass('mobile-li-open');
          li.addClass('mobile-li-open');
        }
        nav.toggleClass('mobile-open');
      }
    });
    return true;
  }

  private padNumber(number: number, padCount = 2) {
    let numStr = '' + number;
    while (numStr.length < padCount) {
      numStr = '0' + numStr;
    }
    return numStr;
  }
}