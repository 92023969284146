import { injectable, inject } from 'inversify';
import { BaseFundraiserComponent } from '../../../base/base-fundraiser-component';
import { FundraiserDonation } from '../../../base/model/fundraiser-donation';
import fundraiserContainer from '../../../../container/fundraiser-container';
import { FundraiserEnvironment } from '../../../sdk/services/fundraiser-environment';
import sdkTypes from '../../../sdk/types/sdk-types';
import { FundraiserPageInput } from '../../../base/model/input/fundraiser-page-input';
import { formatCurrency } from '../../../../utils/utils';
import { FundraiserDisplayOptions } from '../../../base/model/fundraiser-display-options';

@injectable()
export class ThanksToComponent extends BaseFundraiserComponent{

  private fundraiserEnvironment: FundraiserEnvironment;
  private displayOptions: FundraiserDisplayOptions;
  private fundraiserDonations: FundraiserDonation[];
  private max: number;
  private truncate: number;

  constructor() {
    super();
    this.fundraiserEnvironment = fundraiserContainer.get<FundraiserEnvironment>(sdkTypes.fundraiserEnvironment);
    this.componentId = 'fr-my-thanks-to';
    this.title = 'My Thanks To';
  }

  public scanDataAttributes(): void {
    this.max = this.domElement.get(0).dataset.max != null ? parseInt(this.domElement.get(0).dataset.max, 10) : 100;
    this.truncate = this.domElement.get(0).dataset.truncate != null ? parseInt(this.domElement.get(0).dataset.truncate, 10) : 20;
  }

  requestData(pageInput: FundraiserPageInput) {
    pageInput.fetchDonations = true;
  }

  public fillData(data): void {
    this.displayOptions = this.fundraiserEnvironment.options.displayOptions;
    this.fundraiserDonations = this.fundraiserEnvironment.options.donations;
    const withAmounts = [];
    const hiddenAmounts = [];
    //
    if (this.displayOptions.contributorListType === 'TOP_5_CONTRIBUTORS') {
      // Override template with 5 top
      this.max = 5;
    }
    //
    if (this.fundraiserDonations != null) {
      this.fundraiserDonations.forEach((donation: FundraiserDonation) => {
        if (donation.showDonor === 'SHOW_DONOR' || donation.showAmount === 'SHOW_AMOUNT') {
          //
          if (donation.donationAmount != null && donation.showAmount === 'SHOW_AMOUNT') {
            if (withAmounts.length < this.max) {
              withAmounts.push(donation);
            }
          } else {
            hiddenAmounts.push(donation);
          }
        }
      });
      //
      this.fundraiserDonations = withAmounts;
      hiddenAmounts.forEach((donation: FundraiserDonation) => {
        if (this.fundraiserDonations.length < this.max) {
          this.fundraiserDonations.push(donation);
        }
      });
    }
  }

  public getGhostView(): string {
    const view =  this.getTitleView() +
      '<div class="fr-widget__body table-responsive">' +
      '<table class="table table-sm">' +
      '<tr><td class="text-truncate"><h6 class="h90"></h6></td><td class="font-weight-bold"><h6></h6></td></tr>' +
      '<tr><td class="text-truncate"><h6 class="h40"></h6></td><td class="font-weight-bold"><h6></h6></td></tr>' +
      '<tr><td class="text-truncate"><h6 class="h70"></h6></td><td class="font-weight-bold"><h6></h6></td></tr>' +
      '</table>' +
      '</div>';
    return view;
  }

  public getView(): string {
    // Do not show widget if content is empty or if fr tools disabled contributors for this page
    const hasData = this.fundraiserDonations != null && this.fundraiserDonations.length > 0 && this.displayOptions.showContributors;
    if (hasData || this.showIfEmpty) {
      let view = this.getTitleView() +
        '<div class="fr-widget__body table-responsive">' +
        '<table class="table table-sm">';
      //
      if (hasData) {
        this.fundraiserDonations.forEach((donation: FundraiserDonation) => {
          if (donation.showDonor === 'ANONYMOUS') {
            view += '<tr><td class="text-truncate">Anonymous</td>';
          } else if (donation.donorDisplayName == null) {
            view += '<tr><td class="text-truncate"></td>';
          } else if (donation.donorDisplayName.length < this.truncate) {
            view += '<tr><td class="text-truncate">' + donation.donorDisplayName + '</td>';
          } else {
            view += '<tr><td class="text-truncate">' + donation.donorDisplayName.substring(0, this.truncate) + '...</td>';
          }
          if (this.displayOptions.showContributorDonationAmount && donation.donationAmount != null && donation.showAmount === 'SHOW_AMOUNT') {
            view += '<td class="font-weight-bold"> ' + formatCurrency(donation.donationAmount) + '</td>';
          } else {
            view += '<td></td>';
          }
          view += '</tr>';
        });
      }
      //
      view +=  '</table>' + '</div>';
      return view;
    }
    return '';
  }

}