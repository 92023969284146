import { ContainerModule, interfaces } from 'inversify';
import joinTypes from '../types/join-types';
import { JoinOurTeamComponent } from '../team/component/join-our-team-component';
import { RegisterTodayComponent } from '../register/component/register-today-component';
import { JoinOurTeamService } from '../team/services/join-our-team-service';
import { DonateComponent } from '../donate/component/donate-component';
import { DonateMembersComponent } from '../donate/component/donate-members-component';
import { SearchComponent } from '../search/component/search-component';
import { SearchService } from '../search/services/search-service';
import { TellMeMoreComponent } from '../tell-me-more/component/tell-me-more-component';
import { RegisterTodayNationalComponent } from '../register/component/register-today-national-component';
import { CartComponent } from '../items/component/cart-component';
import { ItemsComponent } from '../items/component/items-component';
import { CartService } from '../items/services/cart-service';
import { SearchExternalComponent } from '../search-external/component/search-external-component';
import { DonateService } from '../donate/services/donate-service';

const joinModule = new ContainerModule((bind: interfaces.Bind, unbind: interfaces.Unbind) => {
  bind<CartService>(joinTypes.CartService).to(CartService).inSingletonScope();
  bind<CartComponent>(joinTypes.CartComponent).to(CartComponent).inSingletonScope();
  bind<ItemsComponent>(joinTypes.ItemsComponent).to(ItemsComponent).inSingletonScope();
  //
  bind<JoinOurTeamService>(joinTypes.JoinOurTeamService).to(JoinOurTeamService).inSingletonScope();
  bind<JoinOurTeamComponent>(joinTypes.JoinOurTeamComponent).to(JoinOurTeamComponent).inSingletonScope();
  //
  bind<DonateService>(joinTypes.DonateService).to(DonateService).inSingletonScope();
  bind<DonateComponent>(joinTypes.DonateComponent).to(DonateComponent).inSingletonScope();
  bind<DonateMembersComponent>(joinTypes.DonateMembersComponent).to(DonateMembersComponent).inSingletonScope();
  //
  bind<RegisterTodayComponent>(joinTypes.RegisterTodayComponent).to(RegisterTodayComponent).inSingletonScope();
  bind<RegisterTodayNationalComponent>(joinTypes.RegisterTodayNationalComponent).to(RegisterTodayNationalComponent).inSingletonScope();
  //
  bind<SearchService>(joinTypes.SearchService).to(SearchService).inSingletonScope();
  bind<SearchComponent>(joinTypes.SearchComponent).to(SearchComponent).inSingletonScope();
  bind<SearchExternalComponent>(joinTypes.SearchExternalComponent).to(SearchExternalComponent).inSingletonScope();
  //
  bind<TellMeMoreComponent>(joinTypes.TellMeMoreComponent).to(TellMeMoreComponent).inSingletonScope();
});
export default joinModule;