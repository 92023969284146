import { injectable, inject } from 'inversify';
import { BaseFundraiserComponent } from '../../../base/base-fundraiser-component';
import fundraiserContainer from '../../../../container/fundraiser-container';
import { FundraiserPage } from '../../../base/model/fundraiser-page';
import { FundraiserEnvironment } from '../../../sdk/services/fundraiser-environment';
import sdkTypes from '../../../sdk/types/sdk-types';

@injectable()
export class PageTitleComponent extends BaseFundraiserComponent{

  private fundraiserEnvironment: FundraiserEnvironment;
  private fundraisingPage: FundraiserPage;

  constructor() {
    super();
    this.fundraiserEnvironment = fundraiserContainer.get<FundraiserEnvironment>(sdkTypes.fundraiserEnvironment);
    this.componentId = 'fr-title';
  }

  public fillData(data): void {
    this.fundraisingPage = this.fundraiserEnvironment.options.fundraiserPage;
  }

  public getGhostView(): string {
    return '<div class="fr-widget__body"><h3> {{title}} </h3></div>';
  }

  public getView(): string {
    //
    const array = [{
      key: 'title',
      value: this.fundraisingPage.pageTitle,
    }];
    //
    return this.replaceHtmlParamsInView(this.getGhostView(), array);
  }
}