import { injectable } from 'inversify';
import { request } from '../../../../utils/request';
import fundraiserContainer from '../../../../container/fundraiser-container';
import sdkTypes from '../../../sdk/types/sdk-types';
import { FundraiserEnvironment } from '../../../sdk/services/fundraiser-environment';
import { FundraiserCampaign } from '../../../base/model/fundraiser-campaign';
import { FundraiserPage } from '../../../base/model/fundraiser-page';
import { SearchParticipantCriteria } from '../model/search-participant-criteria';
import { SearchEventCriteria } from '../model/search-event-criteria';

@injectable()
export class SearchService {

  private fundraiserEnvironment: FundraiserEnvironment;
  private fundraiserCampaign: FundraiserCampaign;
  private fundraiserPage: FundraiserPage;

  constructor() {
    this.fundraiserEnvironment = fundraiserContainer.get<FundraiserEnvironment>(sdkTypes.fundraiserEnvironment);
  }

  public async fetchSearchParticipant(firstName, lastName, teamName) {
    this.fundraiserCampaign = this.fundraiserEnvironment.options.fundraiserCampaign;
    this.fundraiserPage = this.fundraiserEnvironment.options.fundraiserPage;
    //
    const criteria = new SearchParticipantCriteria();
    criteria.firstName = firstName;
    criteria.lastName = lastName;
    criteria.teamName = teamName;
    //
    let campaignName = this.fundraiserCampaign.name;
    if(this.fundraiserCampaign.lookupName === 'VOY') {
      campaignName = 'VOY';
    }
    //
    const url = '/api/fundraiser/' + campaignName + '/search/participants/';
    //
    const response = await request('get', url, criteria, {});
    return response.json();
  }

  public async fetchSearchEvent(zipCode) {
    this.fundraiserCampaign = this.fundraiserEnvironment.options.fundraiserCampaign;
    this.fundraiserPage = this.fundraiserEnvironment.options.fundraiserPage;
    //
    const criteria = new SearchEventCriteria();
    criteria.zipCode = zipCode;
    //
    const campaignName = this.fundraiserCampaign.name;
    //
    const url = '/api/fundraiser/' + campaignName + '/search/events/';
    //
    const response = await request('get', url, criteria, {});
    return response.json();
  }

  public async fetchMembersInfo(query: string) {
    const campaignId = this.fundraiserEnvironment.options.fundraiserPage.event.campaignId;
    let oocpeId = this.fundraiserEnvironment.options.fundraiserPage.campaignEventId;
    if (oocpeId == null) {
      oocpeId = this.fundraiserEnvironment.options.fundraiserPage.event.campaignEventId;
    }
    //
    let url = '/api/fundraiser/' + this.fundraiserEnvironment.options.fundraiserPage.fundraiserId + '/event/members/?';
    url += 'oocpeId=' + oocpeId + '&campaignId=' + campaignId + '&term=' + query;
    //
    const response = await request('get', url, {}, {});
    return response.json();
  }

}