import { injectable } from 'inversify';
import { request } from '../../../../utils/request';
import fundraiserContainer from '../../../../container/fundraiser-container';
import sdkTypes from '../../../sdk/types/sdk-types';
import { FundraiserEnvironment } from '../../../sdk/services/fundraiser-environment';
import { FundraiserCampaign } from '../../../base/model/fundraiser-campaign';
import { FundraiserPage } from '../../../base/model/fundraiser-page';

@injectable()
export class LeaderboardService {

  private fundraiserEnvironment: FundraiserEnvironment;
  private fundraiserCampaign: FundraiserCampaign;
  private fundraiserPage: FundraiserPage;

  constructor() {
    this.fundraiserEnvironment = fundraiserContainer.get<FundraiserEnvironment>(sdkTypes.fundraiserEnvironment);
  }

  public async fetchEventLeaderboard() {
    this.fundraiserCampaign = this.fundraiserEnvironment.options.fundraiserCampaign;
    this.fundraiserPage = this.fundraiserEnvironment.options.fundraiserPage;
    //
    const url = '/api/fundraiser/' + this.fundraiserPage.fundraiserId + '/event/' + this.fundraiserPage.event.eventId + '/leaderboard/';
    //
    const response = await request('get', url, {}, {});
    return response.json();
  }

  public async fetchTeamLeaderboard() {
    this.fundraiserCampaign = this.fundraiserEnvironment.options.fundraiserCampaign;
    this.fundraiserPage = this.fundraiserEnvironment.options.fundraiserPage;
    //
    const url = '/api/fundraiser/' + this.fundraiserPage.fundraiserId + '/team/leaderboard/?depth=10';
    //
    const response = await request('get', url, {}, {});
    return response.json();
  }

}