import { injectable, inject } from 'inversify';
import { BaseFundraiserComponent } from '../../../base/base-fundraiser-component';
import fundraiserContainer from '../../../../container/fundraiser-container';
import { FundraiserEnvironment } from '../../../sdk/services/fundraiser-environment';
import sdkTypes from '../../../sdk/types/sdk-types';
import { FundraiserPageInput } from '../../../base/model/input/fundraiser-page-input';
import { FundraiserDisplayOptions } from '../../../base/model/fundraiser-display-options';
import { FundraiserCandidate } from '../../../base/model/fundraiser-candidate';

@injectable()
export class YearCandidatesComponent extends BaseFundraiserComponent{

  private fundraiserEnvironment: FundraiserEnvironment;
  private displayOptions: FundraiserDisplayOptions;
  private fundraiserCandidate: FundraiserCandidate[];
  private max: number;

  constructor() {
    super();
    this.fundraiserEnvironment = fundraiserContainer.get<FundraiserEnvironment>(sdkTypes.fundraiserEnvironment);
    this.componentId = 'fr-year-candidates';
    this.title = 'This Year\'s Candidates';
  }

  public scanDataAttributes(): void {
    this.max = this.domElement.get(0).dataset.max != null ? parseInt(this.domElement.get(0).dataset.max, 10) : 100;
  }

  requestData(pageInput: FundraiserPageInput) {
    pageInput.fetchDonations = true;
  }

  public fillData(data): void {
    this.displayOptions = this.fundraiserEnvironment.options.displayOptions;
    this.fundraiserCandidate = this.fundraiserEnvironment.options.candidates;
  }

  public getGhostView(): string {
    return '';
  }

  public getView(): string {
    // Do not show widget if content is empty or if fr tools disabled contributors for this page
    const hasData = this.fundraiserCandidate != null && this.fundraiserCandidate.length > 0;
    if (hasData || this.showIfEmpty) {
      let view = this.getTitleView() +
        '<div class="fr-widget__body table-responsive">';
      //
      if (hasData) {
        this.fundraiserCandidate.sort((a, b) => {
          if (a.roleName === 'MWOY_ALL_STAR') {
            return -1;
          }
          if (b.roleName === 'MWOY_ALL_STAR') {
            return 1;
          }
          return a.firstName.localeCompare(b.firstName);
        });
        this.fundraiserCandidate.forEach((candidate: FundraiserCandidate, index: number) => {
          const even = index % 2 === 0 ? '' : 'even';
          view += '<div class="candidate ' + even + ' ">';
          const displayName = candidate.firstName + ' ' + candidate.lastName;
          if (displayName.length < 50) {
            view += '<a href="' + candidate.candidateUrl + '" class="candidate-name">' + displayName + '</a>';
          } else {
            view += '<a href="' + candidate.candidateUrl + '" class="candidate-name">' + displayName.substring(0, 50) + '...</a>';
          }
          if (candidate.roleName === 'MWOY_ALL_STAR') {
            view += '<img src="/content360/mwoy/images/global/allstarred2.png">';
          }
          view += '</div>';
        });
      }
      //
      view += '</div>';
      return view;
    }
    return '';
  }

}