import { injectable, inject } from 'inversify';
import moment from 'moment';
import { BaseFundraiserComponent } from '../../../base/base-fundraiser-component';
import fundraiserContainer from '../../../../container/fundraiser-container';
import { FundraiserEnvironment } from '../../../sdk/services/fundraiser-environment';
import sdkTypes from '../../../sdk/types/sdk-types';
import { FundraiserCampaign } from '../../../base/model/fundraiser-campaign';
import { FundraiserPage } from '../../../base/model/fundraiser-page';
import { SearchService } from '../services/search-service';
import joinTypes from '../../types/join-types';
import { FundraiserSearchParticipant } from '../../../base/model/fundraiser-search-participant';
import { FundraiserSearchEvent } from '../../../base/model/fundraiser-search-event';

@injectable()
export class SearchComponent extends BaseFundraiserComponent{

  private fundraiserEnvironment: FundraiserEnvironment;
  private fundraiserPage: FundraiserPage;
  private fundraiserCampaign: FundraiserCampaign;
  private searchService: SearchService;
  private subtitle: string;
  private subtitleLinkHref: string;
  private subtitleLinkText: string;
  private participantInstructionalText: string;
  private firstNameLookupEnabled: boolean;
  private lastNameLookupEnabled: boolean;
  private teamNameLookupEnabled: boolean;
  private resetButtonEnabled: boolean;
  private showColumnParticipantName: boolean;
  private showColumnParticipantTeam: boolean;
  private showColumnParticipantEvent: boolean;
  private showColumnParticipantDonate: boolean;
  private showColumnParticipantOffice: boolean;
  //
  private eventInstructionalText: string;
  private zipCodeLookupEnabled: boolean;
  //
  private showColumnEventName: boolean;
  private showColumnEventLocation: boolean;
  private showColumnEventDate: boolean;
  private showColumnEventRegistration: boolean;
  private eventClosedOnlineRegistrationText: string;

  constructor() {
    super();
    this.fundraiserEnvironment = fundraiserContainer.get<FundraiserEnvironment>(sdkTypes.fundraiserEnvironment);
    this.searchService = fundraiserContainer.get<SearchService>(joinTypes.SearchService);
    this.componentId = 'fr-search';
    this.title = 'Participant Search';
  }

  public scanDataAttributes(): void {
    this.subtitle = this.domElement.get(0).dataset.subtitle != null ? this.domElement.get(0).dataset.subtitle : '';
    this.subtitleLinkHref = this.domElement.get(0).dataset.subtitleLinkHref != null ? this.domElement.get(0).dataset.subtitleLinkHref : '';
    this.subtitleLinkText = this.domElement.get(0).dataset.subtitleLinkText != null ? this.domElement.get(0).dataset.subtitleLinkText : '';
    //
    // Participant
    this.participantInstructionalText = this.domElement.get(0).dataset.participantInstructionalText != null ? //
      this.domElement.get(0).dataset.participantInstructionalText : '';
    this.firstNameLookupEnabled = this.domElement.get(0).dataset.firstNameLookupEnabled === 'true' ? true : false;
    this.lastNameLookupEnabled = this.domElement.get(0).dataset.lastNameLookupEnabled === 'true' ? true : false;
    this.teamNameLookupEnabled = this.domElement.get(0).dataset.teamNameLookupEnabled === 'true' ? true : false;
    this.resetButtonEnabled = this.domElement.get(0).dataset.resetButtonEnabled === 'true' ? true : false;
    this.showColumnParticipantName = this.domElement.get(0).dataset.showColumnParticipantName === 'true' ? true : false;
    this.showColumnParticipantTeam = this.domElement.get(0).dataset.showColumnParticipantTeam === 'true' ? true : false;
    this.showColumnParticipantEvent = this.domElement.get(0).dataset.showColumnParticipantEvent === 'true' ? true : false;
    this.showColumnParticipantDonate = this.domElement.get(0).dataset.showColumnParticipantDonate === 'true' ? true : false;
    this.showColumnParticipantOffice = this.domElement.get(0).dataset.showColumnParticipantOffice === 'true' ? true : false;
    //
    // Event
    this.eventInstructionalText = this.domElement.get(0).dataset.eventInstructionalText != null ? //
      this.domElement.get(0).dataset.eventInstructionalText : '';
    this.zipCodeLookupEnabled = this.domElement.get(0).dataset.zipCodeLookupEnabled === 'true' ? true : false;
    //
    this.showColumnEventName = this.domElement.get(0).dataset.showColumnEventName === 'true' ? true : false;
    this.showColumnEventLocation = this.domElement.get(0).dataset.showColumnEventLocation === 'true' ? true : false;
    this.showColumnEventDate = this.domElement.get(0).dataset.showColumnEventDate === 'true' ? true : false;
    this.showColumnEventRegistration = this.domElement.get(0).dataset.showColumnEventRegistration === 'true' ? true : false;
    //
    this.eventClosedOnlineRegistrationText = this.domElement.get(0).dataset.eventClosedOnlineRegistrationText != null ? //
      this.domElement.get(0).dataset.eventClosedOnlineRegistrationText : 'Thanks for Walking';
  }

  public fillData(data): void {
    this.data = data;
    this.fundraiserCampaign = this.fundraiserEnvironment.options.fundraiserCampaign;
    this.fundraiserPage = this.fundraiserEnvironment.options.fundraiserPage;
  }

  private getParticipantFormView(): string {
    let view = '';
    if (this.participantInstructionalText != null) {
      view += '<div class="instructional-text">' + this.participantInstructionalText + '</div>';
    }
    //
    view += '<div class="search-fields">';
    if (this.firstNameLookupEnabled) {
      view += '<div class="search-input-field">' +
        '  <label>First Name</label>' +
        '  <input class="search-first-name" type="text" value="" placeholder="" required="false" autocomplete="false"/>' +
        '</div>';
    }
    if (this.lastNameLookupEnabled) {
      view += '<div class="search-input-field">' +
        '  <label>Last Name</label>' +
        '  <input class="search-last-name" type="text" value="" placeholder="" required="false" autocomplete="false"/>' +
        '</div>';
    }
    if (this.teamNameLookupEnabled) {
      view += '<div class="search-input-field">' +
        '  <label>Team Name</label>' +
        '  <input class="search-team-name" type="text" value="" placeholder="" required="false" autocomplete="false"/>' +
        '</div>';
    }
    view += '</div>';
    //
    view += '<div class="search-errors search-errors-participant">' +
      '<span class="missing-fields">Enter first name and/or last name.</span>' +
      '<span class="no-results">No information was found that meets your criteria.</span>' +
      '<span class="search-failed">Search failed.</span>' +
      '</div>';
    return view;
  }

  private getEventFormView(): string {
    let view = '';
    if (this.eventInstructionalText != null) {
      view += '<div class="instructional-text">' + this.eventInstructionalText + '</div>';
    }
    //
    view += '<div class="search-fields">';
    if (this.zipCodeLookupEnabled) {
      view += '<div class="search-input-field">' +
        '  <label>Zip Code</label>' +
        '  <input class="search-zip-code" type="text" value="" placeholder="" required="false" autocomplete="false"/>' +
        '</div>';
    }
    view += '</div>';
    //
    view += '<div class="search-errors search-errors-event">' +
      '<span class="missing-fields">Enter zip code.</span>' +
      '<span class="no-results">No information was found that meets your criteria.</span>' +
      '<span class="search-failed">Search failed.</span>' +
      '</div>';
    return view;
  }

  public getGhostView(): string {
    // Do not show widget if content is empty
    let view =  this.getTitleView() +
      '<div class="fr-widget__body">';
    //
    if (this.fundraiserCampaign != null) {
      if (this.fundraiserCampaign.name.toLowerCase() === 'tnt' || this.fundraiserCampaign.name.toLowerCase() === 'ltn') {
        view += '<ul class="search-tabs">';
        view += '<li class="search-tab search-tab-participant search-tab-selected">' +
          '<a href="#individualSearch" data-search="participant">Participant/Team Search</a>' +
          '</li>';
        view += '<li class="search-tab search-tab-event"><a href="#eventSearch" data-search="event">Event Search</a></li>';
        view += '</ul>';
      }
    }
    //
    view += '<div class="search-tab-content search-tab-content-participant" data-search="participant">';
    view += '<div class="search-tab-content-panel">';
    view += this.getParticipantFormView();
    view += '  <div class="search-buttons">';
    if (this.resetButtonEnabled) {
      view += '  <input type="button" value="Reset" class="reset-button"/>';
    }
    view += '  <input type="button" value="Search" class="search-button"/>';
    view += '  </div>';
    view += '</div>';
    view += '<div class="search-results"></div>';
    view += '<div class="search-loading"></div>';
    view += '</div>';
    //
    view += '<div class="search-tab-content search-tab-content-event" data-search="event" style="display: none">';
    view += '<div class="search-tab-content-panel">';
    view += this.getEventFormView();
    view += '<div class="search-buttons">';
    if (this.resetButtonEnabled) {
      view += '<input type="button" value="Reset" class="reset-button"/>';
    }
    view += '<input type="button" value="Search" class="search-button"/>';
    view += '  </div>';
    view += '</div>';
    view += '<div class="search-results"></div>';
    view += '<div class="search-loading"></div>';
    view += '</div>';
    //
    view += '</div>';
    //
    return view;
  }

  public getTitleView(): string {
    let view = '';
    if (!this.hideTitle) {
      view = '<div class="fr-widget__header"> ' +
        '<h1>' + this.title + '</h1>';
      if (this.subtitle) {
        view += '<p>' + this.subtitle + '';
        if (this.subtitleLinkHref != null) {
          view += '<a href="' + this.subtitleLinkHref + '">' + this.subtitleLinkText + '</a>' ;
        }
        view += '</p>';
      }
      view += '</div>';
    }
    return view;
  }

  public getView(): string {
    return this.getGhostView();
  }

  public afterView(): boolean {
    this.domElement.find('.search-tab a').on('click', (e) => {
      const clazz = window.jQuery(e.target).data('search');
      this.switchTab(clazz);
    });
    this.domElement.find('.search-tab-content .reset-button').on('click', (e) => {
      // Reset all
      const clazz = window.jQuery(e.target).parent().parent().parent().data('search');
      this.cleanErrors(clazz);
      if (clazz === 'participant') {
        this.domElement.find('.search-tab-content-participant .search-first-name').val('');
        this.domElement.find('.search-tab-content-participant .search-last-name').val('');
        this.domElement.find('.search-tab-content-participant .search-team-name').val('');
        this.domElement.find('.search-tab-content-participant .search-results').html('');
      }else if (clazz === 'event') {
        this.domElement.find('.search-tab-content-event .search-zip-code').val('');
      }
    });
    this.domElement.find('.search-tab-content .search-button').on('click', (e) => {
      //
      const clazz = window.jQuery(e.target).parent().parent().parent().data('search');
      this.cleanErrors(clazz);
      if (clazz === 'participant') {
        // search participants
        this.searchParticipants();
      }else if (clazz === 'event') {
        // search events
        this.searchEvents();
      }
    });
    return true;
  }

  private searchParticipants(): void {
    let firstName = '';
    let empty = true;
    let allDisabled = true;
    if (this.firstNameLookupEnabled) {
      allDisabled = false;
      firstName = this.domElement.find('.search-first-name').val();
      empty = empty && (firstName == null || firstName === '');
    }
    let lastName = '';
    if (this.lastNameLookupEnabled) {
      allDisabled = false;
      lastName = this.domElement.find('.search-last-name').val();
      empty = empty && (lastName == null || lastName === '');
    }
    let teamName = '';
    if (this.teamNameLookupEnabled) {
      allDisabled = false;
      teamName = this.domElement.find('.search-team-name').val();
      empty = empty && (teamName == null || teamName === '');
    }
    if (!empty || allDisabled) {
      //
      this.domElement.find('.search-tab-content-participant .search-loading').attr({ style: 'display: block' });
      //
      this.searchService.fetchSearchParticipant(firstName, lastName, teamName).then((res) => {
        const searchResults = <FundraiserSearchParticipant[]>res;
        if (searchResults.length > 0) {
          this.domElement.find('.search-tab-content-participant .search-results').html(this.getParticipantsResultsView(searchResults));
        } else {
          this.domElement.find('.search-tab-content-participant .search-results').html('');
          this.showError('participant', 'no-results');
        }
        this.domElement.find('.search-tab-content-participant .search-loading').attr({ style: 'display: none' });
      }).catch(() => {
        this.showError('participant', 'search-failed');
      });
    }else {
      this.showError('participant', 'missing-fields');
    }
  }

  private searchEvents(): void {
    let zipCode = '';
    let empty = true;
    let allDisabled = true;
    if (this.zipCodeLookupEnabled) {
      allDisabled = false;
      zipCode = this.domElement.find('.search-zip-code').val();
      empty = empty && (zipCode == null || zipCode === '');
    }
    if (!empty || allDisabled) {
      //
      this.domElement.find('.search-tab-content-event .search-loading').attr({ style: 'display: block' });
      //
      this.searchService.fetchSearchEvent(zipCode).then((res) => {
        const searchResults = <FundraiserSearchEvent[]>res;
        if (searchResults.length > 0) {
          //
          this.domElement.find('.search-tab-content-event .search-results').html(this.getEventsResultsView(searchResults));
          this.domElement.find('.search-results__filters li a').on('click', (e) => {
            const clazz = window.jQuery(e.target).data('tnt-filter');
            this.switchTabFilter(clazz);
          });
          //
        } else {
          this.domElement.find('.search-tab-content-event .search-results').html('');
          this.showError('event', 'no-results');
        }
        this.domElement.find('.search-tab-content-event .search-loading').attr({ style: 'display: none' });
      }).catch(() => {
        this.showError('event', 'search-failed');
      });
    }else {
      this.showError('event', 'missing-fields');
    }
  }

  public getParticipantsResultsView(results: FundraiserSearchParticipant[]): string {
    let view = '';
    //
    view += '<div class="search-results__counter"><p>Participants found: ' + results.length + '</p></div>';
    //
    view += '<div class="search-results__table"><table><thead>' +
      '<tr>';
    if (this.showColumnParticipantName) {
      view += '<th>Name</th>';
    }
    if (this.showColumnParticipantTeam) {
      view += '<th>Team</th>';
    }
    if (this.showColumnParticipantEvent && this.fundraiserCampaign.name === 'LTN') {
      view += '<th>Walksite</th>';
    }else if (this.showColumnParticipantEvent && this.fundraiserCampaign.name !== 'LTN') {
      view += '<th>Event</th>';
    }

    if (this.showColumnParticipantOffice) {
      view += '<th>Chapter</th>';
    }
    if (this.showColumnParticipantDonate) {
      view += '<th></th>';
    }
    view += '</tr>' +
      '</thead><tbody>';
    //
    let i = 0;
    results.forEach((participant) => {
      const clazz = i % 2 === 1 ? '' : 'odd';
      view += '<tr class="' + clazz + '">';
      if (this.showColumnParticipantName) {
        const nameLink = '<a href="' + participant.pageUrl + '">' + participant.displayName + '</a>';
        view += '<td>' + nameLink + '</td>';
      }
      if (this.showColumnParticipantTeam) {
        let teamLink = '';
        if (participant.teamUrl != null) {
          teamLink += '<a href="' + participant.teamUrl + '">' + participant.teamName + '</a>';
        }
        view += '<td>' + teamLink + '</td>';
      }
      if (this.showColumnParticipantEvent) {
        view += '<td>' + participant.eventName + '</td>';
      }
      if (this.showColumnParticipantOffice) {
        view += '<td>' + participant.officeName + '</td>';
      }
      if (this.showColumnParticipantDonate) {
        const donateLink = '<a href="' + participant.pageUrl + '">Donate</a>';
        view += '<td>' + donateLink + '</td>';
      }
      view += '</tr>';
      i += 1;
    });
    //
    view += '</tbody>';
    view += '</table></div>';
    //
    view += '';
    //
    return view;
  }

  public getEventsResultsView(results: FundraiserSearchEvent[]): string {
    let view = '';
    //
    view += '<div class="search-results__counter"><p>Events found: ' + results.length + '</p></div>';
    //
    if (this.fundraiserCampaign.name === 'TNT') {
      view += '<div class="search-results__filters">' +
        '<ul class="search-results__filters-container">' +
        '<li class="search-results-filter search-results-filter-all selected">' +
        '<a href="#all" data-tnt-filter="all">Show all</a> ' +
        '</li>' +
        '<li class="search-results-filter search-results-filter-marathon">' +
        '<a href="#Marathons" data-tnt-filter="marathon">Marathons</a> ' +
        '</li>' +
        '<li class="search-results-filter search-results-filter-triathlon">' +
        '<a href="#Triathlons" data-tnt-filter="triathlon">Triathlons</a> ' +
        '</li>' +
        '<li class="search-results-filter search-results-filter-cycle">' +
        '<a href="#Cycling" data-tnt-filter="cycle">Cycling</a> ' +
        '</li>' +
        '<li class="search-results-filter search-results-filter-hike">' +
        '<a href="#Hikes" data-tnt-filter="hike">Hikes</a> ' +
        '</li>' +
        '<li class="search-results-filter search-results-filter-others">' +
        '<a href="#Others" data-tnt-filter="others">Others</a> ' +
        '</li>' +
        '</ul>' +
        '</div>';
    }
    //
    view += '<div class="search-results__table"><table><thead>' +
      '<tr>';
    if (this.showColumnEventName) {
      view += '<th>Name</th>';
    }
    if (this.showColumnEventLocation) {
      view += '<th>Location</th>';
    }
    if (this.showColumnEventDate) {
      view += '<th>Date</th>';
    }
    if (this.showColumnEventRegistration) {
      view += '<th></th>';
    }
    view += '</tr>' +
      '</thead><tbody>';
    //
    let i = 0;
    results.forEach((event) => {
      const clazz = i % 2 === 1 ? '' : 'odd';
      let programName = event.programName != null ? event.programName.toLowerCase() : '';
      if (programName !== 'marathon' && programName !== 'triathlon' && programName !== 'cycle' && programName !== 'hike') {
        programName = 'others';
      }
      //
      view += '<tr class="' + clazz + '  type-' + programName + '">';
      if (this.showColumnEventName) {
        const nameLink = '<a href="' + event.pageUrl + '">' + event.displayName + '</a>';
        view += '<td>' + nameLink + '</td>';
      }
      if (this.showColumnEventLocation) {
        view += '<td>' + event.location + '</td>';
      }
      if (this.showColumnEventDate) {
        view += '<td>' + moment(event.date).format('DD/MM/YYYY') + '</td>';
      }
      if (this.showColumnEventRegistration) {
        let donateLink = '';
        if (event.showForOnlineRegistration) {
          donateLink = '<a href="' + event.registrationLink + '">Register</a>';
        }else {
          donateLink = this.eventClosedOnlineRegistrationText;
        }
        view += '<td>' + donateLink + '</td>';
      }
      view += '</tr>';
      i += 1;
    });
    //
    view += '</tbody>';
    view += '</table></div>';
    //
    view += '';
    //
    return view;
  }

  public cleanErrors(type: string): void {
    this.domElement.find('.search-errors-' + type).attr({ style: 'display: none' });
    this.domElement.find('.search-errors-' + type + ' > span').attr({ style: 'display: none' });
  }
  public showError(type: string, clazz: string): void {
    this.domElement.find('.search-errors-' + type).attr({ style: 'display: block' });
    this.domElement.find('.search-errors-' + type + ' > span').attr({ style: 'display: none' });
    this.domElement.find('.search-errors-' + type + ' .' + clazz).attr({ style: 'display: block' });
  }
  public switchTab(type): void {
    this.domElement.find('.search-tab-content').attr({ style: 'display: none' });
    this.domElement.find('.search-tab-content-' + type).attr({ style: 'display: block' });
    this.domElement.find('.search-tab').removeClass('search-tab-selected');
    this.domElement.find('.search-tab-' + type).addClass('search-tab-selected');
  }
  public switchTabFilter(filterType): void {
    // Hide all
    this.domElement.find('.search-tab-content-event .search-results table tbody tr').attr({ style: 'display: none' });
    //
    const filterElement = this.domElement.find('.search-results-filter-' + filterType);
    let isAll = false;
    if (filterType === 'all') {
      // clear all filters
      this.domElement.find('.search-results-filter').removeClass('selected');
      this.domElement.find('.search-results-filter-' + filterType).addClass('selected');
      this.domElement.find('.search-tab-content-event .search-results table tbody tr').attr({ style: 'display: tablerow' });
      isAll = true;
    }else {
      // Just do toggle
      if (filterElement.hasClass('selected')) {
        this.domElement.find('.search-results-filter-' + filterType).removeClass('selected');
        if (this.domElement.find('.search-results-filter.selected').length === 0) {
          // enable all because there are no filters
          this.domElement.find('.search-results-filter-all').addClass('selected');
          this.domElement.find('.search-tab-content-event .search-results table tbody tr').attr({ style: 'display: tablerow' });
          isAll = true;
        }
      }else {
        this.domElement.find('.search-results-filter-all').removeClass('selected');
        this.domElement.find('.search-results-filter-' + filterType).addClass('selected');
      }
    }
    //
    // Refresh table
    if (!isAll) {
      const enabledElements = this.domElement.find('.search-results-filter.selected');
      enabledElements.each((e: HTMLElement) => {
        const filter = window.jQuery(e).find('a').data('tnt-filter');
        this.domElement.find('.search-tab-content-event .search-results table tbody tr.type-' + filter).attr({ style: 'display: tablerow' });
      });
    }
    //
    const displayedElements = this.domElement.find('.search-tab-content-event .search-results table tbody tr[style*="display: tablerow"]');
    this.domElement.find('.search-results__counter p').text('Events found: ' + displayedElements.length);
  }
}