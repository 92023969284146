import { injectable, inject } from 'inversify';
import moment from 'moment';
import { BaseFundraiserComponent } from '../../../base/base-fundraiser-component';
import fundraiserContainer from '../../../../container/fundraiser-container';
import { FundraiserEnvironment } from '../../../sdk/services/fundraiser-environment';
import sdkTypes from '../../../sdk/types/sdk-types';
import { FundraiserCampaign } from '../../../base/model/fundraiser-campaign';
import { FundraiserPage } from '../../../base/model/fundraiser-page';
import { FundraiserRegistrationProduct } from '../../../base/model/fundraiser-registration-product';

@injectable()
export class RegisterTodayComponent extends BaseFundraiserComponent{

  private fundraiserEnvironment: FundraiserEnvironment;
  private fundraiserPage: FundraiserPage;
  private fundraiserCampaign: FundraiserCampaign;
  private registrationProduct: FundraiserRegistrationProduct;
  private description: string;
  private registrationUrl: string;
  private joinIndividualUrl: string;
  private joinTeamUrl: string;
  private createTeamUrl: string;
  private campaignEventId: number;
  private showDonation: boolean;
  private hideIfClosedDonation: boolean;
  private individualLabel: string;
  private joinTeamLabel: string;
  private createTeamLabel: string;
  private allowIndividual: boolean;
  private allowJoinTeam: boolean;
  private allowCreateTeam: boolean;

  constructor() {
    super();
    this.fundraiserEnvironment = fundraiserContainer.get<FundraiserEnvironment>(sdkTypes.fundraiserEnvironment);
    this.componentId = 'fr-register-today';
    this.title = 'Register Today';
  }

  public scanDataAttributes(): void {
    this.hideIfClosedDonation =
    this.domElement.get(0).dataset.hideIfClosedDonation != null ? this.domElement.get(0).dataset.hideIfClosedDonation === 'true' : false;
    this.description = this.domElement.get(0).dataset.description ? this.domElement.get(0).dataset.description : '';
    //
    this.allowIndividual = this.domElement.get(0).dataset.allowIndividual != null ? this.domElement.get(0).dataset.allowIndividual === 'true' : true;
    this.allowJoinTeam = this.domElement.get(0).dataset.allowJoinTeam != null ? this.domElement.get(0).dataset.allowJoinTeam === 'true' : true;
    this.allowCreateTeam = this.domElement.get(0).dataset.allowCreateTeam != null ? this.domElement.get(0).dataset.allowCreateTeam === 'true' : true;
    //
    this.individualLabel = this.domElement.get(0).dataset.individualLabel ? this.domElement.get(0).dataset.individualLabel : 'As an individual';
    this.joinTeamLabel = this.domElement.get(0).dataset.joinTeamLabel ? this.domElement.get(0).dataset.joinTeamLabel : 'Join a Team';
    this.createTeamLabel = this.domElement.get(0).dataset.createTeamLabel ? this.domElement.get(0).dataset.createTeamLabel : 'Create a Team';
  }

  public fillData(data): void {
    this.data = data;
    this.fundraiserCampaign = this.fundraiserEnvironment.options.fundraiserCampaign;
    this.fundraiserPage = this.fundraiserEnvironment.options.fundraiserPage;
    this.registrationProduct = this.fundraiserEnvironment.options.registrationProduct;
    this.campaignEventId = this.fundraiserPage.campaignEventId;
    if (this.campaignEventId == null && this.fundraiserPage.event != null) {
      this.campaignEventId = this.fundraiserPage.event.campaignEventId;
    }
    this.showDonation = this.campaignEventId != null && (this.allowJoinTeam || this.allowIndividual || this.allowCreateTeam);
    if (!this.fundraiserPage.event.acceptsRegistrations) {
      this.showDonation = false;
    }
    ///////////////
    //TNT && EVENT
    ///////////////
    if (this.fundraiserCampaign.name === 'TNT' && this.fundraiserPage.fundraiserType === 'EVENT') {
      this.registrationUrl = this.fundraiserCampaign.registrationUrl + '/event-search/';
      this.joinIndividualUrl = this.registrationUrl;
      this.joinTeamUrl = this.registrationUrl;
      this.createTeamUrl = this.registrationUrl;
    ///////////////
    //TNT || LTN
    ///////////////
    } else if (this.fundraiserCampaign.name === 'LTN' || this.fundraiserCampaign.name === 'TNT') {
      this.registrationUrl = this.fundraiserCampaign.registrationUrl + '/event-selected/' //
        + this.fundraiserPage.campaignEventId;
      this.joinIndividualUrl = this.registrationUrl + '/individual';
      this.joinTeamUrl = this.registrationUrl + '/join-team';
      this.createTeamUrl = this.registrationUrl + '/create-team';
    ///////////////
    //MWOY
    ///////////////
    } else if (this.fundraiserCampaign.name === 'MWOY') {
      this.registrationUrl = this.fundraiserCampaign.registrationUrl;
      if (this.fundraiserPage.team != null) {
        this.registrationUrl += '/team-selected/' + this.fundraiserPage.team.teamId;
      }
      if (this.hideIfClosedDonation && moment(new Date()).isAfter(this.fundraiserPage.event.date)) {
        this.showDonation = false;
      }

      if (this.fundraiserPage.event != null && this.fundraiserPage.event.allowOnlineRegistration) {
        this.joinTeamUrl = this.registrationUrl;
      }
    ///////////////
    //REGATTA
    ///////////////
    } else if (this.fundraiserCampaign.name === 'REGATTA') {
      this.registrationUrl = this.fundraiserCampaign.registrationUrl + //
        '?programGroupName=REGATTA&eventID=' + this.campaignEventId + //
        '&fundraiserID=' + this.fundraiserPage.fundraiserId + '&waysToParticipate=';
      this.joinIndividualUrl = this.registrationUrl + 'JOIN_INDIVIDUAL';
      this.joinTeamUrl = this.registrationUrl + 'JOIN_TEAM';
      this.createTeamUrl = this.registrationUrl + 'CREATE_TEAM';
    ///////////////
    //SE (Big Climb)
    ///////////////
    } else if (this.fundraiserCampaign.name === 'SE') {
      this.registrationUrl = this.fundraiserCampaign.registrationUrl + //
        '/frRegistration.action?programGroupName=SE&eventID=' + this.campaignEventId + //
        '&fundraiserID=' + this.fundraiserPage.fundraiserId + '&waysToParticipate=';
      this.joinIndividualUrl = this.registrationUrl + 'JOIN_INDIVIDUAL';
      this.joinTeamUrl = this.registrationUrl + 'JOIN_TEAM';
      this.createTeamUrl = this.registrationUrl + 'CREATE_TEAM';
    }
    // DO THE ETOOLS URL FOR OTHER CAMPAIGNS
  }

  public getView(): string {   
    // Do not show widget if content is empty
    if (this.showDonation && this.hasUrls()) {
      let view =  this.getTitleView() +
        '<div class="fr-widget__body">';
      //
      if (this.description != null && this.description !== '') {
        view += '<div class="fr-widget__body-register-today__text">' +
          '<p>' + this.description + '</p>' +
          '</div>';
      }
      //
      if (this.fundraiserCampaign.name !== 'MWOY' && this.allowIndividual) {
        view += '<div class="fr-widget__body-register-today__link-container">' +
          '<a class="btn button individual" href="' + this.joinIndividualUrl + '">' + this.individualLabel + '</a>' +
          '</div>';
      }
      //
      if (this.allowJoinTeam) {
        view += '<div class="fr-widget__body-register-today__link-container">' +
          '<a class="btn button join-team" href="' + this.joinTeamUrl + '">' + this.joinTeamLabel + '</a>' +
          '</div>';
      }
      //
      if (this.fundraiserCampaign.name !== 'MWOY' && this.fundraiserCampaign.name !== 'TNT' && this.allowCreateTeam) {
        view += '<div class="fr-widget__body-register-today__link-container">' +
          '<a class="btn button create-team" href="' + this.createTeamUrl + '">' + this.createTeamLabel + '</a>' +
          '</div>';
      }
      //
      view += '</div>';

      return view;
    }
  }

  protected hasUrls(): boolean {
    return this.joinIndividualUrl != null || this.joinTeamUrl != null || this.createTeamUrl != null;
  }

}