const contentTypes = {
  // Posts
  PostsService: Symbol('PostsService'),
  PostsPager: Symbol('PostsPager'),
  PostsComponent: Symbol('PostsComponent'),
  CommentComponent: Symbol('CommentComponent'),
  //
  ConnectComponent: Symbol('ConnectComponent'),
  ContactComponent: Symbol('ContactComponent'),
  // Page
  PageHeaderComponent: Symbol('PageHeaderComponent'),
  // Event
  EventVideoComponent: Symbol('EventVideoComponent'),
  EventInfoComponent: Symbol('EventInfoComponent'),
  EventTitleComponent: Symbol('EventTitleComponent'),
  EventParticipantsComponent: Symbol('EventParticipantsComponent'),
  EventContentComponent: Symbol('EventContentComponent'),
  // Countdown Clock
  CountdownClockComponent: Symbol('CountdownClockComponent'),
  // Find our event
  FindOurEventComponent: Symbol('FindOurEventComponent'),
  // Leaderboard
  LeaderboardService: Symbol('LeaderboardService'),
  LeaderboardComponent: Symbol('LeaderboardComponent'),
  // News Feed
  NewsFeedComponent: Symbol('NewsFeedComponent'),
  //
  ParticipateForComponent: Symbol('ParticipateForComponent'),
  //
  ProgressGoalComponent: Symbol('ProgressGoalComponent'),
  ProgressGoalChartComponent: Symbol('ProgressGoalChartComponent'),
  TeardropGoalComponent: Symbol('TeardropGoalComponent'),
  //
  ShareComponent: Symbol('ShareComponent'),
  // Thanks To
  ThanksToComponent: Symbol('ThanksToComponent'),
  //
  YearCandidatesComponent: Symbol('YearCandidatesComponent'),
};
export default contentTypes;
