import { injectable, inject } from 'inversify';
import { BaseFundraiserComponent } from '../../../base/base-fundraiser-component';
import fundraiserContainer from '../../../../container/fundraiser-container';
import { FundraiserEnvironment } from '../../../sdk/services/fundraiser-environment';
import sdkTypes from '../../../sdk/types/sdk-types';

@injectable()
export class ConnectComponent extends BaseFundraiserComponent{

  private fundraiserEnvironment: FundraiserEnvironment;
  // options
  private linkFacebook: string;
  private linkTwitter: string;
  private linkYoutube: string;
  private linkLLS: string;
  private imageFacebook = '/content360/lcr/images/global/fbChicklet.png';
  private imageTwitter = '/content360/lcr/images/global/twitterChicklet.png';
  private imageYoutube = '/content360/lcr/images/global/youTubeChicklet.png';
  private imageLLS = '/content360/lcr/images/global/dropChicklet.png';

  constructor() {
    super();
    this.fundraiserEnvironment = fundraiserContainer.get<FundraiserEnvironment>(sdkTypes.fundraiserEnvironment);
    this.componentId = 'fr-connect';
    this.hideTitle = true;
    this.title = 'Connect with us';
  }

  public fillData(data): void {
    //
    const dataset = this.domElement.get(0).dataset;
    //
    this.linkFacebook = dataset.linkFacebook != null ? dataset.linkFacebook : null;
    this.linkTwitter = dataset.linkTwitter != null ? dataset.linkTwitter : null;
    this.linkYoutube = dataset.linkYoutube != null ? dataset.linkYoutube : null;
    this.linkLLS = dataset.linkLls != null ? dataset.linkLls : null;
    //
    this.imageFacebook = dataset.imageFacebook != null ? dataset.imageFacebook : this.imageFacebook;
    this.imageTwitter = dataset.imageTwitter != null ? dataset.imageTwitter : this.imageTwitter;
    this.imageYoutube = dataset.imageYoutube != null ? dataset.imageYoutube : this.imageYoutube;
    this.imageLLS = dataset.imageLls != null ? dataset.imageLls : this.imageLLS;
  }

  public getView(): string {
    let view = '<div class="fr-widget__body">';
    //
    if (!this.hideTitle) {
      view += ' <div class="d-flex flex-row text-right">' +
        '   <span> ' + this.title + ' </span>' +
        ' </div>';
    }
    view += '<ul>';
    view += this.addIconLink(this.linkFacebook, this.imageFacebook);
    view += this.addIconLink(this.linkTwitter, this.imageTwitter);
    view += this.addIconLink(this.linkYoutube, this.imageYoutube);
    view += this.addIconLink(this.linkLLS, this.imageLLS);
    //
    view += '</ul>';
    return view;
  }
  //
  public addIconLink(link: string, image: string): string {
    let view = '';
    if (link != null) {
      view =  '  <li>' +
      '    <a href="' + link + '" target="_blank"><img src="' + image + '"></a>' +
      '  </li>';
    }
    return view;
  }
}