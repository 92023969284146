import { ContainerModule, interfaces } from 'inversify';
import { ComponentBuilder } from '../services/component-builder';
import sdkTypes from '../types/sdk-types';
import { ComponentScanner } from '../services/component-scanner';
import { FundraiserEnvironment } from '../services/fundraiser-environment';

const sdkModule = new ContainerModule((bind: interfaces.Bind, unbind: interfaces.Unbind) => {
  bind<FundraiserEnvironment>(sdkTypes.fundraiserEnvironment).to(FundraiserEnvironment).inSingletonScope();
  bind<ComponentBuilder>(sdkTypes.componentBuilder).to(ComponentBuilder).inSingletonScope();
  bind<ComponentScanner>(sdkTypes.componentScanner).to(ComponentScanner).inSingletonScope();
});
export default sdkModule;