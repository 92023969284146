import { injectable, inject } from 'inversify';
import { BaseFundraiserComponent } from '../../../base/base-fundraiser-component';
import fundraiserContainer from '../../../../container/fundraiser-container';
import { FundraiserEnvironment } from '../../../sdk/services/fundraiser-environment';
import sdkTypes from '../../../sdk/types/sdk-types';
import { FundraiserTeam } from '../../../base/model/fundraiser-team';
import { FundraiserPage } from '../../../base/model/fundraiser-page';
import { Thermometer } from '../model/thermometer';
import { FundraiserCampaign } from '../../../base/model/fundraiser-campaign';
import { FundraiserPageInput } from '../../../base/model/input/fundraiser-page-input';
import { FundraiserDisplayOptions } from '../../../base/model/fundraiser-display-options';
import { FundraiserItem } from '../../../base/model/fundraiser-item';
import { DonateService } from '../../../join/donate/services/donate-service';
import joinTypes from '../../../join/types/join-types';

@injectable()
export class BaseProgressGoalComponent extends BaseFundraiserComponent{

  protected fundraiserEnvironment: FundraiserEnvironment;
  protected fundraiserCampaign: FundraiserCampaign;
  protected donateService: DonateService;
  protected donateUrl: string;
  protected displayOptions: FundraiserDisplayOptions;
  protected fundraisingItems: FundraiserItem[];
  protected donationsAmount = 0;
  protected sponsorshipsAmount = 0;
  protected retailSalesAmount = 0;
  protected remaining: number;
  protected page: FundraiserPage;
  protected team: FundraiserTeam;
  protected type: string;
  protected teamThermometer: Thermometer;
  protected individualThermometer: Thermometer;
  protected frGoal: number;
  protected teamGoal: number;
  protected hideMainBar: any;
  protected hideMainPercentage: any;
  protected hideTeam: any;
  protected goalLabel: string;
  //
  constructor() {
    super();
    this.fundraiserEnvironment = fundraiserContainer.get<FundraiserEnvironment>(sdkTypes.fundraiserEnvironment);
    this.donateService = fundraiserContainer.get<DonateService>(joinTypes.DonateService);
    //
  }

  requestData(pageInput: FundraiserPageInput) {
    pageInput.fetchParentTeam = true;
  }

  public fillData(data): void {
    this.donateUrl = this.donateService.buildDonationUrl(null, null);
    this.page = this.fundraiserEnvironment.options.fundraiserPage;
    this.fundraiserCampaign = this.fundraiserEnvironment.options.fundraiserCampaign;
    this.displayOptions = this.fundraiserEnvironment.options.displayOptions;
    this.type = this.page.fundraiserType;
    this.team = this.page.team;
    //
    if (this.type === 'TEAM_MEMBER') {
      this.type = 'INDIVIDUAL';
    } else if (this.type === 'NATIONAL_TEAM') {
      this.type = 'TEAM';
    }
    //
    if (this.title === 'Progress Goal') {
      if (this.type === 'INDIVIDUAL') {
        this.title = 'My Fundraising Total';
      } else if (this.type === 'TEAM') {
        this.title = 'Team Fundraising Total';
      }
    }
    //
    const dataset = this.domElement.get(0).dataset;
    //
    this.hideMainBar = dataset.hideMainBar != null ? (dataset.hideMainBar === 'true') : false;
    this.hideMainPercentage = dataset.hideMainPercentage != null ? (dataset.hideMainPercentage === 'true') : false;
    this.hideTeam = dataset.hideTeam != null ? (dataset.hideTeam === 'true') : false;
    this.goalLabel = dataset.goalLabel != null ? dataset.goalLabel : 'Goal';
    //
    this.fundraisingItems = this.fundraiserEnvironment.options.fundraisingItems;
    //
    if (this.displayOptions.goalType === 'PERSONAL_GOAL') {
      this.frGoal = this.page.fundraiserGoal;
    } else if (this.displayOptions.goalType === 'FUNDRAISING_MINIMUM') {
      this.frGoal = this.page.fundraiserGoalMinimum;
    } else if (this.displayOptions.thermometerType === 'CHECKED') {
      this.frGoal = this.page.fundraiserGoal;
    }
    //
    if (this.fundraisingItems != null) {
      this.fundraisingItems.forEach((i) => {
        let name = i.name;
        if (i.name != null) {
          name = i.name.toUpperCase();
        }
        if (name === 'DONOR') {
          this.donationsAmount = i.totalFundsRaised;
        } else if (name === 'SPONSORSHIP') {
          this.sponsorshipsAmount = i.totalFundsRaised;
        } else if (name === 'BALLOON_SALES' || name === 'RETAIL') {
          this.retailSalesAmount = i.totalFundsRaised;
        }
      });
      this.remaining = this.frGoal - (this.donationsAmount + this.sponsorshipsAmount + this.retailSalesAmount);
    }
    // while the team total above is needed for team member pages, it should not be included in the goal chart
    if (this.page.fundraiserType === 'INDIVIDUAL' || this.page.fundraiserType === 'TEAM_MEMBER') {
      this.remaining = (this.frGoal - this.page.fundraiserTotalDonationAmount);
    }
    if (this.remaining < 0) {
      this.remaining = 0;
    }
    //
    if (this.frGoal != null && this.displayOptions.goalType !== 'NO_THERMOMETER') {
      this.individualThermometer = new Thermometer();
      //
      this.individualThermometer.type = 'INDIVIDUAL';
      this.individualThermometer.raised = this.page.fundraiserTotalDonationAmount ? this.page.fundraiserTotalDonationAmount : 0;
      this.individualThermometer.goal = this.frGoal;
      if (this.frGoal === 0) {
        this.individualThermometer.percentage = 0;
      } else {
        this.individualThermometer.percentage = Math.round((this.page.fundraiserTotalDonationAmount / this.frGoal) * 100);
      }

      this.individualThermometer.percentageWidth = this.individualThermometer.percentage > 100 ? 100 : this.individualThermometer.percentage;
    }
    //
    if (this.team != null && this.displayOptions.goalType !== 'NO_THERMOMETER') {
      //
      if (this.displayOptions.goalType === 'PERSONAL_GOAL') {
        this.teamGoal = this.team.teamFrGoal;
      } else if (this.displayOptions.goalType === 'FUNDRAISING_MINIMUM') {
        this.teamGoal = this.team.teamFrMinimum;
      } else if (this.displayOptions.thermometerType === 'CHECKED') {
        this.teamGoal = this.team.teamFrGoal;
      }
      this.teamThermometer = new Thermometer();
      //
      this.teamThermometer.type = 'TEAM';
      this.teamThermometer.raised = this.donationsAmount + this.retailSalesAmount + this.sponsorshipsAmount;
      this.teamThermometer.goal = this.teamGoal;
      if (this.frGoal === 0) {
        this.teamThermometer.percentage = 0;
      } else {
        // exclude sponsorships from the percentage only
        this.teamThermometer.percentage = Math.round(((this.donationsAmount + this.retailSalesAmount) / this.teamGoal) * 100);
      }
      this.teamThermometer.percentageWidth = this.teamThermometer.percentage > 100 ? 100 : this.teamThermometer.percentage;
    }
    //remove after done testing
    /*
    var debugData = {
      remaining : this.remaining,
      frGoal : this.frGoal,
      donationAmount: this.donationsAmount,
      sponsorshipsAmount: this.sponsorshipsAmount,
      retailSalesAmount: this.retailSalesAmount,
      teamThermometer: this.teamThermometer,
      individualThermometer: this.individualThermometer
    }
    console.log("Donut Chart Debug Data", debugData);
    */
  }
}