import { injectable, inject } from 'inversify';
import moment from 'moment';
import { BaseFundraiserComponent } from '../../../base/base-fundraiser-component';
import fundraiserContainer from '../../../../container/fundraiser-container';
import { FundraiserEnvironment } from '../../../sdk/services/fundraiser-environment';
import sdkTypes from '../../../sdk/types/sdk-types';
import { FundraiserEvent } from '../../../base/model/fundraiser-event';

@injectable()
export class EventTitleComponent extends BaseFundraiserComponent{

  private fundraiserEnvironment: FundraiserEnvironment;
  private fundraiserEvent: FundraiserEvent;
  private eventTitle: string;

  constructor() {
    super();
    this.fundraiserEnvironment = fundraiserContainer.get<FundraiserEnvironment>(sdkTypes.fundraiserEnvironment);
    this.componentId = 'fr-event-title';
    this.title = 'Event Title';
  }

  public fillData(data): void {
    this.fundraiserEvent = this.fundraiserEnvironment.options.fundraiserPage.event;
    this.eventTitle = this.fundraiserEnvironment.options.fundraiserCampaign.eventDefaultSubject;
    //
    if (this.fundraiserEvent != null) {
      const date = moment(this.fundraiserEvent.date).tz('America/New_York');
      this.eventTitle = this.eventTitle.replace('{eventDate}', date.format('dddd MMMM DD, YYYY hh:mm A ')
        + this.fundraiserEvent.timezone);
      this.eventTitle = this.eventTitle.replace('{eventLocation.location}', this.fundraiserEvent.location);
      this.eventTitle = this.eventTitle.replace('{eventLocation.name}', this.fundraiserEvent.name);
      this.eventTitle = this.eventTitle.replace('{eventLocation.city}', this.fundraiserEvent.city);
      this.eventTitle = this.eventTitle.replace('{eventLocation.state}', this.fundraiserEvent.state);
      this.eventTitle = this.eventTitle.replace('{br}', '<br />');
    }
  }

  public getGhostView(): string {
    return '<div><h1 class="h70">{{eventTitle}}</h1></div>';
  }

  public getView(): string {
    //
    const array = [{
      key: 'eventTitle',
      value: this.eventTitle,
    }];
    //
    return this.replaceHtmlParamsInView(this.getGhostView(), array);
  }

  public getTitleView(): string {
    return '';
  }

}