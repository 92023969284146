import { injectable, inject } from 'inversify';
import he from 'he';
import { BaseFundraiserComponent } from '../../../base/base-fundraiser-component';
import fundraiserContainer from '../../../../container/fundraiser-container';
import { FundraiserEnvironment } from '../../../sdk/services/fundraiser-environment';
import sdkTypes from '../../../sdk/types/sdk-types';
import { FundraiserEvent } from '../../../base/model/fundraiser-event';

@injectable()
export class EventContentComponent extends BaseFundraiserComponent{

  private fundraiserEnvironment: FundraiserEnvironment;
  private fundraiserEvent: FundraiserEvent;
  private eventContent: string;

  constructor() {
    super();
    this.fundraiserEnvironment = fundraiserContainer.get<FundraiserEnvironment>(sdkTypes.fundraiserEnvironment);
    this.componentId = 'fr-event-content';
    this.title = 'Event Content';
  }

  public fillData(data): void {
    this.fundraiserEvent = this.fundraiserEnvironment.options.fundraiserPage.event;
    this.eventContent = this.fundraiserEnvironment.options.fundraiserCampaign.eventDefaultContent;
  }

  public getGhostView(): string {
    const content = '' +
      '<h1 class="h50 h-center"></h1>' +
      '<br />' +
      '<h5></h5>' +
      '<h5 class="h75"></h5>' +
      '<h5 class="h50"></h5>';
    return this.getTemplateView(content);
  }

  private getTemplateView(content: string): string {
    const view = '<div class="fr-widget__body fr-widget__body-event-content">'
      + he.decode(content) +
      '     </div>';
    return view;
  }

  public getView(): string {
    return this.getTemplateView(this.eventContent);
  }

  public getTitleView(): string {
    return '';
  }

}