import { injectable, inject } from 'inversify';
import { BaseFundraiserComponent } from '../../../base/base-fundraiser-component';
import fundraiserContainer from '../../../../container/fundraiser-container';
import { FundraiserEnvironment } from '../../../sdk/services/fundraiser-environment';
import sdkTypes from '../../../sdk/types/sdk-types';
import { FundraiserEvent } from '../../../base/model/fundraiser-event';
import { FundraiserCampaign } from '../../../base/model/fundraiser-campaign';

declare let google; // TODO it may have to be restored to var?

@injectable()
export class FindOurEventComponent extends BaseFundraiserComponent{

  private fundraiserCampaign: FundraiserCampaign;
  private fundraiserEnvironment: FundraiserEnvironment;
  private fundraiserEvent: FundraiserEvent;
  private url: string;
  private viewOnMap: boolean;

  constructor() {
    super();
    this.fundraiserEnvironment = fundraiserContainer.get<FundraiserEnvironment>(sdkTypes.fundraiserEnvironment);
    this.componentId = 'fr-find-our-event';
    this.title = 'Find our event';
  }

  public scanDataAttributes(): void {
    this.viewOnMap = this.domElement.get(0).dataset.viewOnMap ? true : false;
  }

  public fillData(data): void {
    this.fundraiserEvent = this.fundraiserEnvironment.options.fundraiserPage.event;
    this.fundraiserCampaign = this.fundraiserEnvironment.options.fundraiserCampaign;
    //
    this.url = '';
    if (this.viewOnMap && this.fundraiserEvent != null) {
      if (this.fundraiserEvent.latitude != null && this.fundraiserEvent.longitude != null) {
        this.url = 'https://maps.google.com/maps?&q=loc:' + this.fundraiserEvent.latitude + ',' + this.fundraiserEvent.longitude;
      } else {
        this.url = 'https://maps.google.com/maps?&q=loc:' + this.fundraiserEvent.city + ',+' + this.fundraiserEvent.city +
          ',+' + this.fundraiserEvent.state + ',+' + this.fundraiserEvent.postalCode;
      }
    }
  }

  public getGhostView(): string {
    return this.getTemplateView('<h4 class="h80 h-center"></h4>');
  }

  public getTemplateView(viewOnMap: string): string {
    const height = this.fundraiserCampaign.name === 'LTN'
      || this.fundraiserCampaign.name === 'TNT'
      || this.fundraiserCampaign.name === 'REGATTA' ? 120 : 300;

    let view =  this.getTitleView() +
      '<div class="fr-widget__body">' +
      ` <div id="map-event" style="height: ${height}px"></div>`;
    if (this.viewOnMap) {
      view += ' <div class="d-flex justify-content-center buttons">' +
        '' + viewOnMap +
        ' </div>';
    }
    view += '</div>';
    return view;
  }

  public getView(): string {
    let view = '';
    if (this.viewOnMap && this.fundraiserEvent != null) {
      view += '<a class="btn button btn-view-on-map" href="' + this.url + '" target="_blank">VIEW ON MAP</a>';
    }
    return this.getTemplateView(view);
  }

  public afterView(): boolean {
    this.loadMaps();
    return true;
  }

  public loadMaps() {
    if (this.fundraiserEvent != null && this.fundraiserEvent.latitude != null && this.fundraiserEvent.longitude != null) {
      if (typeof(google) !== 'undefined' && google != null) {
        const gposition = { lat: this.fundraiserEvent.latitude, lng: this.fundraiserEvent.longitude };
        const gmap = new google.maps.Map(document.getElementById('map-event'), {
          center: gposition,
          zoom: 16,
          scrollwheel: false,
          draggable: false,
          disableDefaultUI: true,
          mapTypeId: google.maps.MapTypeId.ROADMAP,
        });
        if (!window.preview) {
          const marker = new google.maps.Marker({ position: gposition, map: gmap, url: this.url });
        }
      }
    }
  }
}