import { injectable, inject } from 'inversify';
import he from 'he';
import moment from 'moment';
import { BaseFundraiserComponent } from '../../../base/base-fundraiser-component';
import fundraiserContainer from '../../../../container/fundraiser-container';
import contentTypes from '../../types/content-types';
import { FundraiserPage } from '../../../base/model/fundraiser-page';
import { FundraiserPost } from '../../../base/model/fundraiser-post';
import { FundraiserPostComment } from '../../../base/model/fundraiser-post-comment';
import { FundraiserPageInput } from '../../../base/model/input/fundraiser-page-input';
import { FundraiserPagination } from '../../../base/model/input/fundraiser-pagination';
import { FundraiserEnvironment } from '../../../sdk/services/fundraiser-environment';
import sdkTypes from '../../../sdk/types/sdk-types';
import { PostsPager } from '../services/posts-pager';
import { PostNavigation } from '../model/post-navigation';

declare const FB: any;
declare const twttr: any;
declare const grecaptcha: any;

@injectable()
export class PostsComponent extends BaseFundraiserComponent{

  private fundraiserEnvironment: FundraiserEnvironment;
  private postsPager: PostsPager;
  private fundraiserPage: FundraiserPage;
  private navigation: any; // Header navigation
  private pagination: any; // Header navigation
  private fbRecommend: any;
  private comment: any;
  private shareTw: any;
  private hideWebsite: any;
  private usePlaceholders: any;
  private commentLinkText: string;
  private submitCommentButtonText: string;
  //
  private nameLabel = 'Name';
  private namePlaceholder = '';
  private emailLabel = 'Email';
  private emailPlaceholder = '';
  private websiteLabel = 'Website';
  private websitePlaceholder = '';
  private commentLabel = '';
  private commentPlaceholder = '';
  //
  private errorLabelRequiredName: string;
  private errorLabelRequiredEmail: string;
  private errorLabelInvalidEmail: string;
  private errorLabelRequiredComment: string;
  private errorLabelRequiredCaptcha: string;

  constructor() {
    super();
    this.fundraiserEnvironment = fundraiserContainer.get<FundraiserEnvironment>(sdkTypes.fundraiserEnvironment);
    this.postsPager = fundraiserContainer.get<PostsPager>(contentTypes.PostsPager);
    this.componentId = 'fr-posts';
  }

  public scanDataAttributes(): void {
    this.navigation = this.domElement.get(0).dataset.navigation != null ? this.domElement.get(0).dataset.navigation === 'true' : true;
    this.pagination = this.domElement.get(0).dataset.pagination != null ? this.domElement.get(0).dataset.pagination === 'true' : true;
    this.fbRecommend = this.domElement.get(0).dataset.recommend != null ? this.domElement.get(0).dataset.recommend === 'true' : true;
    this.comment = this.domElement.get(0).dataset.comment != null ? this.domElement.get(0).dataset.comment === 'true' : true;
    this.shareTw = this.domElement.get(0).dataset.shareTw != null ? this.domElement.get(0).dataset.shareTw === 'true' : false;
    this.commentLinkText = this.domElement.get(0).dataset.commentLinkText != null ? this.domElement.get(0).dataset.commentLinkText
      : 'Comment';
    this.hideWebsite = this.domElement.get(0).dataset.hideWebsite != null ? this.domElement.get(0).dataset.hideWebsite === 'true' : false;
    this.usePlaceholders = this.domElement.get(0).dataset.usePlaceholders != null ? this.domElement.get(0).dataset.usePlaceholders === 'true' : false;
    this.submitCommentButtonText = this.domElement.get(0).dataset.submitCommentButtonText != null ? this.domElement.get(0).dataset.submitCommentButtonText
      : 'Submit Comment';
    this.errorLabelRequiredName = this.domElement.get(0).dataset.errorLabelRequiredName != null ? this.domElement.get(0).dataset.errorLabelRequiredName
      : 'this field is required.';
    this.errorLabelRequiredEmail = this.domElement.get(0).dataset.errorLabelRequiredEmail != null ? this.domElement.get(0).dataset.errorLabelRequiredEmail
      : 'this field is required.';
    this.errorLabelInvalidEmail = this.domElement.get(0).dataset.errorLabelInvalidEmail != null ? this.domElement.get(0).dataset.errorLabelInvalidEmail
      : 'invalid email.';
    this.errorLabelRequiredComment = this.domElement.get(0).dataset.errorLabelRequiredComment != null ? this.domElement.get(0).dataset.errorLabelRequiredComment
      : 'this field is required.';
    this.errorLabelRequiredCaptcha = this.domElement.get(0).dataset.errorLabelRequiredCaptcha != null ? this.domElement.get(0).dataset.errorLabelRequiredCaptcha
      : 'this field is required.';
  }

  public fillData(data): void {
    this.data = data;
    this.fundraiserPage = this.fundraiserEnvironment.options.fundraiserPage;
    this.postsPager.init(this.fundraiserEnvironment.options.posts);
    //
    if (this.usePlaceholders) {
      this.nameLabel = '';
      this.namePlaceholder = 'Your name';
      this.emailLabel = '';
      this.emailPlaceholder = 'Email address';
      this.websiteLabel = '';
      this.websitePlaceholder = 'Your website';
      this.commentLabel = '';
      this.commentPlaceholder = 'Comment';
    }
  }

  requestData(pageInput: FundraiserPageInput) {
    pageInput.fetchPostsCount = true;
    pageInput.fetchPosts = true;
    pageInput.postPage = new FundraiserPagination();
    pageInput.postPage.offset = 0;
    // Size will be fetched from database
  }

  public getGhostView(): string {
    const view = `
      <div class="fr-widget__body">
        <h3 class="h75"></h3>
        <h6 class="h25"></h6>
        <br />
        <h4 class="h90"></h4>
        <h4 class="h80"></h4>
        <h4 class="h50"></h4>
      </div>
    `;

    return view;
  }

  public getView(): string {
    const posts = this.postsPager.getPosts();
    //
    let items = '';
    posts.forEach((post, iteratorIndex) => {
      items += this.buildItem(post, iteratorIndex);
    });
    //
    let nextIndex = 0;
    let nextStyle = 'display:none;';
    let prevIndex = 0;
    let prevStyle = 'display:none;';
    if (this.postsPager.existsOlderPage()) {
      prevIndex = this.postsPager.getNewerPageIndex();
      prevStyle = '';
    }
    if (this.postsPager.existsNewerPage()) {
      nextIndex = this.postsPager.getOlderPageIndex();
      nextStyle = '';
    }
    //
    const view = `
      <div class="fr-posts__body">
       <div class="fr-posts__items"> {{items}}</div>
       <div class="fr-posts__pagination">
         <a href="#p={{nextIndex}}" class="fr-posts-next" style="{{nextStyle}}">Newer posts</a>
         <a href="#p={{prevIndex}}" class="fr-posts-prev" style="{{prevStyle}}">Older posts</a>
       </div>
       <div class="posts-loading"></div>
      </div>
      `;
    //
    return this.replaceHtmlParamsInView(view, [{
      key: 'items',
      value: items,
    }, {
      key: 'prevIndex',
      value: prevIndex,
    }, {
      key: 'prevStyle',
      value: prevStyle,
    }, {
      key: 'nextIndex',
      value: nextIndex,
    }, {
      key: 'nextStyle',
      value: nextStyle,
    }]);
  }

  private create_footer_comment_partial(post: FundraiserPost): string{
    let footer_comment_output = ``;

    if (post.comments.length > 1 && this.fundraiserEnvironment.options.fundraiserCampaign.name === 'LTN') {
      footer_comment_output = `<a href="#${post.urlKey}">${this.commentLinkText}s</a>`;
    }else {
      footer_comment_output = `<a href="#${post.urlKey}">${this.commentLinkText}</a>`;
    }

    const view_output = `
      <div class="fr-posts__item-footer-balloon-container">
        <div class="fr-posts__item-footer-balloon">${post.comments.length}</div>
        <div class="fr-posts__item-footer-tail"></div>
      </div>

      <div class="fr-posts__item-footer-comment">

        ${footer_comment_output}

      </div>
    `;

    return view_output;
  }

  private create_comment_editor_partial(post: FundraiserPost): string{
    let post_title_partial = ``;
    let comment_item_partial = ``;
    let visitor_website_partial = ``;


      //Partial 4-1
      post_title_partial = this.create_post_title_partial(post);

      //Partial 4-2
      post.comments.forEach((comment) => {
        comment_item_partial += this.buildCommentItem(comment);
      });

      //Partial 4-3
      if (!this.hideWebsite) {
        visitor_website_partial = this.create_visitor_website_partial();
      }

    const view_output = `
      <div class="fr-posts__item-comment-editor" style="display: none">
        <div class="fr-posts__item-separator fr-posts__item-comment-editor-separator"></div>
        <div class="fr-posts__item-comment-editor-title d-flex flex-row">
          <div class="fr-posts__item-footer-balloon">${post.comments.length}</div>

          ${post_title_partial}

        </div>
        <div class="fr-posts__item-comments">

          ${comment_item_partial}

        </div>
        <h3>Leave a Reply</h3>
        <div class="comment-input-group">
           <input class="formItem requiredField" type="text" name="visitorName" placeholder="${this.namePlaceholder}"/>
           <label>${this.nameLabel}</label>
        </div>
        <div class="input-error input-error-name"></div>
        <div class="comment-input-group">
           <input class="formItem requiredField" type="text" name="visitorEmail" placeholder="${this.emailPlaceholder}"/>
           <label>${this.emailLabel}</label>
        </div>
        <div class="input-error input-error-email"></div>
    
        ${visitor_website_partial}

        <div class="comment-input-group">
           <textarea class="formItem requiredField" type="text" name="comment" placeholder="${this.commentPlaceholder}"></textarea>
        </div>
        <div class="input-error input-error-comment"></div>
        <div class="captcha-image">
           <div id="g-recaptcha-${post.fundraiserPostId}"></div>
           <div class="input-error input-error-captcha"></div>
        </div>
        <div class="d-flex justify-content-center"><button class="btn button">${this.submitCommentButtonText}</button></div>
      </div>
    `;

    return view_output;
  }

  private create_post_title_partial(post: FundraiserPost): string{
    let view_output = ``;

    if (post.comments.length > 1) {
      view_output = `<h4>Responses for "${post.title}"</h4>`;
    }else {
      view_output = `<h4>Response for "${post.title}"</h4>`;
    }

    return view_output;
  }

  private create_visitor_website_partial(): string{
    const view_output = `
      <div class="comment-input-group">
        <input class="formItem requiredField" type="text" name="visitorWebsite" value="https://" placeholder="${this.websitePlaceholder}"/> 
        <label>${this.websiteLabel}</label>
      </div>
      <div class="input-error input-error-website"></div>
    `; 

    return view_output;
  }

  private buildItem(post: FundraiserPost, iteratorIndex: number): string {
    let anchorNavigation = '<a href="#' + post.urlKey + '">' + post.title + '</a>';
    if (!this.navigation) {
      anchorNavigation = post.title;
    }

    let footer_comment_partial = ``;
    let fb_like_partial = ``;
    let tw_share_partial = ``;
    let comment_editor_partial = ``;

    //Partial 1
    if (this.comment && this.fundraiserPage.allowComments) {
      footer_comment_partial = this.create_footer_comment_partial(post);
    }

    //Partial 2
    if (this.fbRecommend) {
      fb_like_partial = `
        <div class="fb-like" data-href="${window.location.href}" data-layout="button_count" data-action="recommend" 
          data-size="small" data-show-faces="false" data-share="false"></div>
      `;
    }

    //Partial 3
    if (this.shareTw) {
      tw_share_partial = `
        <a href="https://twitter.com/share" class="twitter-share-button" data-url="${window.location.href}"
          data-text="I just donated to The Leukemia & Lymphoma Society. Help patients with blood cancer live better, longer lives. Visit " 
          data-counturl="${window.location.href}" data-count="horizontal">Tweet</a>
      `;
    }

    //Partial 4
    if (this.comment && this.fundraiserPage.allowComments) {
      comment_editor_partial = this.create_comment_editor_partial(post);
    }

    const view = `
      <div class="fr-posts__item" id="${post.fundraiserPostId}">
        <div class="fr-posts__item-navigation" style="display: none" data-index="${iteratorIndex}"></div>
        <div class="fr-posts__item-header"> 
          <h4>${anchorNavigation}</h4>
        </div>
        <div class="fr-posts__item-separator"></div>
        <div class="fr-posts__item-date"> ${moment(post.date).tz('America/New_York').format('MMM DD, YYYY')}</div> 
        <div class="fr-posts__item-body">${he.decode(post.htmlContent)}</div>
        <div style="clear: both"></div>
        <div class="fr-posts__item-footer">
          <div class="fr-posts__item-show-comments">

            ${footer_comment_partial}

          </div>
          <div class="fr-posts__item-social">

            ${fb_like_partial}
            ${tw_share_partial}

          </div>
          
        </div>

        ${comment_editor_partial}

      </div>
    `;

    return view;
  }

  private buildCommentItem(comment: FundraiserPostComment): string {
    let visitorName = comment.visitorName;
    if (this.fundraiserEnvironment.options.fundraiserCampaign.name === 'REGATTA') {
      visitorName = comment.visitorName + ', ';
    }
    //
    const view = `
      <div class="fr-widget__body-comment">
        <div class="fr-widget__body-comment__message"><span>" ${comment.comment} "</span>
      </div>
      <div class="fr-widget__body-comment__author"><h6> ${visitorName} </h6></div>
      <div class="fr-widget__body-comment__date">
        <span class="text-muted"> ${moment(comment.date).tz('America/New_York').format('ddd MMM D hh:mm:ss zz YYYY')} </span>
      </div>
      </div>
    `;
    
    //
    return view;
  }

  private showLoading(): void {
    this.domElement.find('.posts-loading').attr({ style:'display: block' });
  }

  private hideLoading(): void {
    this.domElement.find('.posts-loading').attr({ style:'display: none' });
  }

  private reloadView(posts: FundraiserPost[]) {
    this.domElement.find('.fr-posts__body').html('');
    this.domElement.find('.fr-posts__body').html(this.getView());
    this.afterView();
  }

  private handlePostPagination() {
    this.domElement.find('.fr-posts-next').on('click', (e) => {
      e.stopPropagation();
      this.reloadView(this.postsPager.fetchNewPage());
      return false;
    });
    this.domElement.find('.fr-posts-prev').on('click', (e) => {
      e.stopPropagation();
      this.showLoading();
      this.postsPager.fetchOlderPage().then((posts) => {
        this.reloadView(posts);
        this.hideLoading();
      }).catch((e) => {
        this.hideLoading();
      });
      return false;
    });
  }

  public exitPost() {
    window.jQuery(this.domElement.find('.fr-posts__item')).show();
    // Hide comments
    window.jQuery(this.domElement.find('.fr-posts__item .fr-posts__item-comment-editor')).hide();
    // Show comment links again
    window.jQuery(this.domElement.find('.fr-posts__item .fr-posts__item-footer')).show();
    // Hide post navigation
    window.jQuery(this.domElement.find('.fr-posts__item-navigation')).show();
    // Show post pagination
    window.jQuery(this.domElement.find('.fr-posts__pagination')).hide();
  }

  public goHome() {
    this.exitPost();
    // Show all page posts in first page
    this.postsPager.goHome();
    this.reloadView(this.postsPager.getPosts());
  }

  public reloadCaptcha(postId: any) {
    if (typeof(grecaptcha) !== 'undefined' && grecaptcha != null) {
      try {
        grecaptcha.reset();
      } catch (e) {
        console.log(e);
      }
      grecaptcha.render('g-recaptcha-' + postId, {
        sitekey: window.recaptchaKey,
      });
    }
  }

  public enterPost(postId: string) {
    // Hide other posts
    window.jQuery(this.domElement.find('.fr-posts__item')).hide();
    //
    const post = window.jQuery(this.domElement.find('#' + postId));
    post.show();
    //
    const navigation = post.find('.fr-posts__item-navigation');
    //
    const postIndex = navigation.data('index') + (this.postsPager.getCurrentPage() * this.fundraiserPage.postsPerPage);
    this.showLoading();
    this.postsPager.getPostNavigation(postIndex).then((postNavigation) => {
      //
      if (this.navigation) {
        navigation.html(this.buildNavigation(postNavigation));
        navigation.show();
      }
      //
      this.domElement.find('.fr-posts__item-navigation-home a').on('click', (a) => {
        this.goHome();
      });
      //
      // Navigation
      this.domElement.find('.fr-posts__item-navigation a.prev').on('click', (e) => {
        const index = window.jQuery(e.target).data('index');
        const postId = window.jQuery(e.target).data('postId');
        this.reloadView(this.postsPager.getPostsViewForPostIndex(index));
        this.enterPost(postId);
      });
      this.domElement.find('.fr-posts__item-navigation a.next').on('click', (e) => {
        const index = window.jQuery(e.target).data('index');
        const postId = window.jQuery(e.target).data('postId');
        this.reloadView(this.postsPager.getPostsViewForPostIndex(index));
        this.enterPost(postId);
      });
      //
      // Show comment box
      post.find('.fr-posts__item-comment-editor').show();
      this.reloadCaptcha(postNavigation.post.fundraiserPostId);
      // Hide comment link
      post.find('.fr-posts__item-footer').hide();
      // Show post navigation
      post.find('.fr-posts__item-navigation').show();
      // Hide post pagination
      window.jQuery(this.domElement.find('.fr-posts__pagination')).hide();
      //
      this.hideLoading();
      //
    }).catch((e) => {
      this.hideLoading();
    });
  }

  private buildNavigation(postNavigation: PostNavigation): string {
    let nav_previous_partial = ``;
    let nav_seprator_partial = ``;
    let nav_next_partial = ``;
    
    //Nav Partial 1
    if (postNavigation.previous != null) {
      nav_previous_partial = `
        <a href="#" class="prev" data-index="${postNavigation.previousIndex}" 
         data-post-id="${postNavigation.previous.fundraiserPostId}"><< ${postNavigation.previous.title}</a>
      `;
    }

    //Nav Partial 2
    if (postNavigation.previous != null && postNavigation.next != null) {
      nav_seprator_partial = '<span> | </span>';
    }

    //Nav Partial 3
    if (postNavigation.next != null) {
      nav_next_partial = `
        <a href="#" class="prev" data-index="${postNavigation.nextIndex}" 
         data-post-id="${postNavigation.next.fundraiserPostId}">${postNavigation.next.title} >></a>
      `; 
    }

    const navigation = `
      ${nav_previous_partial}
      ${nav_seprator_partial}
      ${nav_next_partial}
    `;

    const view = `
      <div class="fr-posts__item-navigation-home"><a href="#"><< Home</a></div>
      <div class="fr-posts__item-navigation-pager">
        ${navigation}
      </div>
    `;

    return this.replaceHtmlParamsInView(view, [{
      key: 'navigation',
      value: navigation,
    }]);
  }

  public afterView(): boolean {
    this.handlePostPagination();
    //
    if (typeof(FB) !== 'undefined' && FB != null) {
      FB.XFBML.parse();
    }
    if (typeof(twttr) !== 'undefined' && twttr != null && twttr.widgets != null) {
      twttr.widgets.load();
    }
    //
    if (this.navigation) {
      this.domElement.find('.fr-posts__item-header a').on('click', (a) => {
        const postId = jQuery(a.target).parent().parent().parent().attr('id');
        this.enterPost(postId);
      });
    } else {
      this.domElement.find('.fr-posts__item-header a').attr({ style: 'text-decoration:none; cursor: default;' });
    }
    this.domElement.find('.fr-posts__item-footer-comment a').on('click', (a) => {
      // Hide all post except ours
      const post = jQuery(a.target).parent().parent().parent().parent();
      const postId = post.attr('id');
      this.enterPost(postId);
    });
    window.jQuery('.captcha-image input[type=text]').on('keyup', (handler) => {
      const val = window.jQuery(handler.target).val();
      window.jQuery(handler.target).val((val + '').toUpperCase());
    });
    this.domElement.find('.fr-posts__item-comment-editor button').on('click', (button) => {
      const emailRegex = '\\b(^[_A-Za-z0-9-]+(\\.[_A-Za-z0-9-]+)*@([A-Za-z0-9-])+' +
        '((\\.com)|(\\.net)|(\\.org)|(\\.info)|(\\.edu)|(\\.mil)|(\\.gov)|(\\.biz)|(\\.ws)|(\\.us)|(\\.tv)|(\\.cc)' +
        '|(\\.aero)|(\\.arpa)|(\\.coop)|(\\.int)|(\\.jobs)|(\\.museum)|(\\.name)|(\\.pro)|(\\.travel)|(\\.nato)' +
        '|(\\.[a-z]{2,3})|(\\.[a-z]{2,}\\.[a-z]{2,3}))$)\\b';
      const postId = jQuery(button.target).parent().parent().parent().attr('id');
      const editor = jQuery(button.target).parent().parent();
      const visitorName = editor.find('input[name="visitorName"]').val();
      const visitorEmail = editor.find('input[name="visitorEmail"]').val() as string;
      const visitorCaptcha = grecaptcha.getResponse();
      let visitorWebsite = '';
      if (!this.hideWebsite) {
        visitorWebsite = editor.find('input[name="visitorWebsite"]').val() as string;
        if (visitorWebsite === 'http://') {
          visitorWebsite = '';
        }
      }
      const message = editor.find('textarea').val();
      //
      // Clear errors
      editor.find('.input-error').html('');
      let valid = true;
      //
      if (visitorName == null || visitorName === '') {
        valid = false;
        editor.find('.input-error.input-error-name').html('<p>' + this.errorLabelRequiredName + '</p>');
      }
      //
      if (visitorEmail == null || visitorEmail === '') {
        valid = false;
        editor.find('.input-error.input-error-email').html('<p>' + this.errorLabelRequiredEmail + '</p>');
      }else if (!visitorEmail.match(emailRegex)) {
        valid = false;
        editor.find('.input-error.input-error-email').html('<p>' + this.errorLabelInvalidEmail + '</p>');
      }
      //
      if (message == null || message === '') {
        valid = false;
        editor.find('.input-error.input-error-comment').html('<p>' + this.errorLabelRequiredComment + '</p>');
      }
      //
      if (visitorCaptcha == null || visitorCaptcha === '') {
        valid = false;
        editor.find('.input-error.input-error-captcha').html('<p>' + this.errorLabelRequiredCaptcha + '</p>');
      }
      //
      if (valid) {
        //
        this.showLoading();
        window.jQuery.ajax({
          type: 'POST',
          dataType: 'json',
          data: JSON.stringify({
            name: visitorName,
            email: visitorEmail,
            website: visitorWebsite,
            comment: message,
            captcha: visitorCaptcha,
          }),
          contentType: 'application/json; charset=utf-8',
          url: '/api/comment/' + this.fundraiserPage.fundraiserId + '/' + postId + '/',
        }).then((data) => {
          this.hideLoading();
          if (data.success) {
          //
            editor.find('input[name="visitorName"]').val('');
            editor.find('input[name="visitorEmail"]').val('');
            if (!this.hideWebsite) {
              editor.find('input[name="visitorWebsite"]').val('https://');
            }
            editor.find('textarea').val('');
            //
            const newComment = new FundraiserPostComment();
            newComment.date = moment(new Date()).format();
            newComment.visitorName = visitorName + '';
            newComment.comment = message + '';
            //
            editor.find('.fr-posts__item-comments').prepend(this.buildCommentItem(newComment));
            //
            const size = editor.find('.fr-posts__item-comment-editor-title .fr-posts__item-footer-balloon').html();
            editor.find('.fr-posts__item-comment-editor-title .fr-posts__item-footer-balloon').html((parseFloat(size) + 1) + '');
            //
            this.reloadCaptcha(postId);
          }else {
            editor.find('.input-error.input-error-captcha').html('<p>' + data.error + '</p>');
            this.reloadCaptcha(postId);
          }
          //
        });
      }
    });
    return true;
    //
  }
}