import { injectable, inject } from 'inversify';
import moment from 'moment';
import { BaseFundraiserComponent } from '../../../base/base-fundraiser-component';
import fundraiserContainer from '../../../../container/fundraiser-container';
import { FundraiserEnvironment } from '../../../sdk/services/fundraiser-environment';
import sdkTypes from '../../../sdk/types/sdk-types';
import { FundraiserCampaign } from '../../../base/model/fundraiser-campaign';
import { FundraiserTeam } from '../../../base/model/fundraiser-team';
import { FundraiserTeamMember } from '../../../base/model/fundraiser-team-member';
import { FundraiserPage } from '../../../base/model/fundraiser-page';
import { JoinOurTeamService } from '../services/join-our-team-service';
import joinTypes from '../../types/join-types';
import { FundraiserSubteam } from '../../../base/model/fundraiser-subteam';
import { FundraiserPageInput } from '../../../base/model/input/fundraiser-page-input';
import { FundraiserEvent } from '../../../base/model/fundraiser-event';

@injectable()
export class JoinOurTeamComponent extends BaseFundraiserComponent{

  private joinOurTeamService: JoinOurTeamService;
  private serverDate: number;
  private subteams: FundraiserSubteam[];
  private fundraiserEnvironment: FundraiserEnvironment;
  private fundraiserPage: FundraiserPage;
  private team: FundraiserTeam;
  private fundraiserCampaign: FundraiserCampaign;
  private fundraisingClosed: boolean;

  constructor() {
    super();
    this.fundraiserEnvironment = fundraiserContainer.get<FundraiserEnvironment>(sdkTypes.fundraiserEnvironment);
    this.joinOurTeamService = fundraiserContainer.get<JoinOurTeamService>(joinTypes.JoinOurTeamService);
    this.componentId = 'fr-join-our-team';
    this.title = 'Join our team now';
  }

  public fillData(data): void {
    this.data = data;
    this.subteams = data;
    this.fundraiserCampaign = this.fundraiserEnvironment.options.fundraiserCampaign;
    this.fundraiserPage = this.fundraiserEnvironment.options.fundraiserPage;
    this.serverDate = this.fundraiserEnvironment.options.serverDate;
    this.team = this.fundraiserPage.team;
    this.fundraisingClosed = moment(this.fundraiserPage.closeDonationsDate).isBefore(moment(this.serverDate));
    //
    // DO THE ETOOLS URL FOR OTHER CAMPAIGNS
  }

  requestData(pageInput: FundraiserPageInput) {
    pageInput.fetchTeamCaptains = true;
  }

  public prepareData(): Promise<any> {
    return this.joinOurTeamService.fetchSubteamsInfo();
  }

  public getView(): string {   

    // Do not show widget if content is empty
    if (this.team != null && (this.fundraiserPage.fundraiserType === 'TEAM' || this.fundraiserPage.fundraiserType === 'NATIONAL_TEAM')) {
      let view =  this.getTitleView() +
        '<div class="fr-widget__body">' +
        '<table class="table">' +
        ' <thead>' +
        '   <tr>' +
        '     <th>Team Name</th>' +
        '     <th>Subteam</th>';
      if (this.fundraiserCampaign.name === 'LTN') {
        view +=  '     <th>Walk Site</th>';
      }
      view += '     <th>Date</th>' +
        '     <th>Team Captain</th>' +
        '     <th>City/State</th>' +
        '     <th></th>' +
        '   </tr>' +
        '</thead>' +
        '<tbody>';
      //
      if (this.fundraiserPage.event != null) {
        view += this.buildTeamRow(this.team, null, this.team.captains, this.fundraiserPage.event, 
          this.buildRegistrationUrl(this.team.teamId, this.fundraiserPage.event));
      }
      //
      if (this.subteams != null) {
        this.subteams.forEach((subteam) => {
          //
          view += this.buildTeamRow(this.team, subteam, subteam.captainList, subteam.event, this.buildRegistrationUrl(subteam.teamId, subteam.event));
        });
      }
      //
      view +=  '</tbody>' +
        '</table>';
      //
      view += '</div>';

      return view;
    }
  }

  public buildTeamRow(team: FundraiserTeam, subteam: FundraiserSubteam, captains: FundraiserTeamMember[],
                      event: FundraiserEvent, registrationUrl: string): string {

    let captainName = '';
    if (captains != null && captains.length > 0) {
      captainName = this.buildCaptainName(captains[0]);
    }
    //
    const isFirstChild = subteam != null && this.team.teamId === subteam.parentTeamId;
    //
    let view = '';
    view += ' <tr>';
    if (this.fundraiserPage.fundraiserType === 'NATIONAL_TEAM') {
      if (isFirstChild) {
        view += '   <td><a href="' + subteam.fullUrl + '">' + subteam.teamName + '<a/></td>';
      }else {
        // print parent team
        this.subteams.forEach((s) => {
          if (subteam != null && s.teamId === subteam.parentTeamId) {
            view += '   <td>' + s.teamName + '</td>';
          }
        });
      }
    } else {
      // For teams
      view += '   <td>' + team.teamName + '</td>';
    }
    //
    if (subteam != null) {
      if (this.fundraiserPage.fundraiserType === 'NATIONAL_TEAM') {
        if (!isFirstChild) {
          view += '   <td><a href="' + subteam.fullUrl + '">' + subteam.teamName + '<a/></td>';
        }else {
          view += '   <td></td>';
        }
      }else {
        // Teams
        view += '   <td><a href="' + subteam.fullUrl + '">' + subteam.teamName + '<a/></td>';
      }
    }else {
      view += '   <td></td>';
    }
    //
    if (this.fundraiserCampaign.name === 'LTN') {
      view +=  '   <td>' + event.location + '</td>';
    }
    view += '   <td>' + moment(event.date).tz('America/New_York').format('MM/DD/YY') + '</td>' +
      '   <td>' + captainName + '</td>' +
      '   <td>' + event.city + ', ' + event.state + ' </td>' +
      '   <td>' + (!this.fundraisingClosed ? '<a href="' + registrationUrl + '" class="btn button btn-join">JOIN</a>' : '') + '</td>' +
      ' </tr>';
    return view;
  }
  public buildRegistrationUrl(teamId: number, event: FundraiserEvent) {
    // TODO this only works for LTN / TNT / MWOY
    const registrationUrl = this.fundraiserCampaign.registrationUrl + '/event-selected/' //
      + event.campaignEventId + '/team-selected/' + teamId;
    return registrationUrl;
  }

  public buildCaptainName(captain: FundraiserTeamMember):string {
    let captainName = '';
    if (captain != null) {
      if (captain.memberTypeId === 1) {
        captainName = captain.firstName + ' ' + captain.lastName;
      }else {
        captainName = captain.institutionName;
      }
    }
    return captainName;
  }
}