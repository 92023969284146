import { injectable, inject } from 'inversify';
import { BaseFundraiserComponent } from '../../../base/base-fundraiser-component';
import fundraiserContainer from '../../../../container/fundraiser-container';
import { FundraiserEnvironment } from '../../../sdk/services/fundraiser-environment';
import sdkTypes from '../../../sdk/types/sdk-types';
import { FundraiserCampaign } from '../../../base/model/fundraiser-campaign';
import { FundraiserPage } from '../../../base/model/fundraiser-page';
import { FundraiserTeamMember } from '../../../base/model/fundraiser-team-member';
import { FundraiserPageInput } from '../../../base/model/input/fundraiser-page-input';
import { FundraiserDisplayOptions } from '../../../base/model/fundraiser-display-options';

@injectable()
export class DonateMembersComponent extends BaseFundraiserComponent{

  private fundraiserEnvironment: FundraiserEnvironment;
  private fundraiserPage: FundraiserPage;
  private fundraiserCampaign: FundraiserCampaign;
  private members: FundraiserTeamMember[];
  private displayOptions: FundraiserDisplayOptions;

  constructor() {
    super();
    this.fundraiserEnvironment = fundraiserContainer.get<FundraiserEnvironment>(sdkTypes.fundraiserEnvironment);
    this.componentId = 'fr-donate-members';
    this.title = 'Donate to a Team Member';
  }

  public fillData(data): void {
    this.data = data;
    this.displayOptions = this.fundraiserEnvironment.options.displayOptions;
    this.fundraiserCampaign = this.fundraiserEnvironment.options.fundraiserCampaign;
    this.fundraiserPage = this.fundraiserEnvironment.options.fundraiserPage;
    this.members = this.fundraiserPage.team.members;
  }

  public requestData(pageInput: FundraiserPageInput) {
    pageInput.fetchTeamMembers = true;
  }

  public getView(): string {

    let teamType = '';
    if (this.fundraiserEnvironment.options.fundraiserPage.team) {
      teamType = this.fundraiserEnvironment.options.fundraiserPage.team.teamType
    }

    let view = ``;

    console.log("======================");
    console.log("Fundraiser Team Type: "+ teamType);
    console.log("Fundraiser Campaign Type: "+ this.fundraiserCampaign.name);
    console.log("======================");
    
    if( teamType != 'SCHOOL' || this.fundraiserCampaign.name != 'LTN' ){
      // Do not show widget if content is empty
      view =  this.getTitleView() +
        '<div class="fr-widget__body">';
      if (this.displayOptions.showRosterLinks) {
        view += '  <div class="d-flex fr-widget__body-donate-text">' +
          '    <span>Click to donate</span>' +
          '  </div>';
      }
      view += '  <div class="separator"></div>' +
        '  <div class="fr-widget__body-members text-center">';
      //
      let index = 0;
      //
      this.members.sort((a, b) => {
        return a.lastName.localeCompare(b.lastName);
      });
      this.members.forEach((member) => {
        const clazz = index % 2 === 1 ? '' : 'alt';
        view += '<div class="' + clazz + '">';
        if (member.fundraiserPageUrl != null && this.displayOptions.showRosterLinks) {
          view += '<a href="' + member.fundraiserPageUrl + '">';
        }
        view += '<span>' + this.buildMemberName(member) + '</span>';
        if (member.fundraiserPageUrl != null  && this.displayOptions.showRosterLinks) {
          view += '</a>';
        }
        view += '</div>';
        index += 1;
      });
      //
      view += '</div>' +
        '</div>';
      //

    }

    return view;
    
  }

  public buildMemberName(member: FundraiserTeamMember):string {
    let name = '';
    if (member != null) {
      if (member.memberTypeId === 1) {
        name = member.firstName + ' ' + member.lastName;
      }else {
        name = member.institutionName;
      }
    }
    return name;
  }

}