import { injectable } from 'inversify';
import { ComponentScanner } from './component-scanner';
import fundraiserContainer from '../../../container/fundraiser-container';
import sdkTypes from '../types/sdk-types';
import { BaseFundraiserComponent } from '../../base/base-fundraiser-component';
import { FundraiserEnvironment } from './fundraiser-environment';

@injectable()
export class ComponentBuilder {

  private scanner: ComponentScanner;
  private fundraiserEnvironment: FundraiserEnvironment;

  constructor() {
    this.fundraiserEnvironment = fundraiserContainer.get<FundraiserEnvironment>(sdkTypes.fundraiserEnvironment);
    this.scanner = fundraiserContainer.get<ComponentScanner>(sdkTypes.componentScanner);
  }

  public async initialize(): Promise<any> {
    //console.log('[ComponentBuilder] - Initializing component builder...');
    //
    // TODO ERASE THIS METHOD AND THE INPUT DATA
    // this.scanner.getComponentsScanned().forEach((component:BaseFundraiserComponent) => {
      // component.requestData(pageInput);
      // component.initiateGhostView();
    // });
    const mainData:any = this.fundraiserEnvironment.options.fundraiserData;
    this.fundraiserEnvironment.options.fundraiserPage = mainData.data;
    if (this.fundraiserEnvironment.options.fundraiserPage != null) {
      this.fundraiserEnvironment.options.fundraiserPage.event = mainData.event;
      this.fundraiserEnvironment.options.fundraiserPage.team = mainData.team;
      if (this.fundraiserEnvironment.options.fundraiserPage.team != null) {
        this.fundraiserEnvironment.options.fundraiserPage.team.parentTeam = mainData.parentTeam;
        this.fundraiserEnvironment.options.fundraiserPage.team.captains = mainData.captainList;
        this.fundraiserEnvironment.options.fundraiserPage.team.members = mainData.teamMemberList;
      }
    }
    this.fundraiserEnvironment.options.eventTeamMembers = mainData.eventTeamMemberList;
    this.fundraiserEnvironment.options.serverDate = mainData.serverDate;
    this.fundraiserEnvironment.options.serverDateOffset = mainData.serverDateOffset;
    this.fundraiserEnvironment.options.fundraiserCampaign = mainData.campaign;
    this.fundraiserEnvironment.options.campaignDonationRanges = mainData.campaignDonationRangeList;
    this.fundraiserEnvironment.options.newsFeed = mainData.newsFeed;
    this.fundraiserEnvironment.options.postCount = mainData.postCount;
    this.fundraiserEnvironment.options.donations = mainData.donationList;
    this.fundraiserEnvironment.options.comments = mainData.commentList;
    this.fundraiserEnvironment.options.posts = mainData.postList;
    this.fundraiserEnvironment.options.postCount = mainData.postCount;
    this.fundraiserEnvironment.options.displayOptions = mainData.displayOptions;
    this.fundraiserEnvironment.options.candidates = mainData.candidateList;
    this.fundraiserEnvironment.options.fundraisingItems = mainData.fundraisingItems;
    this.fundraiserEnvironment.options.shoppingItems = mainData.shoppingItemList;
    this.fundraiserEnvironment.options.registrationProduct = mainData.registrationProduct;
    //
    const fundraiserPage = this.fundraiserEnvironment.options.fundraiserPage;
    const fundraiserCampaign = this.fundraiserEnvironment.options.fundraiserCampaign;
    if (fundraiserPage != null) {
      this.fundraiserEnvironment.options.pageUrl = fundraiserPage.fullUrl;
    }
    this.fundraiserEnvironment.options.campaignName = fundraiserCampaign.name.toUpperCase();
      // Set fundraiser id for common purposes
    this.scanner.setHiddenVariables(fundraiserPage, fundraiserCampaign);

    //
    const componentPromises = [];
    //
    this.scanner.getComponentsScanned().forEach((component:BaseFundraiserComponent) => {
      componentPromises.push(this.prepareViewPromise(component));
    });

    const results = await Promise.all(componentPromises);
    //
    //TODO move to logging utils
    //console.log('[ComponentBuilder] - Finished building of widgets. Built: ' + componentPromises.length);
    return results;
  }

  private prepareViewPromise(component: BaseFundraiserComponent): Promise<any> {
    //TODO move to logging utils
    //console.log('[ComponentBuilder] - Preparing : ' + component.componentId);
    return new Promise<void>((resolve, reject) => {

      //TODO move to logging utils
      //console.log('[ComponentBuilder] - Loading async data for component: ' + component.componentId);

      if (component.hasView()) {
        if (this.fundraiserEnvironment.options.fundraiserPage != null) {
          component.setType(this.fundraiserEnvironment.options.fundraiserPage.fundraiserType);
        }
        //
        component.prepareData()
          .then((data) => {

            //TODO move to logging utils
            //console.log('[ComponentBuilder] - Filling component' + component.componentId  + 'with data: ' + data);
            component.disableLoading();
            component.fillData(data);

            //console.log('[ComponentBuilder] - Evaluating component: ' + component.componentId);
            if (component.hasView()) {
              //console.log('[ComponentBuilder] - Building component view: ' + component.componentId);
              //
              component.replaceView();
              //
              component.afterView();
            }
            resolve();

          })
          .catch((err) => {
            console.log('[ComponentBuilder] - component returned data with error: ' + component.componentId);
            console.log('[ComponentBuilder] - error: ' + err);
            reject(err);
          });
      }
    });
  }

  public loadMaps():void {
    console.log('Loading maps from async window function');
    this.scanner.getComponentsScanned().forEach((component:BaseFundraiserComponent) => {
      component.loadMaps();
    });
  }

  public select2():void {
    console.log('Loading select2 from async window function');
    this.scanner.getComponentsScanned().forEach((component:BaseFundraiserComponent) => {
      component.select2();
    });
  }
}