import { injectable } from 'inversify';
import { FundraiserEnvironment } from '../../../sdk/services/fundraiser-environment';
import sdkTypes from '../../../sdk/types/sdk-types';
import fundraiserContainer from '../../../../container/fundraiser-container';
import { FundraiserCampaign } from '../../../base/model/fundraiser-campaign';
import { FundraiserPage } from '../../../base/model/fundraiser-page';
import moment from 'moment';

@injectable()
export class DonateService {

  private fundraiserEnvironment: FundraiserEnvironment;
  private fundraiserCampaign: FundraiserCampaign;
  private fundraiserPage: FundraiserPage;
  private pageUrl: string;
  private serverDate: any;

  constructor() {
    this.fundraiserEnvironment = fundraiserContainer.get<FundraiserEnvironment>(sdkTypes.fundraiserEnvironment);
    this.pageUrl = this.fundraiserEnvironment.options.pageUrl;
  }

  public buildDonationUrl(donationAmount: any, donationClosedLink: string) {
    this.fundraiserCampaign = this.fundraiserEnvironment.options.fundraiserCampaign;
    this.fundraiserPage = this.fundraiserEnvironment.options.fundraiserPage;
    this.serverDate = this.fundraiserEnvironment.options.serverDate;

    const currentCampaignType = this.fundraiserEnvironment.options.fundraiserData.data.campaignType.toLowerCase();
    
    //
    let donationUrl = this.fundraiserCampaign.donationUrl;
    //
    if (this.fundraiserCampaign.name === 'SE') {
      donationUrl += '/frShoppingCart.action';
    }
    //
    donationUrl = donationUrl + //
      '?programGroupName=' + this.fundraiserCampaign.name + //
      '&fundraiserPageID=' + this.fundraiserPage.fundraiserPageId + //
      '&participantFirstName=';
    if (this.fundraiserPage.memberFirstName != null) {
      donationUrl += encodeURIComponent(this.fundraiserPage.memberFirstName);
    }
    donationUrl += '&participantLastName=';
    if (this.fundraiserPage.memberLastName != null) {
      donationUrl += encodeURIComponent(this.fundraiserPage.memberLastName);
    }
    if (this.fundraiserPage.displayName != null) {
      donationUrl += '&displayName=' + encodeURIComponent(this.fundraiserPage.displayName);
    } else if (this.fundraiserPage.event != null) {
      donationUrl += '&displayName=' + encodeURIComponent(this.fundraiserPage.event.name);
    }
    donationUrl += '&fundraiserPageURL=' + encodeURIComponent(this.fundraiserPage.fullUrl);
    //
    if (this.fundraiserCampaign.name === 'REGATTA' || this.fundraiserCampaign.name === 'SE') {
      let campaignEventId = this.fundraiserPage.event.campaignEventId;
      if (campaignEventId == null) {
        campaignEventId = this.fundraiserPage.campaignEventId;
      }
      donationUrl += '&oocpe=' + campaignEventId + '&nextShoppingCartAction=checkout';
    }
    //
    if (this.fundraiserCampaign.name === 'SE' && currentCampaignType == 'bigclimb') {
      donationUrl += '&themeName=Bigclimb';
    }else if(this.fundraiserCampaign.name === 'SE' && currentCampaignType == 'events'){
      donationUrl += '&themeName=Events';
    }else if(this.fundraiserCampaign.name === 'SE' && currentCampaignType == 'voy'){
      donationUrl += '&themeName=Events';
    }
    //
    const fundraisingClosed = moment(this.fundraiserPage.closeDonationsDate).isBefore(moment(this.serverDate));
    if (fundraisingClosed) {
      // check if there is a donationClosedLink from WP, if so use it
      if (donationClosedLink == null || donationClosedLink === '') {
        // otherwise, use the donationClosesURL from the Campaign (Program Group)
        donationUrl = this.fundraiserCampaign.donationClosedURL;
      } else {
        donationUrl = donationClosedLink;
      }
    }
    if (donationAmount !== null && donationAmount !== '') {
      donationUrl = donationUrl.replace('?', '?amount=' + donationAmount + '&');
    }
    //
    return donationUrl;
  }
}