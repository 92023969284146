import { injectable, inject } from 'inversify';
import { BaseFundraiserComponent } from '../../../base/base-fundraiser-component';
import fundraiserContainer from '../../../../container/fundraiser-container';
import { FundraiserEnvironment } from '../../../sdk/services/fundraiser-environment';
import sdkTypes from '../../../sdk/types/sdk-types';
import { FundraiserCampaign } from '../../../base/model/fundraiser-campaign';
import { FundraiserPage } from '../../../base/model/fundraiser-page';
import joinTypes from '../../types/join-types';
import { SearchService } from '../../search/services/search-service';

declare const window: any;

@injectable()
export class SearchExternalComponent extends BaseFundraiserComponent{

  private fundraiserEnvironment: FundraiserEnvironment;
  private searchService: SearchService;
  private fundraiserCampaign: FundraiserCampaign;
  private fundraiserPage: FundraiserPage;

  constructor() {
    super();
    this.fundraiserEnvironment = fundraiserContainer.get<FundraiserEnvironment>(sdkTypes.fundraiserEnvironment);
    this.searchService = fundraiserContainer.get<SearchService>(joinTypes.SearchService);
    this.componentId = 'fr-search-external';
    this.title = 'Find a Participant / Team';
  }

  public scanDataAttributes(): void {
    // empty constructor
  }

  public fillData(data): void {
    this.data = data;
    this.fundraiserCampaign = this.fundraiserEnvironment.options.fundraiserCampaign;
    this.fundraiserPage = this.fundraiserEnvironment.options.fundraiserPage;
  }

  public getGhostView(): string {
    // Do not show widget if content is empty
    let view =  this.getTitleView() +
      '<div class="fr-widget__body">' +
      ' <form action="" method="get">' +
      '   <div class="search-line">' +
      '     <div class="search-input-box">';
    //
    view += '<div class="find-participant-container" style="display: none">' +
      '<select class="find-participant-select to-select2" style="display: none; width: 100%">' +
      '</select>' +
      '</div>';
    //
    view +=  '</div> ' +
    '     </div>' +
      '</form>';
    //
    view += '</div>';
    //
    return view;
  }

  public getView(): string {
    return this.getGhostView();
  }

  public afterView(): boolean {
    window.jQuery('.find-participant-container').show();
    this.searchService.fetchMembersInfo('').then((data: any) => {
      window.jQuery('.find-participant-select').show();
      window.jQuery('.find-participant-container .loading').hide();
      window.jQuery('.find-participant-select').append('<option value=""></option>');
      data.forEach((member) => {
        const view = '<option value="' + member.fullUrl + '">' + member.displayName + '</option>';
        window.jQuery('.find-participant-select').append(view);
      });
      //
      this.select2();
      window.jQuery('.find-participant-select').on('change', (e) => {
        const url = window.jQuery(e.target).val();
        if (url) {
          window.location.href = url;
        }
      });
      //
      // window.jQuery('.find-participant-select').select2('open');

    });
    return true;
  }

  public select2(): void {
    if (window.jQuery('.find-participant-select').select2 !== undefined) {
      window.jQuery('.find-participant-select').select2({
        placeholder: '',
      });
    }
  }

}