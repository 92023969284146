import { injectable, inject } from 'inversify';
import { Thermometer } from '../model/thermometer';
import { formatCurrency, formatCurrencyNoDecimals } from '../../../../utils/utils';
import { BaseProgressGoalComponent } from './base-progress-goal-component';

@injectable()
export class ProgressGoalComponent extends BaseProgressGoalComponent{
  //
  constructor() {
    super();
    this.componentId = 'fr-progress-goal';
    this.title = 'Progress Goal';
  }

  //--FUNCTIONS RELATED TO BUILDING VIEW PARTIALS--//

  //General View Partials
  private build_individual_event_thermometer_partial(): string{
    const view_output = this.buildThermometer(this.individualThermometer, this.type, !this.hideMainBar , !this.hideMainPercentage);

    return view_output;
  }

  private build_thermometer_bar_percent(showBar: boolean, showPercentage: boolean): string{
    let view_output = ``;

    if (!this.hideTeam) 
      view_output = this.buildThermometer(this.teamThermometer, this.type, showBar, showPercentage);

    return view_output;
  }

  private build_donation_and_grand_total_partial(): string{
    let view_partial_3_1 = ``;
    let view_partial_3_2 = ``;
    let view_partial_3_3 = ``;
    let view_partial_3_4 = ``;

    //Partial 3-1
    //donation amount
    if ((this.donationsAmount > 0 || this.retailSalesAmount > 0) && (this.fundraiserCampaign.name !== 'LTN')) {
      view_partial_3_1 = this.build_view_partial_3_1();
    }

    //Partial 3-2
    if (this.sponsorshipsAmount > 0) {
      view_partial_3_2 = this.build_view_partial_3_2();
    }

    //grand total
    let showTotal = false;
    let gtotal = 0;
    let label = "Total";
    if( (this.fundraiserCampaign.name !== 'LTN' &&this.donationsAmount > 0 || this.retailSalesAmount > 0) && this.sponsorshipsAmount > 0){
      showTotal = true;
      gtotal = this.donationsAmount + this.retailSalesAmount + this.sponsorshipsAmount;
      label = "Grand Total";
    }else if(this.fundraiserCampaign.name === 'LTN' && this.type === "TEAM"){
      showTotal = true;
      gtotal = this.donationsAmount + this.retailSalesAmount + this.sponsorshipsAmount;
      label = "Overall Impact";
    }
        
    //Partial 3-3
    if(showTotal){
      view_partial_3_3 = this.build_view_partial_3_3(label, gtotal);
    }
          
    //Partial 3-4
    if (this.team.parentTeam != null) {
      view_partial_3_4 = this.build_view_partial_3_4();
    }
    
    const view_output = `
      <div class="fr-widget__body-progress-team d-flex flex-column align-items-center">
        ${view_partial_3_1}
        ${view_partial_3_2}
      </div>

      ${view_partial_3_3}
      ${view_partial_3_4}
    `;

    return view_output;
  }

  private build_view_partial_3_1(): string{
    const view_output = `
      <div>
        <span class="font-weight-bold">Donations:&nbsp;</span> ${formatCurrency(this.donationsAmount + this.retailSalesAmount) }
      </div>
    `;

    return view_output;
  }

  private build_view_partial_3_2(): string{
    const view_output = `
      <div><span class="font-weight-bold">Sponsorship:&nbsp;</span> ${formatCurrency(this.sponsorshipsAmount)}</div>
    `;

    return view_output;
  }

  private build_view_partial_3_3(label: string, gtotal: number): string{
    const view_output = `
      <div class="fr-widget__body-progress-team d-flex flex-column align-items-center grand-total">
        <div>
          <span class="font-weight-bold">${label}:&nbsp;</span>
          ${formatCurrency(gtotal)}
        </div> 
      </div>
    `;

    return view_output;
  }

  private build_view_partial_3_4(): string{
    const view_output = `
      <div class="fr-widget__body-progress-team d-flex flex-column justify-content-center">
        <span class="font-weight-bold text-center">We're part of the team</span>
        <span class="font-weight-bold text-center">${this.team.parentTeam.teamName} </span>
      </div>
      <div class="d-flex justify-content-center buttons">
        <a class="btn button text-uppercase btn-team-page" href="${this.team.parentTeam.fullUrl}">View team page</a>
      </div>
    `;

    return view_output;
  }

  private build_team_url_partial(): string{
    const view_output = `
      <div class="d-flex justify-content-center buttons">
        <a class="btn button text-uppercase btn-team-page" href="${this.team.fullUrl}">View team page</a>
      </div>
    `;

    return view_output;
  }

  //Thermometer Partials
  private build_thermometer_header_partial(): string{
    const view_output = `
      <div class="fr-widget__body-progress-team type-my-team d-flex justify-content-center">
        <span class="font-weight-bold">My Team</span>
      </div>
    `;

    return view_output;
  }

  private build_amount_raised_partial(thermometer_type_individual: boolean, ltn_or_tnt: boolean, total_sponsorship: number): string{
    let raised_text = ``;
    const total = total_sponsorship;

    if( thermometer_type_individual == true ){

      raised_text = `Raised:&nbsp;`;

    }else{

      if( ltn_or_tnt == true ){
        raised_text = `Team Total:&nbsp;`;
      }else{
        raised_text = `Total Raised:&nbsp;`;
      }
      
    }

    const view_output = `
      <div class="fr-widget__body-progress-column d-flex">
        <span class="font-weight-bold"> ${raised_text} </span>
        <span class="raised-value"> ${formatCurrency(total)} </span>
      </div>
    `;

    return view_output;

  }

  private build_donations_goal_partial(ltn_and_team: boolean, thermometer: Thermometer, goalLabel: string): string{
    let view_output = ``;
    const goal_label = goalLabel;

    if( ltn_and_team == true ){

      view_output = `
        <!--donations-->
        <div>
          <span class="font-weight-bold">Team Fundraising Total:&nbsp;</span>
          ${formatCurrencyNoDecimals(this.donationsAmount + this.retailSalesAmount) }
        </div>

        <!--goal-->
        <div class="fr-widget__body-progress-column d-flex">
          <span class="font-weight-bold">Team Fundraising Goal:&nbsp;</span>
          <span class="goal-value">${formatCurrencyNoDecimals(thermometer.goal)}</span>
        </div>
      `;

    }else{

      view_output = `
        <div class="fr-widget__body-progress-column d-flex">
          <span class="font-weight-bold">${goal_label}:&nbsp;</span>
          <span class="goal-value">${formatCurrencyNoDecimals(thermometer.goal)}</span>
        </div>
     `;

    }

    return view_output;
  }

  private build_raised_value_partial(raisedLabel: string, thermometer: Thermometer): string{
    const view_output = `
      <div class="fr-widget__body-progress-column d-flex">
        <span class="font-weight-bold">  ${raisedLabel} :&nbsp;</span>
        <span class="raised-value"> ${ formatCurrency(thermometer.raised) } </span>
      </div>
    `;
    
    return view_output;
  }

  private build_progess_bar_partial(is_regatta: boolean, is_regatta_individual: boolean, is_regata_team: boolean, thermometer: Thermometer): string{
    let progressBarText = ``;
    let slider = ``;

    if(is_regatta){
      if(is_regatta_individual){
        progressBarText = `<div class="progress-bar-text">Individual goal ${formatCurrency(thermometer.goal)} </div>`;
      }else if(is_regata_team){
        progressBarText = `<div class="progress-bar-text">Team goal ${formatCurrency(thermometer.goal)} </div>`;
      }

      slider = `
        <div class="${thermometer.type}-slider slider visible" style="left: -40px;">
          <div class="sliderText">${formatCurrency(thermometer.raised)}</div>
        </div>
      `;
    }

    const view_output = `
     <div class="fr-widget__body-progress-container ${thermometer.type}-progress-container"> 
        <div class="progress"> 
          <div class="progress-bar" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" 
            style="width: ${thermometer.percentageWidth}%"> ${thermometer.percentage}%</div> ${progressBarText} 
        </div> ${slider} 
      </div>
    `;

    return view_output;
  }

  private build_thermometer_percentage_partial(thermometer: Thermometer): string{
    const view_output= `
      <div class="fr-widget__body-percentage d-flex justify-content-center">
        <span class="">${thermometer.percentage}&nbsp;%</span>
      </div>
    `;
    return view_output;
  }

  private build_donate_button_partial(thermometer: Thermometer): string{
    const view_output = `
      <div class="fr-widget__body-event-footer">
        <div class="event-progress-goal-text"> ${formatCurrencyNoDecimals(thermometer.raised)} 
          OUT OF ${formatCurrencyNoDecimals(thermometer.goal)} RAISED </div>
        <div class="event-donate-link">
          <a href="${this.donateUrl}"><button class="secondary-button">DONATE TO THE EVENT</button></a>
        </div>
      </div>
    `;

    return view_output;
  }
  //--END OF FUNCTIONS RELATED TO BUILDING VIEW PARTIALS--//

  public getView(): string {
    let individual_event_thermometer_partial = ``;
    let thermometer_bar_percent_partial = ``;
    let donation_and_grand_total_partial = ``;
    let team_url_partial = ``;

    // Do not show widget if content is empty
    if (this.teamThermometer != null || this.individualThermometer != null) {
      const view_header =  this.getTitleView();

      //Partial 1
      if (this.type === 'INDIVIDUAL' || this.type === 'EVENT') {
        individual_event_thermometer_partial = this.build_individual_event_thermometer_partial();
      }

      if (this.team != null) {
        const showBar = !(this.type === 'INDIVIDUAL' && this.fundraiserCampaign.name !== 'REGATTA');
        const showPercentage = (this.type !== 'INDIVIDUAL');

        //Partial 2
        thermometer_bar_percent_partial = this.build_thermometer_bar_percent(showBar, showPercentage);

        //Partial 3
        if (this.type === 'TEAM') {
          donation_and_grand_total_partial = this.build_donation_and_grand_total_partial();
        }
        //Partial 4
        if (this.type === 'INDIVIDUAL') {
          team_url_partial = this.build_team_url_partial();
        }
      }

      const view = `
        ${view_header}
        <div class="fr-widget__body">

          ${individual_event_thermometer_partial}
          ${thermometer_bar_percent_partial}
          ${donation_and_grand_total_partial}
          ${team_url_partial}

        </div>
      `;

      return view;
    }
  }
  
  public buildThermometer(thermometer: Thermometer, type: string, showBar: boolean, showPercentage: boolean): string {
    let view = ``;
    let total = thermometer.raised;

    let goalLabel = this.goalLabel;
    let raisedLabel = 'Raised';

    //Variables from conditional statement blocks
    let thermometer_type_individual = false;
    let ltn_or_tnt = false;
    let ltn_and_team = false;

    //Partial output strings
    let thermometer_header_partial = ``;
    let amount_raised_partial = ``;
    let donations_goal_partial = ``;
    let raised_value_partial = ``;
    let progess_bar_partial = ``;
    let thermometer_percentage_partial = ``;
    let donate_button_partial = ``;
    
    //Partial 1
    if (type === 'INDIVIDUAL' && thermometer.type  === 'TEAM') {
      thermometer_header_partial = this.build_thermometer_header_partial();
    }

    //view += ` <div class="fr-widget__body-progress-footer d-flex justify-content-center type-${type}-${thermometer.type}">`;

    //Partial 2
    if (type === 'INDIVIDUAL' && this.fundraiserCampaign.name !== 'SE') {

      if (thermometer.type === 'INDIVIDUAL') {

        thermometer_type_individual = true;

      } else {
        if (this.fundraiserCampaign.name === 'LTN' || this.fundraiserCampaign.name === 'TNT') {

          ltn_or_tnt = true;

        }

        //remove sponsorship amount from team total on LTN
        if(this.fundraiserCampaign.name === "LTN" && this.sponsorshipsAmount > 0)
          total -= this.sponsorshipsAmount;
      }
     
      amount_raised_partial = this.build_amount_raised_partial(thermometer_type_individual, ltn_or_tnt, total);
    }
    
    if (type === 'INDIVIDUAL' && thermometer.type !== 'INDIVIDUAL' && this.fundraiserCampaign.name === 'SE') {
      goalLabel = 'Team ' + this.goalLabel;
      raisedLabel = 'Team ' + raisedLabel;
    }
    
    //Partial 3
    //LTN Team Page UI
    if(this.fundraiserCampaign.name === 'LTN' && this.type === "TEAM"){
      
      ltn_and_team = true;

    }

    donations_goal_partial = this.build_donations_goal_partial(ltn_and_team, thermometer, goalLabel);
   
    //Partial 4
    if (this.fundraiserCampaign.name === 'SE' && this.page.fundraiserType !== 'EVENT') {
      raised_value_partial = this.build_raised_value_partial(raisedLabel, thermometer);
    }
    //view += '</div>';
    
    //Partial 5
    if (showBar) {
      let is_regatta = false;
      let is_regatta_individual = false;
      let is_regata_team = false;

      if (this.fundraiserCampaign.name === 'REGATTA') {
        is_regatta = true;

        if (thermometer.type === 'INDIVIDUAL') {
          is_regatta_individual = true;
        }else if (thermometer.type === 'TEAM') {
          is_regata_team = true;
        }
      }

      progess_bar_partial = this.build_progess_bar_partial(is_regatta, is_regatta_individual, is_regata_team, thermometer);
    }

    //Partial 6
    if (showPercentage) {
      thermometer_percentage_partial = this.build_thermometer_percentage_partial(thermometer);
    }

    //Partial 7
    if (this.fundraiserCampaign.name === 'SE' && this.page.fundraiserType === 'EVENT') {
      donate_button_partial =  this.build_donate_button_partial(thermometer);
    }

    view = `
      ${thermometer_header_partial}

      <div class="fr-widget__body-progress-footer d-flex justify-content-center type-${type}-${thermometer.type}">

        ${amount_raised_partial}

        ${donations_goal_partial}

        ${raised_value_partial}

      </div>

      ${progess_bar_partial}

      ${thermometer_percentage_partial}
      
      ${donate_button_partial}

    `;

    return view;
  }

  public afterView(): boolean {
    if (this.fundraiserCampaign.name === 'REGATTA') {
      this.positionTooltips();
      window.jQuery(window).on('resize', () => {
        this.positionTooltips();
      });
    }
    return true;
  }

  private positionTooltips() {
    const individualWidth = window.jQuery('.INDIVIDUAL-progress-container .progress-bar').width();
    const individualTooltipWidth = window.jQuery('.INDIVIDUAL-progress-container .slider').width();
    const individualLeftPx = individualWidth - individualTooltipWidth / 2;
    window.jQuery('.INDIVIDUAL-progress-container .slider').attr('style', 'left: ' + individualLeftPx + 'px');
    //
    const teamWidth = window.jQuery('.TEAM-progress-container .progress-bar').width();
    const teamTooltipWidth = window.jQuery('.TEAM-progress-container .slider').width();
    const teamLeftPx = teamWidth - teamTooltipWidth / 2;
    window.jQuery('.TEAM-progress-container .slider').attr('style', 'left: ' + teamLeftPx + 'px');

    window.jQuery('.fr-progress-goal .slider').on('click', (event) => {
      window.jQuery(event.currentTarget).hide();
      return true;
    });
  }
}
