import { injectable, inject } from 'inversify';
import { BaseFundraiserComponent } from '../../../base/base-fundraiser-component';
import fundraiserContainer from '../../../../container/fundraiser-container';
import { FundraiserEnvironment } from '../../../sdk/services/fundraiser-environment';
import sdkTypes from '../../../sdk/types/sdk-types';
import { FundraiserPageInput } from '../../../base/model/input/fundraiser-page-input';
import { FundraiserEventTeamMember } from '../../../base/model/fundraiser-event-team-member';

@injectable()
export class EventParticipantsComponent extends BaseFundraiserComponent{

  private fundraiserEnvironment: FundraiserEnvironment;
  private eventTeamMembers: FundraiserEventTeamMember[];

  constructor() {
    super();
    this.fundraiserEnvironment = fundraiserContainer.get<FundraiserEnvironment>(sdkTypes.fundraiserEnvironment);
    this.componentId = 'fr-event-participants';
    this.title = 'Our Participants';
  }

  public scanDataAttributes(): void {
    // empty
  }

  requestData(pageInput: FundraiserPageInput) {
    pageInput.fetchEventTeamMembers = true;
  }

  public fillData(data): void {
    this.eventTeamMembers = this.fundraiserEnvironment.options.eventTeamMembers;
  }

  public getView(): string {
    const hasData = this.eventTeamMembers != null && this.eventTeamMembers.length > 0;
    if (hasData || this.showIfEmpty) {
      let view = this.getTitleView() +
        '<div class="fr-widget__body"><div class="table-responsive">' +
        '<table class="table table-sm">';
      //
      if (hasData) {
        this.eventTeamMembers.forEach((eventTeamMember: FundraiserEventTeamMember) => {
          let displayName = eventTeamMember.firstName + ' ' + eventTeamMember.lastName;
          if (displayName.length < 50) {
            displayName = displayName.substring(0, 50);
          }
          view += '<tr><td class="text-truncate"><a href="' + eventTeamMember.fullUrl + '">' + displayName + '</a></td></tr>';
        });
      }
      //
      view +=  '</table></div></div>';
      return view;
    }
    return '';
  }

}