import { injectable } from 'inversify';
import { FundraiserEnvironment } from '../../../sdk/services/fundraiser-environment';
import sdkTypes from '../../../sdk/types/sdk-types';
import fundraiserContainer from '../../../../container/fundraiser-container';
import { PostsService } from './posts-service';
import contentTypes from '../../types/content-types';
import { FundraiserPost } from '../../../base/model/fundraiser-post';
import { FundraiserPage } from '../../../base/model/fundraiser-page';
import { PostNavigation } from '../model/post-navigation';

/**
 * The page of post will be defined by the fundraiser page variable.
 */
@injectable()
export class PostsPager {

  private fundraiserEnvironment: FundraiserEnvironment;
  private postsService: PostsService;
  private fundraiserPage: FundraiserPage;
  /**
   * Post ordered by viewed page index. If only three post per page are accepted this map will have: <br />
   * index 0 - posts[0, 1, 2] <br />
   * index 1 - posts[3, 4, 5]
   */
  private postsViewMap = new Map<number, FundraiserPost[]>();
  /**
   * A different and practical view for the cached posts, in an ordered list.
   */
  private cachedPostsAsList: FundraiserPost[];
  /**
   * Total number of post in the db.
   */
  private totalPosts: number;
  /**
   * currentView index of the page of posts
   */
  private currentViewIndex = 0;
  /**
   * This is only set when we are in a post view.
   */
  private currentViewPostIndex : number;

  constructor() {
    this.fundraiserEnvironment = fundraiserContainer.get<FundraiserEnvironment>(sdkTypes.fundraiserEnvironment);
    this.postsService = fundraiserContainer.get<PostsService>(contentTypes.PostsService);
  }

  public init(initialPosts: FundraiserPost[]) {
    //
    let posts = initialPosts;

    //this should never happen, all pages are expected to have a post based upon business logic
    if (posts == null) {
      posts = [];
    }

    //
    this.fundraiserPage = this.fundraiserEnvironment.options.fundraiserPage;
    this.cachedPostsAsList = posts;
    this.totalPosts = this.fundraiserEnvironment.options.postCount;
    //
    this.postsViewMap.set(0, this.cachedPostsAsList.slice(0, this.cachedPostsAsList.length));
  }

  /**
   * Return true if we can still navigate to older posts page
   */
  public getCurrentPage() {
    return this.currentViewIndex;
  }

  /**
   * Return true if we can still navigate to older posts page
   */
  public existsNewerPage() {
    return this.currentViewIndex > 0;
  }

  /**
   * Return true if we can still navigate to older posts page
   */
  public existsOlderPage() {
    return (this.currentViewIndex + 1) * this.fundraiserPage.postsPerPage < this.totalPosts;
  }

  public fetchNewPage(): FundraiserPost[] {
    if (this.existsNewerPage()) {
      this.currentViewIndex -= 1;
      return this.postsViewMap.get(this.currentViewIndex);
    }
    return null;
  }

  public fetchOlderPage(): Promise<FundraiserPost[]> {
    return new Promise<FundraiserPost[]>((resolve, reject) => {
      if (this.existsOlderPage()) {
        this.currentViewIndex += 1;
        const olderPostPage = this.postsViewMap.get(this.currentViewIndex);
        if (olderPostPage != null) {
          // Return cached page
          resolve(olderPostPage);
        }else {
          this.fetchPosts().then((data) => {
            resolve(data);
          }).catch((e) => {
            reject(e);
          });
        }
      } else {
        resolve([]);
      }
    });
  }

  private fetchPosts(): Promise<any> {
    return this.fetchPostsForPage(this.currentViewIndex);
  }

  private fetchPostsForPage(pageIndex: number): Promise<any> {
    return new Promise((resolve, reject) => {
      const offset = (pageIndex) * this.fundraiserPage.postsPerPage;
      this.postsService.fetchPosts(offset, this.fundraiserPage.postsPerPage).then((posts: any) => {
        this.cachedPostsAsList = this.cachedPostsAsList.concat(posts.postList);
        this.postsViewMap.set(pageIndex, posts.postList.slice(0, posts.postList.length));
        resolve(this.postsViewMap.get(pageIndex));
      }).catch((e) => {
        reject(e);
      });
    });
  }

  public getPostNavigation(postIndex: number): Promise<PostNavigation> {
    return new Promise((resolve, reject) => {
      this.currentViewPostIndex = postIndex;
      //
      const navigation = new PostNavigation();
      navigation.post = this.cachedPostsAsList[this.currentViewPostIndex];
      if (this.currentViewPostIndex > 0) {
        navigation.nextIndex = postIndex - 1;
        navigation.next = this.cachedPostsAsList[this.currentViewPostIndex - 1];
      }
      const prevIndex = this.currentViewPostIndex + 1;
      if (prevIndex < this.totalPosts) {
        // There is a prev post. Check is if fetched
        if (prevIndex < this.cachedPostsAsList.length) {
          navigation.previousIndex = postIndex + 1;
          navigation.previous = this.cachedPostsAsList[prevIndex];
          resolve(navigation);
        }else {
          this.fetchPostsForPage(this.currentViewIndex + 1).then((posts) => {
            navigation.previousIndex = postIndex + 1;
            navigation.previous = posts[0];
            resolve(navigation);
          }).catch((e) => {
            reject(e);
          });
        }
      }else {
        resolve(navigation);
      }
    });
  }

  public getPostsViewForPostIndex(postIndex: number): FundraiserPost[] {
    this.currentViewIndex = Math.trunc(postIndex / this.fundraiserPage.postsPerPage);
    this.currentViewPostIndex = postIndex;
    return this.getPosts();
  }

  public getPosts(): FundraiserPost[] {
    return this.postsViewMap.get(this.currentViewIndex);
  }

  public getOlderPageIndex(): number {
    return this.currentViewIndex + 1;
  }

  public getNewerPageIndex(): number {
    return this.currentViewIndex - 1;
  }

  public goHome() {
    this.currentViewIndex = 0;
  }

  // /**
  //  * Fetches one page older from the cache if possible or from the server.
  //  */
  // public async fetchOlderPage() {
  //   const olderPostPage = this.postsViewMap.get();
  //   if ()
  // }

  /**
   * For testing purposes
   */
  public getFundraiserEnvironment() {
    return this.fundraiserEnvironment;
  }
}