import { injectable, inject } from 'inversify';
import { formatCurrency, isEventNumber } from '../../../../utils/utils';
import { BaseCartComponent } from './base-cart-component';

@injectable()
export class CartComponent extends BaseCartComponent{

  constructor() {
    super();
    this.componentId = 'fr-cart';
    this.title = 'Shopping Cart';
  }

  public getView(): string {
    let view = '';
    //
    if (this.sessionItems != null && this.sessionItems.length > 0) {
      view = this.buildCartView();
    }else {
      view = '<p>No items are available</p>';
    }
    //
    return view;
  }

  public buildCartView() {
    let view = '';
    //
    view = '' +
      '<div class="fr-widget__body">' +
      '<div class="cart-panel">' +
      '<div class="cart-table">' +
      '<table>' +
      '<thead>' +
      '<tr>' +
      '<th>Name</th>' +
      '<th>Price</th>' +
      '<th>Quantity</th>' +
      '<th>Total</th>' +
      '<th>Remove</th>' +
      '</tr>' +
      '</thead>' +
      '<tbody>';
    //
    let subtotal = 0;
    this.sessionItems.forEach((si) => {
      const item = this.shoppingItems.find(i => i.itemId === si.itemId);
      const total = si.quantity * item.listPrice;
      subtotal += total;
      if (si.quantity > 0) {
        view += '<tr>' +
          '<td>' + item.name + '</td>' +
          '<td>' + formatCurrency(item.listPrice) + '</td>' +
          '<td>' +
          '   <input type="text" name="quantity" data-item-id="' + item.itemId + '" value="' + si.quantity + '"> ' +
          '   <button type="button">Update Quantity</button> ' +
          '</td>' +
          '<td class="item-total">' + formatCurrency(total) + '</td>' +
          '<td><a href="javascript:;" class="remove-item" data-item-id="' + item.itemId + '"><img src="/content360/mwoy/images/delete.png"></a></td>' +
          '</tr>';
      }
    });
    //
    view += '</tbody>' +
      '</table>' +
      '</div>' +
      '<div class="cart-subtotal">Sub total:<span class="cart-subtotal-value">' + formatCurrency(subtotal) + '</span></div> ' +
      '</div>' +
      '<div class="checkout-container"><button type="button" class="checkout">checkout</button></div> ' +
      '</div>';
    //
    return view;
  }

  public afterView(): boolean {
    this.refreshCartHeaderLink();
    window.jQuery('.cart-table input').on('keypress', (e) => {
      return isEventNumber(e);
    });
    window.jQuery('.cart-table button').on('click', (e) => {
      const input = window.jQuery(e.target).parent().find('input');
      const itemId = input.data('item-id');
      const quantity = parseInt(input.val() + '', 10);
      //
      if (quantity <= 0) {
        this.showErrorMessage('Please enter valid quantity.');
      } else {
        const availableQuantity = this.getAvailableItemQuantity(itemId);
        if (availableQuantity >= quantity) {
          // Update total price of item
          const total = quantity * this.shoppingItems.find(i => i.itemId === itemId).listPrice;
          input.parent().parent().find('.item-total').text(formatCurrency(total));
          // update quantity in session
          this.cartService.updateCart(itemId, quantity).then((data: any) => {
            this.sessionItems = data;
          });
        } else {
          this.showErrorMessage('Maximum available quantity: ' + availableQuantity);
          // Restore previous value
          const prevVal = this.sessionItems.find(function (i) { return i.itemId === itemId; }).quantity;
          input.val(prevVal);
        }
      }
    });
    window.jQuery('.cart-table .remove-item').on('click', (e) => {
      const itemId = window.jQuery(e.target).parent().data('item-id');
      //
      const index = this.sessionItems.findIndex(d => d.itemId === itemId);
      // Delete element from array
      this.sessionItems.splice(index, 1);
      // Delete table row
      window.jQuery(e.target).parent().parent().parent().remove();
      //
      // Recalculate total
      let subtotal = 0;
      this.sessionItems.forEach((si) => {
        const item = this.shoppingItems.find(i => i.itemId === si.itemId);
        const total = si.quantity * item.listPrice;
        subtotal += total;
      });
      //
      window.jQuery('.fr-cart .cart-subtotal .cart-subtotal-value').text(formatCurrency(subtotal));
      // Remove from http session
      this.cartService.updateCart(itemId, 0).then((data: any) => {
        console.log('Item removed');
        this.sessionItems = data;
      });
      //
      this.refreshCartHeaderLink();
      //
      if (this.sessionItems == null || this.sessionItems.length === 0) {
        window.jQuery('.fr-cart').html(this.getView());
      }
    });
    //
    window.jQuery('.checkout').on('click', (e) => {
      window.location.href = this.getDonateCartUrl();
    });
    //
    return true;
  }

  private showErrorMessage(message: string): void {
    alert(message);
  }

}