import { injectable, inject } from 'inversify';
import moment from 'moment';
import { BaseFundraiserComponent } from '../../../base/base-fundraiser-component';
import fundraiserContainer from '../../../../container/fundraiser-container';
import { FundraiserEnvironment } from '../../../sdk/services/fundraiser-environment';
import sdkTypes from '../../../sdk/types/sdk-types';
import { FundraiserEvent } from '../../../base/model/fundraiser-event';

@injectable()
export class EventInfoComponent extends BaseFundraiserComponent{

  private fundraiserEnvironment: FundraiserEnvironment;
  private fundraiserEvent: FundraiserEvent;
  private date: any;

  constructor() {
    super();
    this.fundraiserEnvironment = fundraiserContainer.get<FundraiserEnvironment>(sdkTypes.fundraiserEnvironment);
    this.componentId = 'fr-event-info';
    this.title = 'Event Info';
  }

  public fillData(data): void {
    this.fundraiserEvent = this.fundraiserEnvironment.options.fundraiserPage.event;

    if (this.fundraiserEvent.date != null) {
      this.date = moment(this.fundraiserEvent.date + this.fundraiserEvent.timezoneOffset * 1000 * 60 * 60);
    }
  }

  public getView(): string {

    // Do not show widget if content is empty
    if (this.fundraiserEvent != null) {
      const view =  this.getTitleView() +
        '<div class="fr-widget__body">' +
        ' <div class="d-flex flex-row">' +
        '   <div class="flex-column fr-widget__body-date">' +
        '     <h5>' + this.date.format('D') + '</h5>' +
        '     <h6>' + this.date.format('MMM') + '</h6>' +
        '   </div>' +
        '   <div class="flex-column flex-grow-1 fr-widget__body-location">' +
        '       <h5>' + this.fundraiserEvent.location + '</h5>' +
        '       <h6><i class="fa fa-map-marker"></i>' + this.fundraiserEvent.city + ', ' + this.fundraiserEvent.state + '</h6>' +
        '   </div>' +
        ' </div>' +
        '</div>';
      return view;
    }
  }

}