import { injectable, inject } from 'inversify';
import moment from 'moment';
import 'moment-timezone-slim';
import { BaseFundraiserComponent } from '../../../base/base-fundraiser-component';
import fundraiserContainer from '../../../../container/fundraiser-container';
import { FundraiserEnvironment } from '../../../sdk/services/fundraiser-environment';
import sdkTypes from '../../../sdk/types/sdk-types';
import { FundraiserEvent } from '../../../base/model/fundraiser-event';
import { FundraiserPage } from '../../../base/model/fundraiser-page';
import { FundraiserPageInput } from '../../../base/model/input/fundraiser-page-input';
import { FundraiserCampaign } from '../../../base/model/fundraiser-campaign';

@injectable()
export class CountdownClockComponent extends BaseFundraiserComponent{

  private fundraiserEnvironment: FundraiserEnvironment;
  private fundraiserEvent: FundraiserEvent;
  private page: FundraiserPage;
  private fundraiserCampaign: FundraiserCampaign;
  private serverDate: number;
  private serverDateOffset: number;
  private type: string;
  private date: any;
  // options
  private format: string; // NORMAL (two lines) / LONG (1 line)
  private clockLabels: string; // HIDE, TOP, BOTTOM, INSIDE_TOP, INSIDE_BOTTOM
  private hideIfClosedDonation: boolean;
  private hideTime: boolean;
  private hideTimezone: boolean;
  private hideCountdownWeeks: boolean;
  private hideCountdownDays: boolean;
  private hideCountdownHours: boolean;
  private hideCountdownMinutes: boolean;
  private hideCountdownSeconds: boolean;
  private eventErrorMessage: string;

  constructor() {
    super();
    this.fundraiserEnvironment = fundraiserContainer.get<FundraiserEnvironment>(sdkTypes.fundraiserEnvironment);
    this.componentId = 'fr-countdown';
    this.title = 'Countdown';
  }

  public scanDataAttributes(): void {
    this.hideIfClosedDonation =
    this.domElement.get(0).dataset.hideIfClosedDonation != null ? this.domElement.get(0).dataset.hideIfClosedDonation === 'true' : false;
    this.format = this.domElement.get(0).dataset.format != null ? this.domElement.get(0).dataset.format : 'NORMAL';
    this.clockLabels = this.domElement.get(0).dataset.clockLabels != null ? this.domElement.get(0).dataset.clockLabels : 'TOP';
    this.hideTime = this.domElement.get(0).dataset.hideTime != null ? this.domElement.get(0).dataset.hideTime === 'true' : false;
    this.hideTimezone = this.domElement.get(0).dataset.hideTimezone != null ? this.domElement.get(0).dataset.hideTimezone === 'true' : false;
    this.hideCountdownWeeks = this.domElement.get(0).dataset.hideWeeks != null ? this.domElement.get(0).dataset.hideWeeks === 'true' : false;
    this.hideCountdownDays = this.domElement.get(0).dataset.hideDays != null ? this.domElement.get(0).dataset.hideDays === 'true' : false;
    this.hideCountdownHours = this.domElement.get(0).dataset.hideHours != null ? this.domElement.get(0).dataset.hideHours === 'true' : false;
    this.hideCountdownMinutes = this.domElement.get(0).dataset.hideMinutes != null ? this.domElement.get(0).dataset.hideMinutes === 'true' : false;
    this.hideCountdownSeconds = this.domElement.get(0).dataset.hideSeconds != null ? this.domElement.get(0).dataset.hideSeconds === 'true' : false;
    this.eventErrorMessage = this.domElement.get(0).dataset.eventErrorMessage != null ? this.domElement.get(0).dataset.eventErrorMessage : '';
  }

  public requestData(pageInput: FundraiserPageInput) {
    // empty
  }

  public fillData(data): void {
    this.page = this.fundraiserEnvironment.options.fundraiserPage;
    this.fundraiserCampaign = this.fundraiserEnvironment.options.fundraiserCampaign;
    this.serverDate = this.fundraiserEnvironment.options.serverDate;
    this.serverDateOffset = this.fundraiserEnvironment.options.serverDateOffset;
    this.fundraiserEvent = this.page.event;
    this.type = this.page.fundraiserType;
    //
    if (this.fundraiserEvent != null && this.fundraiserEvent.date != null) {
      this.date = moment(this.fundraiserEvent.date).tz('America/New_York');
    }
  }

  public refreshView(): any {
    if (this.date != null) {
      const weeksEl = window.jQuery('#fr-countdown-timer-weeks');
      const daysEl = window.jQuery('#fr-countdown-timer-days');
      const hoursEl = window.jQuery('#fr-countdown-timer-hours');
      const minutesEl = window.jQuery('#fr-countdown-timer-minutes');
      const secondsEl = window.jQuery('#fr-countdown-timer-seconds');
      //
      if (weeksEl.length) {
        //
        // Current timezone offset for new york = -4 (DST -5)
        const timezoneOffset = this.serverDateOffset - this.fundraiserEvent.timezoneOffset;
        //
        this.serverDate = this.serverDate + 1000; // 1 second
        const now =  this.serverDate;
        const distance = moment(this.fundraiserEvent.date).add(timezoneOffset, 'hour').diff(now);
        //
        // Time calculations for days, hours, minutes and seconds
        let weeks = Math.floor(distance / (1000 * 60 * 60 * 24 * 7));
        let days = Math.floor((distance % (1000 * 60 * 60 * 24 * 7)) / (1000 * 60 * 60 * 24));
        let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        let seconds = Math.floor((distance % (1000 * 60)) / 1000);
        //
        if (weeks < 0) {
          weeks = 0;
        }
        if (days < 0) {
          days = 0;
        }
        if (hours < 0) {
          hours = 0;
        }
        if (minutes < 0) {
          minutes = 0;
        }
        if (seconds < 0) {
          seconds = 0;
        }
        if (this.hideCountdownSeconds && seconds > 0) {
          // sum 1 minute for the missing seconds
          minutes += 1;
        }
        //
        weeksEl.html(weeks + '');
        daysEl.html(days + '');
        hoursEl.html(hours + '');
        minutesEl.html(minutes + '');
        secondsEl.html(seconds + '');
      }
    }

  }

  private build_countdown_datetime_partial(): string{
    let view_output = ``;

    if (this.format === 'NORMAL') {
      view_output = `<h5 class="format-normal">{{date}}</h5>`;

      if (!this.hideTime) {
        view_output = '<h6 class="format-normal">{{time}}</span></h6>';
      }
    }else {
      let format_time_long = ``;

      if (!this.hideTime) {
        format_time_long = `<span class="format-long"> @{{time}}</span>`;
      }

      view_output = `
        <h5 class="format-long"> {{date}} ${format_time_long} </h5>
      `;
    }

    return view_output;
  }

  private build_ghost_clock_partial(): string{
    let view_output = ``;

    if (!this.hideCountdownWeeks) {
      view_output += this.addClock('weeks', 'Weeks');
    }
    if (!this.hideCountdownDays) {
      view_output += this.addClock('days', 'Days');
    }
    if (!this.hideCountdownHours) {
      view_output += this.addClock('hours', 'Hours');
    }
    if (!this.hideCountdownMinutes) {
      view_output += this.addClock('minutes', 'Minutes');
    }
    if (!this.hideCountdownSeconds) {
      view_output += this.addClock('seconds', 'Seconds');
    }
    
    return view_output;
  }

  public getGhostView(): string {
    const view_header = this.getTitleView();
    let countdown_datetime_partial = ``;
    let ghost_clock_partial = ``;

    //Partial 1
    countdown_datetime_partial = this.build_countdown_datetime_partial();

    //Partial 2
    ghost_clock_partial = this.build_ghost_clock_partial();

    const view = `
      ${view_header}

      <div class="fr-widget__body">
        <div class="d-flex flex-column text-center countdown-date-time">
          ${countdown_datetime_partial}
        </div>

        <div class="d-flex flex-row text-center justify-content-around">
          <div class="arrow"></div>
        </div>
      
        <div class="d-flex flex-row text-center justify-content-around countdown-timers">
          ${ghost_clock_partial}
        </div>
      </div>

    `;

    return view;
  }

  public getView(): string {
    let showCountdown = true;
    let errorMessage = '';
    if (this.hideIfClosedDonation && moment(this.serverDate).isAfter(this.page.closeDonationsDate)) {
      showCountdown = false;
    }else if (this.fundraiserCampaign.name === 'MWOY') {
      if (this.hideIfClosedDonation && moment(new Date()).isAfter(this.page.event.date)) {
        showCountdown = false;
        errorMessage = '<div class="fr-widget__body error">' + this.eventErrorMessage + '</div>';
      }
    }
    if (showCountdown) {
      let timeString = this.date.format('hh:mm A ');
      if (!this.hideTimezone && this.fundraiserEvent != null) {
        timeString += this.fundraiserEvent.timezone;
      }
      //
      const array = [{
        key: 'date',
        value: this.date.format('ddd') + ' <span class="highlight"> ' + this.date.format('MMM DD, YYYY') + '</span>',
      }, {
        key: 'time',
        value: timeString,
      }];
      //
      return this.replaceHtmlParamsInView(this.getGhostView(), array);
    }
    return errorMessage;
  }

  public afterView(): boolean {
    this.refreshView();
    setInterval(() => { this.refreshView(); }, 1000);
    return true;
  }

  private create_addClock_partial_3(id): string{
    let view_output = ``;

    if (this.clockLabels === 'INSIDE_TOP' || this.clockLabels === 'INSIDE_BOTTOM') {
      view_output = `<span class="time-number time-number-inside" id="fr-countdown-timer-${id}"></span>`;
    }else {
      view_output = `<span class="time-number" id="fr-countdown-timer-${id}"></span>`;
    }

    return view_output;
  }
  //
  public addClock(id, label): string {
    let time_label_top_partial = ``;
    let time_label_inside_top_partial = ``;
    let add_clock_partial = ``;
    let time_label_inside_bottom_partial = ``;
    let time_label_bottom_partial = ``;

    //Partial 1
    if (this.clockLabels === 'TOP') time_label_top_partial = `<span class="time-label">${label}</span>`;

    //Partial 2
    if (this.clockLabels === 'INSIDE_TOP') time_label_inside_top_partial = `<span class="time-label time-label-inside">${label}</span>`;

    //Partial 3
    add_clock_partial = this.create_addClock_partial_3(id);

    //Partial 4
    if (this.clockLabels === 'INSIDE_BOTTOM') time_label_inside_bottom_partial = `<span class="time-label time-label-inside">${label}</span>`;

    //Partial 5
    if (this.clockLabels === 'BOTTOM') time_label_bottom_partial = `<span class="time-label time-label-bottom">${label}</span>`;

    const view = `
      <div class="d-flex flex-column">
        ${time_label_top_partial}

        <div class="d-flex fr-widget__body-timer">
          ${time_label_inside_top_partial}
          ${add_clock_partial}
          ${time_label_inside_bottom_partial}
        </div>

        ${time_label_bottom_partial}
      </div>
    `;
    
    return view;
  }
}