import { injectable, inject } from 'inversify';
import moment from 'moment';
import 'moment-timezone-slim';
import { BaseFundraiserComponent } from '../../../base/base-fundraiser-component';
import fundraiserContainer from '../../../../container/fundraiser-container';
import { FundraiserEnvironment } from '../../../sdk/services/fundraiser-environment';
import sdkTypes from '../../../sdk/types/sdk-types';
import { FundraiserEvent } from '../../../base/model/fundraiser-event';
import { FundraiserComment } from '../../../base/model/fundraiser-comment';
import { FundraiserPageInput } from '../../../base/model/input/fundraiser-page-input';

@injectable()
export class CommentComponent extends BaseFundraiserComponent{

  private fundraiserEnvironment: FundraiserEnvironment;
  private fundraiserEvent: FundraiserEvent;
  private fundraiserComments: FundraiserComment[];

  constructor() {
    super();
    this.fundraiserEnvironment = fundraiserContainer.get<FundraiserEnvironment>(sdkTypes.fundraiserEnvironment);
    this.componentId = 'fr-comment';
    this.title = 'Supporter Comments';
  }

  public fillData(data): void {
    this.data = data;
    this.fundraiserComments = this.fundraiserEnvironment.options.comments;
    this.fundraiserEvent = this.fundraiserEnvironment.options.fundraiserPage.event;
  }

  requestData(pageInput: FundraiserPageInput) {
    pageInput.fetchComments = true;
  }

  public getView(): string {

    // Do not show widget if content is empty
    if (this.fundraiserComments != null && this.fundraiserComments.length > 0) {
      let view =  this.getTitleView() +
        '<div class="fr-widget__body">';

      this.fundraiserComments.forEach((comment: FundraiserComment) => {

        view += '  <div class="fr-widget__body-comment">' +
          '          <div class="fr-widget__body-comment__message"><span>"';
        //
        view += comment.comment;
        //
        view += '"</span>' +
          '</div>' +
          '<div class="fr-widget__body-comment__author">';

        if (comment.donorDisplayName != null && comment.showDonor === 'SHOW_DONOR') {
          view += '<h6>' + comment.donorDisplayName + '</h6>';
        }else {
          view += '<h6>Anonymous user</h6>';
        }
        //
        const date = moment(comment.createdDate).tz('America/New_York');
        const sDate = date.format('ddd MMM D hh:mm:ss ') + comment.timezone + date.format(' YYYY');

        view += '</div>' +
          '<div class="fr-widget__body-comment__date">' +
          '<span class="text-muted">' + sDate + '</span>' +
          '</div>' +
          '</div>';
      });
      //
      view += '</div>';

      return view;
    }
    return '';
  }

}