import { injectable, inject } from 'inversify';
import { BaseFundraiserComponent } from '../../../base/base-fundraiser-component';
import fundraiserContainer from '../../../../container/fundraiser-container';
import { FundraiserEnvironment } from '../../../sdk/services/fundraiser-environment';
import sdkTypes from '../../../sdk/types/sdk-types';
import { FundraiserCampaign } from '../../../base/model/fundraiser-campaign';
import { FundraiserPage } from '../../../base/model/fundraiser-page';

@injectable()
export class TellMeMoreComponent extends BaseFundraiserComponent{

  private fundraiserEnvironment: FundraiserEnvironment;
  private fundraiserPage: FundraiserPage;
  private fundraiserCampaign: FundraiserCampaign;
  private tellMeMoreUrl: string;
  private tellMeMorePlaceholder: string;
  private tellMeMoreButton: string;

  constructor() {
    super();
    this.fundraiserEnvironment = fundraiserContainer.get<FundraiserEnvironment>(sdkTypes.fundraiserEnvironment);
    this.componentId = 'fr-tell-me-more';
    this.title = 'Tell Me More';
  }

  public scanDataAttributes(): void {
    this.tellMeMoreUrl = this.domElement.get(0).dataset.url != null ? this.domElement.get(0).dataset.url
      : 'https://www.teamintraining.org/chapterLookup.htm?path=/firsttimehere/tellmemore&postalCode={{postalCode}}';
    this.tellMeMorePlaceholder = this.domElement.get(0).dataset.placeholder != null ? this.domElement.get(0).dataset.placeholder
      : 'Your Zip Code';
    this.tellMeMoreButton = this.domElement.get(0).dataset.button != null ? this.domElement.get(0).dataset.button
      : 'I\'D LIKE TO LEARN MORE';
  }

  public fillData(data): void {
    this.data = data;
    //
  }

  public getGhostView(): string {
    const view = this.getTitleView() +
      '<div class="fr-widget__body">' +
      ' <input class="zip-code-input" type="text" placeholder="{{placeholder}}"/>' +
      ' <button class="button tell-me-more-button">{{button}}</button>' +
      ' </div>' +
      '</div>';
    return this.replaceHtmlParamsInView(view, [{
      key: 'placeholder',
      value: this.tellMeMorePlaceholder,
    }, {
      key: 'button',
      value: this.tellMeMoreButton,
    }]);
  }

  public getView(): string {
    return this.getGhostView();
  }

  public afterView(): boolean {
    this.domElement.find('button').on('click', (e) => {
      e.stopPropagation();
      const zipCode = window.jQuery(e.target).parent().find('input').val();
      if (zipCode == null || zipCode === '') {
        window.alert('Please enter a valid zip code.');
      }else {
        const url = this.tellMeMoreUrl.replace('{{postalCode}}', zipCode as string);
        window.location.href = url;
      }
      return false;
    });
    return true;
  }

}