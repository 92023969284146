import { injectable, inject } from 'inversify';
import { BaseFundraiserComponent } from '../../../base/base-fundraiser-component';
import fundraiserContainer from '../../../../container/fundraiser-container';
import { FundraiserEnvironment } from '../../../sdk/services/fundraiser-environment';
import sdkTypes from '../../../sdk/types/sdk-types';
import { FundraiserCampaign } from '../../../base/model/fundraiser-campaign';
import { FundraiserPage } from '../../../base/model/fundraiser-page';
import { JoinOurTeamService } from '../../team/services/join-our-team-service';
import joinTypes from '../../types/join-types';
import { FundraiserPageInput } from '../../../base/model/input/fundraiser-page-input';
import { FundraiserSubteam } from '../../../base/model/fundraiser-subteam';
import { FundraiserTeam } from '../../../base/model/fundraiser-team';
import moment from 'moment';
import { FundraiserTeamMember } from '../../../base/model/fundraiser-team-member';
import { FundraiserEvent } from '../../../base/model/fundraiser-event';

declare const window: any;

@injectable()
export class RegisterTodayNationalComponent extends BaseFundraiserComponent{

  private joinOurTeamService: JoinOurTeamService;
  private team: FundraiserTeam;
  private subteams: FundraiserSubteam[];
  private fundraiserEnvironment: FundraiserEnvironment;
  private fundraiserPage: FundraiserPage;
  private fundraiserCampaign: FundraiserCampaign;
  private createTeamUrl: string;
  private tableInitialized = false;

  constructor() {
    super();
    this.fundraiserEnvironment = fundraiserContainer.get<FundraiserEnvironment>(sdkTypes.fundraiserEnvironment);
    this.joinOurTeamService = fundraiserContainer.get<JoinOurTeamService>(joinTypes.JoinOurTeamService);
    this.componentId = 'fr-register-today-national';
    this.title = 'Register Today';
  }
  requestData(pageInput: FundraiserPageInput) {
    pageInput.fetchTeamCaptains = true;
  }

  public fillData(data): void {
    this.data = data;
    this.fundraiserCampaign = this.fundraiserEnvironment.options.fundraiserCampaign;
    this.fundraiserPage = this.fundraiserEnvironment.options.fundraiserPage;
    this.team = this.fundraiserPage.team;
    //
    if (this.fundraiserCampaign.name === 'LTN') {
      this.createTeamUrl = this.fundraiserCampaign.registrationUrl + '/national-team-selected/' //
        + this.fundraiserPage.team.teamId + '/national-team-type/NATIONAL_CORP/create-team';
      //
    }
  }

  public getView(): string {
    // Do not show widget if content is empty
    if (this.fundraiserPage.team != null) {
      let view =  '' +
        '<div class="fr-widget__body">';
      //
      view += '<div class="fr-widget__body-register-today-national__link-container">' +
        '<a class="btn button create-team" href="' + this.createTeamUrl + '" target="_blank">' +
        ' <span>Create a Team</span><span>></span>' +
        '</a>' +
        '</div>';
      //
      view += '<div class="fr-widget__body-register-today-national__link-container">' +
        '<a class="btn button join-team" href="javascript:;" target="_blank" data-bs-toggle="modal" data-bs-target="#joinTeamModal">' +
        ' <span>Join Team</span><span>></span>' +
        '</a>' +
        '</div>';
      //

      if (this.fundraiserPage.fundraiserType == 'NATIONAL_TEAM' 
        && this.fundraiserPage.team.teamType == 'SCHOOL_DISTRICT' 
        && this.fundraiserCampaign.name == 'LTN' ){
        //hide for schools
      } else {
        view += '<div class="fr-widget__body-register-today-national__link-container">' +
            '<a class="btn button find-participant" href="javascript:;">' +
            ' <span>Find a Participant</span><span>></span>' +
            '</a>' +
            '</div>';
        //
        view += '<div class="find-participant-container" style="display: none">' +
            '<img src="/content360/ltn/images/fundraising/spinner.gif" class="loading"/>' +
            '<select class="find-participant-select" style="display: none">' +
            '</select>' +
            '</div>';
      }
      //
      //
      view += '</div>';
      //
      view += this.addJoinTeamModal();
      //
      return view;
    }
  }

  public addJoinTeamModal(): string {
    let view = '';
    //
    view += '' +
      '<div id="joinTeamModal" class="modal fade join-team-modal" role="dialog">' +
      '  <div class="modal-dialog">' +
      '    <div class="modal-content">' +
      '      <div class="modal-header">' +
      '        <span class="modal-title">JOIN TEAM</span>' +
      '        <button type="button" class="close" data-bs-dismiss="modal">' +
      '        CLOSE <span>&times;</span>' +
      '        </button>' +
      '      </div>' +
      '      <div class="modal-body">' +
      '        <div id="teamTable" class="table-responsive">'
      '        </div>' +
      '      </div>' +
      '      <div class="modal-footer">' +
      '      </div>' +
      '    </div>' +
      '  </div>' +
      '</div>';
    //
    return view;
  }

  public buildJoinTeamTable(): string {
    let view = '' +
      '<table class="table"> ' +
      ' <thead>' +
      '   <tr>' +
      '     <th>Team</th>' +
      '     <th>Sub Team</th>' +
      '     <th>Event</th>' +
      '     <th></th>' +
      '   </tr>' +
      ' </thead>' +
      ' <tbody>';
    //
    if (this.subteams != null) {
      this.subteams.forEach((subteam) => {
        //
        const registrationUrl = this.fundraiserCampaign.registrationUrl + '/event-selected/' + subteam.event.campaignEventId
          + '/team-selected/' + subteam.teamId;
        const isParent = this.team.teamId === subteam.parentTeamId;
        view += this.buildTeamRow(subteam, subteam, subteam.captainList, subteam.event, registrationUrl, isParent);
      });
    }
    //
    view += '</tbody>' +
      '</table>';
    //
    return view;
  }

  public buildTeamRow(team: FundraiserSubteam, subteam: FundraiserSubteam, captains: FundraiserTeamMember[], event: FundraiserEvent,
                      registrationUrl: string, isParent: boolean): string {
    //
    let view = ' <tr>';
    //
    if (isParent) {
      view += '   <td><div><a href="' + team.fullUrl + '">' + team.teamName + '</a></div>';
    } else {
      const parentTeam = this.subteams.filter(st => st.teamId === subteam.parentTeamId)[0];
      view += '   <td><div><a href="' + parentTeam.fullUrl + '">' + parentTeam.teamName + '</a></div>';
    }
    //
    if (captains != null && captains.length > 0) {
      captains.forEach((captain) => {
        const captainName = this.buildCaptainName(captain);
        view += '<div><img src="https://pages.lightthenight.org/nationalteam/images/captain-ico.png">' + captainName + '</div>';
      });
    }
    view += '</td>';
    //
    if (!isParent) {
      view += '   <td><div><a href="' + subteam.fullUrl + '">' + subteam.teamName + '<a/></div></td>';
    }else {
      view += '   <td></td>';
    }
    //
    const lastIndexOfSlash = subteam.fullUrl.lastIndexOf('/');
    view +=  '   <td><div> <a href="' + subteam.fullUrl.substring(0, lastIndexOfSlash) + '">' + event.location +
      '</a></div><div>' + moment(event.date).format('MMM DD, YYYY')
      + ' - ' + event.city + ', ' + event.state + ' </div></td>';
    view +=  '   <td><a href="' + registrationUrl + '" class="btn button btn-join">JOIN</a></td>' +
      ' </tr>';
    return view;
  }

  public buildCaptainName(captain: FundraiserTeamMember):string {
    let captainName = '';
    if (captain != null) {
      if (captain.memberTypeId === 1) {
        captainName = captain.firstName + ' ' + captain.lastName;
      }else {
        captainName = captain.institutionName;
      }
    }
    return captainName;
  }

  public afterView(): boolean {
    console.log('Initializing datatable');
    window.jQuery('a.join-team').on('click', () => {
      if (!this.tableInitialized) {
        // only fetch Subteam data onclick of the Join Team button
        this.joinOurTeamService.fetchSubteamsInfo().then((data) => {
          this.subteams = data as any;
          // once we have the data, build the modal content and set config options
          document.getElementById('teamTable').innerHTML = this.buildJoinTeamTable();
          window.jQuery('#joinTeamModal table').DataTable({
            lengthChange: false,
            info: false,
            language: {
              search: '',
              searchPlaceholder: 'Search',
              paginate: {
                previous: '«',
                next: '»',
              },
            },
          });
          this.tableInitialized = true;
        });
      }
    });
    window.jQuery('a.find-participant').on('click', () => {
      window.jQuery('.find-participant-container').show();
      this.joinOurTeamService.fetchSubteamsMembersInfo('').then((data: FundraiserTeamMember[]) => {
        window.jQuery('.find-participant-select').show();
        window.jQuery('.find-participant-container .loading').hide();
        window.jQuery('.find-participant-select').find('option').remove();
        const view = '<option value="">Select participant</option>';
        window.jQuery('.find-participant-select').append(view);
        data.forEach((member) => {
          const view = '<option value="' + member.fundraiserPageUrl + '">' + member.firstName + ' ' + member.lastName + '</option>';
          window.jQuery('.find-participant-select').append(view);
        });
        //
        window.jQuery('.find-participant-select').select2({
          placeholder: 'first or last name',
        });
        //
        window.jQuery('.find-participant-select').on('change', (e) => {
          const url = window.jQuery(e.target).val();
          if (url) {
            window.location.href = url;
          }
        });
        //
        window.jQuery('.find-participant-select').select2('open');

      });
    });
    // Prevent the modal from closing when clicking on the pagination buttons
    window.jQuery('#joinTeamModal').on('hide.bs.modal', (e) => {
      const srcEl = event.srcElement;
      if (srcEl instanceof HTMLElement && srcEl.classList.contains('paginate_button')) {
        e.preventDefault();
      }
    });
    return true;
  }

}
