import { injectable, inject } from 'inversify';
import { BaseFundraiserComponent } from '../../../base/base-fundraiser-component';
import fundraiserContainer from '../../../../container/fundraiser-container';
import { FundraiserEnvironment } from '../../../sdk/services/fundraiser-environment';
import sdkTypes from '../../../sdk/types/sdk-types';

@injectable()
export class ContactComponent extends BaseFundraiserComponent{

  private fundraiserEnvironment: FundraiserEnvironment;
  // options
  private name1: string;
  private email1: string;
  private phone1: string;
  //
  private name2: string;
  private email2: string;
  private phone2: string;
  //
  private name3: string;
  private email3: string;
  private phone3: string;
  //
  private name4: string;
  private email4: string;
  private phone4: string;

  constructor() {
    super();
    this.fundraiserEnvironment = fundraiserContainer.get<FundraiserEnvironment>(sdkTypes.fundraiserEnvironment);
    this.componentId = 'fr-contact';
    this.hideTitle = true;
    this.title = 'Contact Information';
  }

  public fillData(data): void {
    //
    const e = this.domElement.get(0);
    //
    this.name1 = e.dataset.name1 != null ? e.dataset.name1 : '';
    this.email1 = e.dataset.email1 != null ? e.dataset.email1 : '';
    this.phone1 = e.dataset.phone1 != null ? e.dataset.phone1 : '';
    //
    this.name2 = e.dataset.name2 != null ? e.dataset.name2 : '';
    this.email2 = e.dataset.email2 != null ? e.dataset.email2 : '';
    this.phone2 = e.dataset.phone2 != null ? e.dataset.phone2 : '';
    //
    this.name3 = e.dataset.name3 != null ? e.dataset.name3 : '';
    this.email3 = e.dataset.email3 != null ? e.dataset.email3 : '';
    this.phone3 = e.dataset.phone3 != null ? e.dataset.phone3 : '';
    //
    this.name4 = e.dataset.name4 != null ? e.dataset.name4 : '';
    this.email4 = e.dataset.email4 != null ? e.dataset.email4 : '';
    this.phone4 = e.dataset.phone4 != null ? e.dataset.phone4 : '';
    //
  }

  public getView(): string {
    let view = this.getTitleView() + '<div class="fr-widget__body">';
    //
    if (this.name1 != null && this.name1 !== '') {
      view += this.addContact(this.name1, this.email1, this.phone1);
    }
    if (this.name2 != null && this.name2 !== '') {
      view += this.addContact(this.name2, this.email2, this.phone2);
    }
    if (this.name3 != null && this.name3 !== '') {
      view += this.addContact(this.name3, this.email3, this.phone3);
    }
    if (this.name4 != null && this.name4 !== '') {
      view += this.addContact(this.name4, this.email4, this.phone4);
    }
    //
    view += '</div>';
    //
    return view;
  }

  protected addContact(displayName, email, phone): string {
    let view = '<div class="contact-block">';
    if (displayName != null) {
      view += '<div class="contact-block__name contact-info">' + displayName + '</div>';
    }
    if (email != null) {
      view += '<div class="contact-block__email contact-info">' + email + '</div>';
    }
    if (phone != null) {
      view += '<div class="contact-block__name contact-info">' + phone + '</div>';
    }
    view += '</div>';
    return view;
  }
}