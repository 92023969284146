import { request } from '../../../../utils/request';
import { injectable } from 'inversify';
import { FundraiserEnvironment } from '../../../sdk/services/fundraiser-environment';
import sdkTypes from '../../../sdk/types/sdk-types';
import fundraiserContainer from '../../../../container/fundraiser-container';
import { FundraiserCampaign } from '../../../base/model/fundraiser-campaign';
import { FundraiserPage } from '../../../base/model/fundraiser-page';

@injectable()
export class PostsService {

  private fundraiserEnvironment: FundraiserEnvironment;
  private fundraiserCampaign: FundraiserCampaign;
  private fundraiserPage: FundraiserPage;
  private pageUrl: string;

  constructor() {
    this.fundraiserEnvironment = fundraiserContainer.get<FundraiserEnvironment>(sdkTypes.fundraiserEnvironment);
    this.pageUrl = this.fundraiserEnvironment.options.pageUrl;
  }

  public async fetchPosts(pageOffset: number, pageSize: number) {
    this.fundraiserCampaign = this.fundraiserEnvironment.options.fundraiserCampaign;
    this.fundraiserPage = this.fundraiserEnvironment.options.fundraiserPage;
    //
    const url = '/api/page/' + this.fundraiserPage.fundraiserPageId;
    //
    const response = await request('post', url, {}, {
      fetchPage: true,
      fetchPosts: true,
      postPage: {
        offset: pageOffset,
        size: pageSize,
      },
    });
    return response.json();
  }
}