const joinTypes = {
  // Cart
  CartService: Symbol('CartService'),
  CartComponent: Symbol('CartComponent'),
  //
  // Join our team
  JoinOurTeamService: Symbol('JoinOurTeamService'),
  JoinOurTeamComponent: Symbol('JoinOurTeamComponent'),
  //
  // Donation
  DonateComponent: Symbol('DonateComponent'),
  DonateMembersComponent: Symbol('DonateMembersComponent'),
  DonateService: Symbol('DonateService'),
  //
  // Items
  ItemsComponent: Symbol('ItemsComponent'),
  //
  // Register today
  RegisterTodayComponent: Symbol('RegisterTodayComponent'),
  RegisterTodayNationalComponent: Symbol('RegisterTodayNationalComponent'),
  //
  // Search
  SearchService: Symbol('SearchService'),
  SearchComponent: Symbol('SearchComponent'),
  SearchExternalComponent: Symbol('SearchExternalComponent'),
  //
  // Tell me more
  TellMeMoreComponent: Symbol('TellMeMoreComponent'),
};
export default joinTypes;
