import { injectable } from 'inversify';
import { request } from '../../../../utils/request';
import fundraiserContainer from '../../../../container/fundraiser-container';
import sdkTypes from '../../../sdk/types/sdk-types';
import { FundraiserEnvironment } from '../../../sdk/services/fundraiser-environment';
import { FundraiserCampaign } from '../../../base/model/fundraiser-campaign';
import { FundraiserPage } from '../../../base/model/fundraiser-page';

@injectable()
export class CartService {

  private fundraiserEnvironment: FundraiserEnvironment;
  private fundraiserCampaign: FundraiserCampaign;
  private fundraiserPage: FundraiserPage;

  constructor() {
    this.fundraiserEnvironment = fundraiserContainer.get<FundraiserEnvironment>(sdkTypes.fundraiserEnvironment);
  }

  public async getCart() {
    this.fundraiserCampaign = this.fundraiserEnvironment.options.fundraiserCampaign;
    this.fundraiserPage = this.fundraiserEnvironment.options.fundraiserPage;
    //
    const url = '/api/fundraiser/cart/?fundraiserPageId=' + this.fundraiserPage.fundraiserPageId;
    //
    const response = await request('get', url, '', {});
    return response.json();
  }

  public async addCart(pItemId: number, pQuantity: number) {
    this.fundraiserCampaign = this.fundraiserEnvironment.options.fundraiserCampaign;
    this.fundraiserPage = this.fundraiserEnvironment.options.fundraiserPage;
    //
    const url = '/api/fundraiser/cart/add/';
    //
    const response = await request('post', url, '', {
      fundraiserPageId: this.fundraiserPage.fundraiserPageId,
      itemId: pItemId,
      quantity: pQuantity,
    });
    return response.json();
  }

  public async updateCart(pItemId: number, pQuantity: number) {
    this.fundraiserCampaign = this.fundraiserEnvironment.options.fundraiserCampaign;
    this.fundraiserPage = this.fundraiserEnvironment.options.fundraiserPage;
    //
    const url = '/api/fundraiser/cart/';
    //
    const response = await request('post', url, '', {
      fundraiserPageId: this.fundraiserPage.fundraiserPageId,
      itemId: pItemId,
      quantity: pQuantity,
    });
    return response.json();
  }

  public async clearCart() {
    this.fundraiserCampaign = this.fundraiserEnvironment.options.fundraiserCampaign;
    this.fundraiserPage = this.fundraiserEnvironment.options.fundraiserPage;
    //
    const url = '/api/fundraiser/cart/';
    //
    const response = await request('get', url, '', {
      fundraiserPageId: this.fundraiserPage.fundraiserPageId,
    });
    return response.json();
  }

}