import { injectable } from 'inversify';
import fundraiserContainer from '../../../container/fundraiser-container';
import sdkTypes from '../../sdk/types/sdk-types';
import { FundraiserEnvironment } from '../../sdk/services/fundraiser-environment';

@injectable()
export class StructuralService {

  private fundraiserEnvironment: FundraiserEnvironment;
  private campaignName: string;
  private pageUrl: string;

  constructor() {
    this.fundraiserEnvironment = fundraiserContainer.get<FundraiserEnvironment>(sdkTypes.fundraiserEnvironment);
  }
}