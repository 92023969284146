import { injectable, inject } from 'inversify';
import { BaseFundraiserComponent } from '../../../base/base-fundraiser-component';
import fundraiserContainer from '../../../../container/fundraiser-container';
import { FundraiserEnvironment } from '../../../sdk/services/fundraiser-environment';
import sdkTypes from '../../../sdk/types/sdk-types';

@injectable()
export class EventVideoComponent extends BaseFundraiserComponent{

  private fundraiserEnvironment: FundraiserEnvironment;
  private videoUrl: string;

  constructor() {
    super();
    this.fundraiserEnvironment = fundraiserContainer.get<FundraiserEnvironment>(sdkTypes.fundraiserEnvironment);
    this.componentId = 'fr-event-video';
    this.title = 'Event Video';
  }

  public fillData(data): void {
    this.videoUrl = this.fundraiserEnvironment.options.fundraiserCampaign.videoUrl;
    const overrideVideoUrl = this.domElement.get(0).dataset.video != null ? this.domElement.get(0).dataset.video : '';
    if (overrideVideoUrl != null && overrideVideoUrl !== '') {
      this.videoUrl = overrideVideoUrl;
    }
  }

  public getGhostView(): string {
    let view = this.getTitleView();
    view += '<div class="fr-widget__body">' +
      '         <div class="youtube" data-embed="{{videoUrl}}">' +
      '           <div class="play-button"></div>' +
      '         </div>' +
      '     </div>';
    return view;
  }

  public getView(): string {
    const array = [{
      key: 'videoUrl',
      value: this.videoUrl,
    }];
    return this.replaceHtmlParamsInView(this.getGhostView(), array);
  }

  public afterView(): boolean {
    const youtube = this.domElement.find('.youtube');
    if (this.videoUrl != null) {
      this.videoUrl = this.videoUrl.replace('http://', '//');
      this.videoUrl = this.videoUrl.replace('https://', '//');
      const url = this.videoUrl + '?rel=0&showinfo=0&autoplay=0';
      youtube.html('<div class="fr-iframe"><iframe frameborder="0" allowfullscreen="" src="' + url + '"></iframe></div>');
    }
    return true;
  }

}