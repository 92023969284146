import { injectable, inject } from 'inversify';
import { BaseFundraiserComponent } from '../../../base/base-fundraiser-component';
import fundraiserContainer from '../../../../container/fundraiser-container';
import { FundraiserEnvironment } from '../../../sdk/services/fundraiser-environment';
import sdkTypes from '../../../sdk/types/sdk-types';

declare const FB: any;
declare const twttr: any;
declare const parsePinBtns: any;

@injectable()
export class ShareComponent extends BaseFundraiserComponent{

  private fundraiserEnvironment: FundraiserEnvironment;
  private fbRecommend: any;
  private twShare: any;
  private pinSave: any;
  private email: any;

  constructor() {
    super();
    this.fundraiserEnvironment = fundraiserContainer.get<FundraiserEnvironment>(sdkTypes.fundraiserEnvironment);
    this.componentId = 'fr-share';
  }

  public fillData(data): void {
    this.fbRecommend = this.domElement.get(0).dataset.fbRecommend != null ? this.domElement.get(0).dataset.fbRecommend === 'true' : true;
    this.twShare = this.domElement.get(0).dataset.twShare != null ? this.domElement.get(0).dataset.twShare === 'true' : true;
    this.pinSave = this.domElement.get(0).dataset.pinSave != null ? this.domElement.get(0).dataset.pinSave === 'true' : true;
    this.email = this.domElement.get(0).dataset.email != null ? this.domElement.get(0).dataset.email === 'true' : true;
  }

  public getGhostView(): string {
    return '';
  }

  public getView(): string {
    let view = '<div>';
    //
    if (this.fbRecommend) {
      view += '<div class="fb-like" data-href="' + window.location.href +
        '" data-layout="button_count" data-action="recommend" data-size="small" data-show-faces="false" data-share="false"></div>';
    }
    //
    if (this.twShare) {
      view += '<a href="https://twitter.com/share" class="twitter-share-button"' +
        ' data-url="' + window.location.href + '"' +
        ' data-text="I just donated to The Leukemia & Lymphoma Society. Help patients with blood cancer live better, longer lives. Visit "' +
        ' data-counturl="' + window.location.href + '"' +
        ' data-count="horizontal">Tweet</a>';
    }
    //
    if (this.pinSave) {
      view += '<a href="https://www.pinterest.com/pin/create/button/" class="pin-it-buttons" data-pin-do="buttonBookmark" data-pin-sticky="false"></a>';
    }
    //
    if (this.email) {
      view += '<a href="mailto:" class="share-email">&nbsp;</a>';
    }
    //
    view += '</div>';
    return view;
  }

  public getTitleView(): string {
    return '';
  }

  public afterView(): boolean {
    //
    if (this.fbRecommend) {
      if (typeof(FB) !== 'undefined' && FB != null) {
        FB.XFBML.parse();
      }
    }
    if (this.twShare) {
      if (typeof(twttr) !== 'undefined' && twttr != null && twttr.widgets != null) {
        twttr.widgets.load();
      }
    }
    if (this.pinSave) {
      if (typeof(parsePinBtns) !== 'undefined' && parsePinBtns != null) {
        parsePinBtns(document.getElementById('pin-it-buttons'));
      }
    }
    return true;
  }

}